import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Container,
  Header,
  Title,
  SearchExportContainer,
  SearchInput,
  ExportButton,
  AddEnterpriseButton,
  ColumnToggleContainer,
  ColumnToggleButton,
  ColumnOptions,
  ColumnOption,
  Table,
  Th,
  HeaderContent,
  Td,
  ActionIconButton,
  SortIcon,
  Spacer,
  FormGroup,
  CheckboxLabel,
  ButtonGroup,
  SaveButton,
  CloseButton,
  DeleteButton,
  TableWrapper,
  HighlightedRow,
  BoxContainerStyled
} from './component-styles/Enterprises.styled';
import { 
  getEnterprises, 
  getAgents, 
  getUsersForDropdown, 
  addEnterprise, 
  updateEnterprise, 
  deleteEnterprise,
  updateAgentEnterpriseId
} from '../utils/enterpriseService';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { 
  FaEye, 
  FaEdit, 
  FaTrash, 
  FaSort, 
  FaSortUp, 
  FaSortDown, 
  FaFileExport,
  FaPlus,
  FaCog
} from 'react-icons/fa';
import DashboardHeader from './DashboardHeader';
import * as XLSX from 'xlsx';
import ReusableModal from './toolbox/ReusableModal'; // Ensure the path is correct
import { Tooltip } from 'react-tooltip';
import Pagination from './toolbox/Pagination'; // Import the reusable Pagination component
import { BoxContainer, BoxWrapper } from './toolbox/Reusables';
import Tabs from './toolbox/Tabs'; // Import the Tabs component
import AssignAdmin from './toolbox/AssignAdmin';

const Enterprises = () => {
  const { currentUser, userRoles } = useAuth();
  const [enterprises, setEnterprises] = useState([]);
  const [filteredEnterprises, setFilteredEnterprises] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  const [editableData, setEditableData] = useState({});
  const [deleteConfirmationInput, setDeleteConfirmationInput] = useState('');
  const [visibleColumns, setVisibleColumns] = useState({
    name: true,
    users: true,
    agents: true,
    created_at: true,
  });
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);
  const [newEnterpriseData, setNewEnterpriseData] = useState({
    name: '',
    industry: '',
    contact_email: '',
    phone_number: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip_code: '',
    },
    users: [],
    agents: [],
    chat_logs: [],
    payment_history: [],
    settings: {
      max_agents: 10,
      support_plan: 'basic',
      timezone: 'America/Los_Angeles',
    },
    active: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const enterprisesPerPage = 10;
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // New State Variables for Agents and Users Dropdowns
  const [allAgents, setAllAgents] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const isAdmin = userRoles.some(role => role.id === 'admin');

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) {
        console.log('User is not authenticated.');
        return;
      }

      // Fetch Enterprises
      const enterprisesResponse = await getEnterprises();
      if (enterprisesResponse.success) {
        setEnterprises(enterprisesResponse.enterprises);
        setFilteredEnterprises(enterprisesResponse.enterprises);
      } else {
        console.error(enterprisesResponse.error);
        toast.error('Error fetching enterprises.');
      }

      // Fetch Agents
      const agentsResponse = await getAgents();
      if (agentsResponse.success) {
        setAllAgents(agentsResponse.agents);
      } else {
        console.error(agentsResponse.error);
        toast.error('Error fetching agents.');
      }

      // Fetch Users
      const usersResponse = await getUsersForDropdown();
      if (usersResponse.success) {
        setAllUsers(usersResponse.users);
      } else {
        console.error(usersResponse.error);
        toast.error('Error fetching users.');
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    let updatedEnterprises = [...enterprises];

    if (searchTerm.trim() !== '') {
      const lowercasedTerm = searchTerm.toLowerCase();
      updatedEnterprises = updatedEnterprises.filter(enterprise =>
        (enterprise.name && enterprise.name.toLowerCase().includes(lowercasedTerm)) ||
        (enterprise.industry && enterprise.industry.toLowerCase().includes(lowercasedTerm)) ||
        (enterprise.contact_email && enterprise.contact_email.toLowerCase().includes(lowercasedTerm)) ||
        (enterprise.phone_number && enterprise.phone_number.toLowerCase().includes(lowercasedTerm)) ||
        (enterprise.settings.support_plan && enterprise.settings.support_plan.toLowerCase().includes(lowercasedTerm)) ||
        (enterprise.settings.timezone && enterprise.settings.timezone.toLowerCase().includes(lowercasedTerm))
      );
    }

    if (sortConfig.key) {
      updatedEnterprises.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === 'created_at') {
          aValue = aValue.toDate();
          bValue = bValue.toDate();
        }

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
        }
        if (typeof bValue === 'string') {
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredEnterprises(updatedEnterprises);
    setCurrentPage(1);
  }, [searchTerm, enterprises, sortConfig]);

  const handleViewEnterprise = (enterprise) => {
    setSelectedEnterprise(enterprise);
    setIsViewModalOpen(true);
  };

  const handleEditEnterprise = (enterprise) => {
    setSelectedEnterprise(enterprise);
  
    // Derive associated agents based on enterprise_id from allAgents
    const associatedAgentIds = allAgents
      .filter(agent => agent.enterprise_id === enterprise.id)
      .map(agent => agent.id);
  
    setEditableData({
      name: enterprise.name || '',
      industry: enterprise.industry || '',
      contact_email: enterprise.contact_email || '',
      phone_number: enterprise.phone_number || '',
      address_street: enterprise.address?.street || '',
      address_city: enterprise.address?.city || '',
      address_state: enterprise.address?.state || '',
      address_zip_code: enterprise.address?.zip_code || '',
      users: enterprise.users || [],
      agents: associatedAgentIds, // Set based on enterprise_id
      settings_max_agents: enterprise.settings?.max_agents || 10,
      settings_support_plan: enterprise.settings?.support_plan || 'basic',
      settings_timezone: enterprise.settings?.timezone || 'America/Los_Angeles',
      active: enterprise.active || false,
    });
  
    setIsEditModalOpen(true);
  };
  
  

  const handleDeleteEnterprise = (enterprise) => {
    setSelectedEnterprise(enterprise);
    setDeleteConfirmationInput(''); // Reset the confirmation input
    setIsDeleteModalOpen(true);  
  };

  const confirmDeleteEnterprise = async () => {
    if (!selectedEnterprise) return;
  
    if (deleteConfirmationInput !== '2024') {
      toast.error('Incorrect confirmation number. Deletion canceled.');
      return;
    }
  
    const response = await deleteEnterprise(selectedEnterprise.id);
    if (response.success) {
      const updatedEnterprises = enterprises.filter(ent => ent.id !== selectedEnterprise.id);
      setEnterprises(updatedEnterprises);
      setFilteredEnterprises(updatedEnterprises);
      toast.success('Enterprise deleted successfully!');
      setIsDeleteModalOpen(false);
    } else {
      console.error(response.error);
      toast.error('Error deleting enterprise.');
    }
  };
  
// Updated handleSaveEdit Function
const handleSaveEdit = async (e) => {
  e.preventDefault();
  if (!selectedEnterprise) return;

  // Optional: Add further validation here

  const updatedData = {
    name: editableData.name,
    industry: editableData.industry,
    contact_email: editableData.contact_email,
    phone_number: editableData.phone_number,
    address: {
      street: editableData.address_street,
      city: editableData.address_city,
      state: editableData.address_state,
      zip_code: editableData.address_zip_code,
    },
    users: editableData.users, // Array of user UIDs
    agents: editableData.agents, // Array of agent IDs
    settings: {
      max_agents: editableData.settings_max_agents,
      support_plan: editableData.settings_support_plan,
      timezone: editableData.settings_timezone,
    },
    active: editableData.active,
  };

  const newUserIds = editableData.users; // Array of user UIDs to associate

  const response = await updateEnterprise(selectedEnterprise.id, updatedData, newUserIds);
  if (response.success) {
    const updatedEnterprises = enterprises.map(ent =>
      ent.id === selectedEnterprise.id ? { ...ent, ...updatedData } : ent
    );
    setEnterprises(updatedEnterprises);
    setFilteredEnterprises(updatedEnterprises);
    toast.success('Enterprise updated successfully!');

    // Manage Agent Associations by Directly Updating enterprise_id
    const newAgents = updatedData.agents || [];

    for (const agentId of newAgents) {
      const updateResponse = await updateAgentEnterpriseId(agentId, selectedEnterprise.id);
      if (!updateResponse.success) {
        console.error(`Failed to assign Agent "${agentId}" to Enterprise "${selectedEnterprise.id}":`, updateResponse.error);
        toast.error(`Failed to assign Agent "${agentId}".`);
      }
    }

    setIsEditModalOpen(false);

    // Refresh Agents List to Reflect Updates
    const agentsResponse = await getAgents();
    if (agentsResponse.success) {
      setAllAgents(agentsResponse.agents);
    } else {
      console.error(agentsResponse.error);
      toast.error('Error refreshing agents after update.');
    }
  } else {
    console.error(response.error);
    toast.error('Error updating enterprise.');
  }
};

    const handleInputChange = (e) => {
    const { name, value, type, checked, options } = e.target;

    if (name === 'users' || name === 'agents') {
      const selectedOptions = Array.from(options)
        .filter(option => option.selected)
        .map(option => option.value);
      setEditableData(prev => ({
        ...prev,
        [name]: selectedOptions,
      }));
    } else if (type === 'checkbox') {
      setEditableData(prev => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setEditableData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteConfirmationInput(''); // Reset the confirmation input
  };
  

  const handleColumnToggle = (columnKey) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const renderTableHeaders = () => {
    const allColumns = {
      name: 'Name',
      users: 'Users',
      agents: 'Agents',
      created_at: 'Created',
    };

    return Object.keys(allColumns).map(columnKey => (
      visibleColumns[columnKey] && (
        <Th key={columnKey} onClick={() => handleSort(columnKey)}>
          <HeaderContent>
            {allColumns[columnKey]}
            {sortConfig.key === columnKey ? (
              sortConfig.direction === 'ascending' ? (
                <SortIcon><FaSortUp /></SortIcon>
              ) : (
                <SortIcon><FaSortDown /></SortIcon>
              )
            ) : (
              <SortIcon><FaSort /></SortIcon>
            )}
          </HeaderContent>
        </Th>
      )
    ));
  };

// Updated renderTableRow Function (Ensure Correct Agents Count)
const renderTableRow = (enterprise) => {
  // Determine how to format the created_at field
  let createdAtFormatted = 'N/A';
  if (enterprise.created_at) {
    if (typeof enterprise.created_at.toDate === 'function') {
      // Firestore Timestamp
      createdAtFormatted = enterprise.created_at.toDate().toLocaleString();
    } else if (enterprise.created_at instanceof Date) {
      // JavaScript Date object
      createdAtFormatted = enterprise.created_at.toLocaleString();
    }
  }

  // Compute the number of agents associated with this enterprise
  const agentsCount = allAgents.filter(agent => agent.enterprise_id === enterprise.id).length;

  return (
    <HighlightedRow key={enterprise.id}>
      {visibleColumns.name && <Td>{enterprise.name}</Td>}
      {visibleColumns.users && <Td>{enterprise.users.length}</Td>}
      {visibleColumns.agents && <Td>{agentsCount}</Td>}
      {visibleColumns.created_at && <Td>{createdAtFormatted}</Td>}
      <Td>
        <ActionIconButton
          variant="view"
          onClick={() => handleViewEnterprise(enterprise)}
          data-tooltip-id={`view-tooltip-${enterprise.id}`}
          data-tooltip-content="View Details"
        >
          <FaEye />
        </ActionIconButton>
        <ActionIconButton
          variant="edit"
          onClick={() => handleEditEnterprise(enterprise)}
          data-tooltip-id={`edit-tooltip-${enterprise.id}`}
          data-tooltip-content="Edit Enterprise"
        >
          <FaEdit />
        </ActionIconButton>
        <ActionIconButton
          variant="delete"
          onClick={() => handleDeleteEnterprise(enterprise)}
          data-tooltip-id={`delete-tooltip-${enterprise.id}`}
          data-tooltip-content="Delete Enterprise"
        >
          <FaTrash />
        </ActionIconButton>
        {/* Initialize tooltips */}
        <Tooltip id={`view-tooltip-${enterprise.id}`} place="top" effect="solid" />
        <Tooltip id={`edit-tooltip-${enterprise.id}`} place="top" effect="solid" />
        <Tooltip id={`delete-tooltip-${enterprise.id}`} place="top" effect="solid" />
      </Td>
    </HighlightedRow>
  );
};
  const indexOfLastEnterprise = currentPage * enterprisesPerPage;
  const indexOfFirstEnterprise = indexOfLastEnterprise - enterprisesPerPage;
  const currentEnterprises = filteredEnterprises.slice(indexOfFirstEnterprise, indexOfLastEnterprise);
  const totalPages = Math.ceil(filteredEnterprises.length / enterprisesPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

// Updated handleExport Function
const handleExport = () => {
  const dataToExport = filteredEnterprises.map(ent => {
    const exportData = {};
    if (visibleColumns.name) exportData['Name'] = ent.name;
    if (visibleColumns.users) exportData['Users'] = ent.users.length;
    if (visibleColumns.agents) {
      const agentsCount = allAgents.filter(agent => agent.enterprise_id === ent.id).length;
      exportData['Agents'] = agentsCount;
    }
    if (visibleColumns.created_at) exportData['Created'] = ent.created_at?.toDate().toLocaleString() || 'N/A';
    return exportData;
  });

  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Enterprises');
  XLSX.writeFile(workbook, 'EnterpriseData.xlsx');
};

// Inside handleAddEnterprise
// Updated handleAddEnterprise Function
const handleAddEnterprise = async (e) => {
  e.preventDefault();

  // Basic validation
  if (!newEnterpriseData.name.trim()) {
    toast.error('Enterprise name is required.');
    return;
  }

  // Extract selected user IDs
  const selectedUserIds = newEnterpriseData.users; // Assuming 'users' is an array of UIDs

  const response = await addEnterprise(newEnterpriseData, selectedUserIds);
  if (response.success) {
    const newEnt = response.enterprise; // Use the fetched enterprise data with Timestamps
    setEnterprises(prev => [...prev, newEnt]);
    setFilteredEnterprises(prev => [...prev, newEnt]);
    toast.success('Enterprise added successfully!');
    setIsAddModalOpen(false);
    setNewEnterpriseData({
      name: '',
      industry: '',
      contact_email: '',
      phone_number: '',
      address: {
        street: '',
        city: '',
        state: '',
        zip_code: '',
      },
      users: [],
      agents: [],
      chat_logs: [],
      payment_history: [],
      settings: {
        max_agents: 10,
        support_plan: 'basic',
        timezone: 'America/Los_Angeles',
      },
      active: true,
    });

    // Assign enterprise_id to associated agents
    const associatedAgents = newEnterpriseData.agents || [];
    for (const agentId of associatedAgents) {
      const updateResponse = await updateAgentEnterpriseId(agentId, newEnt.id);
      if (!updateResponse.success) {
        console.error(`Failed to assign Agent "${agentId}" to Enterprise "${newEnt.id}":`, updateResponse.error);
        toast.error(`Failed to assign Agent "${agentId}".`);
      }
    }

    // Refresh Agents List to Reflect Updates
    const agentsResponse = await getAgents();
    if (agentsResponse.success) {
      setAllAgents(agentsResponse.agents);
    } else {
      console.error(agentsResponse.error);
      toast.error('Error refreshing agents after assignment.');
    }
  } else {
    console.error(response.error);
    toast.error(response.error || 'Error adding enterprise.');
  }
};

  const tabs = [
    {
      label: 'Enterprises',
      content: (
        <>
        <BoxContainerStyled>
        <Header>
        <Title>Enterprise Management</Title>
        <SearchExportContainer>
          <SearchInput
            type="text"
            placeholder="Search by name, industry..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <ExportButton onClick={handleExport}>
            Export <FaFileExport />
          </ExportButton>
          {isAdmin && (
            <AddEnterpriseButton onClick={() => setIsAddModalOpen(true)}>
              <FaPlus /> Add Enterprise
            </AddEnterpriseButton>
          )}
        </SearchExportContainer>
        <ColumnToggleContainer>
          <ColumnToggleButton onClick={() => setIsColumnOptionsOpen(!isColumnOptionsOpen)}>
            Columns
          </ColumnToggleButton>
          {isColumnOptionsOpen && (
            <ColumnOptions>
              {Object.keys(visibleColumns).map(columnKey => (
                <ColumnOption key={columnKey}>
                  <input
                    type="checkbox"
                    id={`toggle-${columnKey}`}
                    checked={visibleColumns[columnKey]}
                    onChange={() => handleColumnToggle(columnKey)}
                  />
                  <label htmlFor={`toggle-${columnKey}`}>
                    {columnKey.charAt(0).toUpperCase() + columnKey.slice(1).replace('_', ' ')}
                  </label>
                </ColumnOption>
              ))}
            </ColumnOptions>
          )}
        </ColumnToggleContainer>
      </Header>
      
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              {renderTableHeaders()}
              <Th>Actions</Th>
            </tr>
          </thead>
          <tbody>
            {currentEnterprises.length > 0 ? (
              currentEnterprises.map(enterprise => renderTableRow(enterprise))
            ) : (
              <tr>
                <Td colSpan={Object.values(visibleColumns).filter(Boolean).length + 1} style={{ textAlign: 'center' }}>
                  No enterprises found.
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      </BoxContainerStyled>
      
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Pagination
          totalItems={filteredEnterprises.length}
          itemsPerPage={enterprisesPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}

      {/* View Enterprise Modal */}
      <ReusableModal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        title="Enterprise Details"
      >
        {selectedEnterprise && (
          <div>
            <p><strong>ID:</strong> {selectedEnterprise.id}</p>
            <p><strong>Name:</strong> {selectedEnterprise.name}</p>
            <p><strong>Industry:</strong> {selectedEnterprise.industry || 'N/A'}</p>
            <p><strong>Contact Email:</strong> {selectedEnterprise.contact_email}</p>
            <p><strong>Phone Number:</strong> {selectedEnterprise.phone_number || 'N/A'}</p>
            <p><strong>Address:</strong></p>
            <ul>
              <li><strong>Street:</strong> {selectedEnterprise.address?.street || 'N/A'}</li>
              <li><strong>City:</strong> {selectedEnterprise.address?.city || 'N/A'}</li>
              <li><strong>State:</strong> {selectedEnterprise.address?.state || 'N/A'}</li>
              <li><strong>Zip Code:</strong> {selectedEnterprise.address?.zip_code || 'N/A'}</li>
            </ul>
            <p><strong>Users:</strong> {selectedEnterprise.users.length}</p>
            <p><strong>Agents:</strong> {allAgents.filter(agent => agent.enterprise_id === selectedEnterprise.id).length}</p>
            <p><strong>Chat Logs:</strong> {selectedEnterprise.chat_logs.length}</p>
            <p><strong>Created At:</strong> {selectedEnterprise.created_at?.toDate().toLocaleString() || 'N/A'}</p>
            <p><strong>Last Updated:</strong> {selectedEnterprise.last_updated?.toDate().toLocaleString() || 'N/A'}</p>
            <p><strong>Active:</strong> {selectedEnterprise.active ? 'Yes' : 'No'}</p>
            <p><strong>Settings:</strong></p>
            <ul>
              <li><strong>Max Agents:</strong> {selectedEnterprise.settings?.max_agents || 'N/A'}</li>
              <li><strong>Support Plan:</strong> {selectedEnterprise.settings?.support_plan || 'N/A'}</li>
              <li><strong>Timezone:</strong> {selectedEnterprise.settings?.timezone || 'N/A'}</li>
            </ul>
          </div>
        )}
      </ReusableModal>

      {/* Edit Enterprise Modal */}
      <ReusableModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Enterprise Details"
      >
        {selectedEnterprise && (
          <form onSubmit={handleSaveEdit}>
            {/* Name Field (Required) */}
            <FormGroup>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={editableData.name}
                onChange={handleInputChange}
                required
                placeholder="Enter enterprise name"
              />
            </FormGroup>

            {/* Industry Field (Optional) */}
            <FormGroup>
              <label htmlFor="industry">Industry:</label>
              <input
                type="text"
                id="industry"
                name="industry"
                value={editableData.industry}
                onChange={handleInputChange}
                placeholder="Enter industry sector"
              />
            </FormGroup>

            {/* Contact Email Field (Required) */}
            <FormGroup>
              <label htmlFor="contact_email">Contact Email:</label>
              <input
                type="email"
                id="contact_email"
                name="contact_email"
                value={editableData.contact_email}
                onChange={handleInputChange}
                required
                placeholder="Enter contact email"
              />
            </FormGroup>

            {/* Phone Number Field (Optional) */}
            <FormGroup>
              <label htmlFor="phone_number">Phone Number:</label>
              <input
                type="text"
                id="phone_number"
                name="phone_number"
                value={editableData.phone_number}
                onChange={handleInputChange}
                placeholder="Enter phone number"
              />
            </FormGroup>

            {/* Address Fields (Optional) */}
            <FormGroup>
              <label htmlFor="address_street">Street:</label>
              <input
                type="text"
                id="address_street"
                name="address_street"
                value={editableData.address_street}
                onChange={handleInputChange}
                placeholder="Enter street address"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="address_city">City:</label>
              <input
                type="text"
                id="address_city"
                name="address_city"
                value={editableData.address_city}
                onChange={handleInputChange}
                placeholder="Enter city"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="address_state">State:</label>
              <input
                type="text"
                id="address_state"
                name="address_state"
                value={editableData.address_state}
                onChange={handleInputChange}
                placeholder="Enter state"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="address_zip_code">Zip Code:</label>
              <input
                type="text"
                id="address_zip_code"
                name="address_zip_code"
                value={editableData.address_zip_code}
                onChange={handleInputChange}
                placeholder="Enter zip code"
              />
            </FormGroup>

            {/* Users Field */}
            <FormGroup>
              <label htmlFor="users">Users (Emails):</label>
              <select
                id="users"
                name="users"
                multiple
                value={editableData.users}
                onChange={handleInputChange}
              >
                {allUsers.map(user => (
                  <option key={user.uid} value={user.uid}>{user.email}</option>
                ))}
              </select>
              <small>Hold down the Ctrl (Windows) or Command (Mac) key to select multiple options.</small>
            </FormGroup>

            {/* Agents Field */}
{/* Agents Field */}
<FormGroup>
  <label htmlFor="agents">Agents (Names):</label>
  <select
    id="agents"
    name="agents"
    multiple
    value={editableData.agents}
    onChange={handleInputChange}
  >
    {allAgents.map(agent => {
      const isAssignedToAnother = agent.enterprise_id && agent.enterprise_id !== selectedEnterprise.id;
      return (
        <option 
          key={agent.id} 
          value={agent.id}
          style={{ color: isAssignedToAnother ? 'gray' : 'black' }} // Conditional styling
        >
          {agent.name} {isAssignedToAnother ? '(Already Assigned to Another)' : ''}
        </option>
      );
    })}
  </select>
  <small>Hold down the Ctrl (Windows) or Command (Mac) key to select multiple options.</small>
</FormGroup>


            {/* Settings Fields */}
            <FormGroup>
              <label htmlFor="settings_max_agents">Max Agents:</label>
              <input
                type="number"
                id="settings_max_agents"
                name="settings_max_agents"
                value={editableData.settings_max_agents}
                onChange={handleInputChange}
                min="1"
                placeholder="Enter maximum number of agents"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="settings_support_plan">Support Plan:</label>
              <select
                id="settings_support_plan"
                name="settings_support_plan"
                value={editableData.settings_support_plan}
                onChange={handleInputChange}
              >
                <option value="basic">Basic</option>
                <option value="premium">Premium</option>
                <option value="enterprise">Enterprise</option>
                {/* Add more plans as needed */}
              </select>
            </FormGroup>
            <FormGroup>
              <label htmlFor="settings_timezone">Timezone:</label>
              <select
                id="settings_timezone"
                name="settings_timezone"
                value={editableData.settings_timezone}
                onChange={handleInputChange}
              >
                <option value="America/Los_Angeles">America/Los_Angeles</option>
                <option value="Europe/London">Europe/London</option>
                <option value="Asia/Manila">Asia/Manila</option>
                {/* Add more timezones as needed */}
              </select>
            </FormGroup>

            {/* Active Status */}
            <FormGroup>
              <CheckboxLabel>
                <input
                  type="checkbox"
                  name="active"
                  checked={editableData.active}
                  onChange={handleInputChange}
                />
                Active
              </CheckboxLabel>
            </FormGroup>

            {/* Save and Cancel Buttons */}
            <ButtonGroup>
              <SaveButton type="submit">Save</SaveButton>
              <CloseButton type="button" onClick={() => setIsEditModalOpen(false)}>
                Cancel
              </CloseButton>
            </ButtonGroup>
          </form>
        )}
      </ReusableModal>

      {/* Delete Confirmation Modal */}
{/* Delete Confirmation Modal */}
<ReusableModal
  isOpen={isDeleteModalOpen}
  onClose={() => {
    setIsDeleteModalOpen(false);
    handleCloseDeleteModal();
  }}
  title="Confirm Deletion"
>
  {selectedEnterprise && (
    <div>
      <p>Are you sure you want to delete <strong>{selectedEnterprise.name}</strong>?</p>
      <p>Please type <strong>2024</strong> and press Enter to confirm deletion.</p>
      <FormGroup>
        <input
          type="text"
          value={deleteConfirmationInput}
          onChange={(e) => setDeleteConfirmationInput(e.target.value)}
          placeholder="Type 2024 to confirm"
          onKeyPress={(e) => {
            if (e.key === 'Enter' && deleteConfirmationInput === '2024') {
              confirmDeleteEnterprise();
            }
          }}
        />
      </FormGroup>
      <ButtonGroup>
        <DeleteButton 
          onClick={confirmDeleteEnterprise}
          disabled={deleteConfirmationInput !== '2024'}
        >
          Delete
        </DeleteButton>
        <CloseButton onClick={() => setIsDeleteModalOpen(false)}>Cancel</CloseButton>
      </ButtonGroup>
    </div>
  )}
</ReusableModal>

      {/* Add Enterprise Modal */}
      <ReusableModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        title="Add New Enterprise"
      >
        <form onSubmit={handleAddEnterprise}>
          {/* Name Field (Required) */}
          <FormGroup>
            <label htmlFor="new-name">Name:</label>
            <input
              type="text"
              id="new-name"
              name="name"
              value={newEnterpriseData.name}
              onChange={(e) => setNewEnterpriseData(prev => ({ ...prev, name: e.target.value }))}
              required
              placeholder="Enter enterprise name"
            />
          </FormGroup>

          {/* Industry Field (Optional) */}
          <FormGroup>
            <label htmlFor="new-industry">Industry:</label>
            <input
              type="text"
              id="new-industry"
              name="industry"
              value={newEnterpriseData.industry}
              onChange={(e) => setNewEnterpriseData(prev => ({ ...prev, industry: e.target.value }))}
              placeholder="Enter industry sector"
            />
          </FormGroup>

          {/* Contact Email Field (Required) */}
          <FormGroup>
            <label htmlFor="new-contact_email">Contact Email:</label>
            <input
              type="email"
              id="new-contact_email"
              name="contact_email"
              value={newEnterpriseData.contact_email}
              onChange={(e) => setNewEnterpriseData(prev => ({ ...prev, contact_email: e.target.value }))}
              required
              placeholder="Enter contact email"
            />
          </FormGroup>

          {/* Phone Number Field (Optional) */}
          <FormGroup>
            <label htmlFor="new-phone_number">Phone Number:</label>
            <input
              type="text"
              id="new-phone_number"
              name="phone_number"
              value={newEnterpriseData.phone_number}
              onChange={(e) => setNewEnterpriseData(prev => ({ ...prev, phone_number: e.target.value }))}
              placeholder="Enter phone number"
            />
          </FormGroup>

          {/* Address Fields (Optional) */}
          <FormGroup>
            <label htmlFor="new-address_street">Street:</label>
            <input
              type="text"
              id="new-address_street"
              name="address_street"
              value={newEnterpriseData.address.street}
              onChange={(e) => setNewEnterpriseData(prev => ({
                ...prev,
                address: { ...prev.address, street: e.target.value }
              }))}
              placeholder="Enter street address"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="new-address_city">City:</label>
            <input
              type="text"
              id="new-address_city"
              name="address_city"
              value={newEnterpriseData.address.city}
              onChange={(e) => setNewEnterpriseData(prev => ({
                ...prev,
                address: { ...prev.address, city: e.target.value }
              }))}
              placeholder="Enter city"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="new-address_state">State:</label>
            <input
              type="text"
              id="new-address_state"
              name="address_state"
              value={newEnterpriseData.address.state}
              onChange={(e) => setNewEnterpriseData(prev => ({
                ...prev,
                address: { ...prev.address, state: e.target.value }
              }))}
              placeholder="Enter state"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="new-address_zip_code">Zip Code:</label>
            <input
              type="text"
              id="new-address_zip_code"
              name="address_zip_code"
              value={newEnterpriseData.address.zip_code}
              onChange={(e) => setNewEnterpriseData(prev => ({
                ...prev,
                address: { ...prev.address, zip_code: e.target.value }
              }))}
              placeholder="Enter zip code"
            />
          </FormGroup>

          {/* Users Field */}
          <FormGroup>
            <label htmlFor="new-users">Users (Emails):</label>
            <select
              id="new-users"
              name="users"
              multiple
              value={newEnterpriseData.users}
              onChange={(e) => {
                const selectedOptions = Array.from(e.target.options)
                  .filter(option => option.selected)
                  .map(option => option.value);
                setNewEnterpriseData(prev => ({ ...prev, users: selectedOptions }));
              }}
            >
              {allUsers.map(user => (
                <option key={user.uid} value={user.uid}>{user.email}</option>
              ))}
            </select>
            <small>Hold down the Ctrl (Windows) or Command (Mac) key to select multiple options.</small>
          </FormGroup>

{/* Agents Field */}
<FormGroup>
  <label htmlFor="new-agents">Agents (Names):</label>
  <select
    id="new-agents"
    name="agents"
    multiple
    value={newEnterpriseData.agents}
    onChange={(e) => {
      const selectedOptions = Array.from(e.target.options)
        .filter(option => option.selected)
        .map(option => option.value);
      setNewEnterpriseData(prev => ({ ...prev, agents: selectedOptions }));
    }}
  >
    {allAgents.map(agent => {
      const isAssigned = agent.enterprise_id;
      return (
        <option 
          key={agent.id} 
          value={agent.id}
          disabled={isAssigned && !newEnterpriseData.agents.includes(agent.id)} // Allow selection if already selected
        >
          {agent.name} {isAssigned ? '(Already Assigned)' : ''}
        </option>
      );
    })}
  </select>
  <small>Hold down the Ctrl (Windows) or Command (Mac) key to select multiple options.</small>
</FormGroup>


          {/* Settings Fields */}
          <FormGroup>
            <label htmlFor="new-settings_max_agents">Max Agents:</label>
            <input
              type="number"
              id="new-settings_max_agents"
              name="settings_max_agents"
              value={newEnterpriseData.settings.max_agents}
              onChange={(e) => setNewEnterpriseData(prev => ({
                ...prev,
                settings: { ...prev.settings, max_agents: parseInt(e.target.value, 10) }
              }))}
              min="1"
              placeholder="Enter maximum number of agents"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="new-settings_support_plan">Support Plan:</label>
            <select
              id="new-settings_support_plan"
              name="settings_support_plan"
              value={newEnterpriseData.settings.support_plan}
              onChange={(e) => setNewEnterpriseData(prev => ({
                ...prev,
                settings: { ...prev.settings, support_plan: e.target.value }
              }))}
            >
              <option value="basic">Basic</option>
              <option value="premium">Premium</option>
              <option value="enterprise">Enterprise</option>
              {/* Add more plans as needed */}
            </select>
          </FormGroup>
          <FormGroup>
            <label htmlFor="new-settings_timezone">Timezone:</label>
            <select
              id="new-settings_timezone"
              name="settings_timezone"
              value={newEnterpriseData.settings.timezone}
              onChange={(e) => setNewEnterpriseData(prev => ({
                ...prev,
                settings: { ...prev.settings, timezone: e.target.value }
              }))}
            >
              <option value="America/Los_Angeles">America/Los_Angeles</option>
              <option value="Europe/London">Europe/London</option>
              <option value="Asia/Manila">Asia/Manila</option>
              {/* Add more timezones as needed */}
            </select>
          </FormGroup>

          {/* Active Status */}
          <FormGroup>
            <CheckboxLabel>
              <input
                type="checkbox"
                name="active"
                checked={newEnterpriseData.active}
                onChange={(e) => setNewEnterpriseData(prev => ({ ...prev, active: e.target.checked }))}
              />
              Active
            </CheckboxLabel>
          </FormGroup>

          {/* Save and Cancel Buttons */}
          <ButtonGroup>
            <SaveButton type="submit">Add Enterprise</SaveButton>
            <CloseButton type="button" onClick={() => setIsAddModalOpen(false)}>
              Cancel
            </CloseButton>
          </ButtonGroup>
        </form>
      </ReusableModal>
      </>
      ),
    },
    {
      label: 'Assignments',
      content: <AssignAdmin/>
    }
  ];

  return (
    <>
      <DashboardHeader />
      <BoxWrapper>
        
          <Tabs tabs={tabs}/>

      </BoxWrapper>
    </>
  );
};

export default Enterprises;