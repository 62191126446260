// src/utils/api.js

export const getApiUrl = () => {
    if (process.env.REACT_APP_API_URL) {
      return process.env.REACT_APP_API_URL;
    } else if (window.location.hostname.includes('helloandy.ai')) {
      return 'https://helloandy.ai/api';
    } else if (window.location.hostname.includes('localhost')) {
      return 'http://localhost:5001/api'; // Ensure Flask runs on port 5001
    } else {
      return 'http://52.21.146.149:5001/api'; // Default or fallback API URL
    }
  };
  