// Training.js
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FormattedText from '../helper/FormattedText';
import DashboardHeader from './DashboardHeader';
import TrainingSidebar from './Training_Sidebar';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  Timestamp,
  query,
  where
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';
import { BoxWrapper, BoxContainer } from './toolbox/Reusables';
import { useAuth } from '../contexts/AuthContext';

// [Your existing styled-components remain unchanged]



// Styling the main container for centralized view
const Container = styled.div`
  display: flex;
  height: calc(100vh - 200px); // Adjusting height to fit the header
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;

  @media (min-width: 1024px) { 
    // Desktop view
    flex-direction: row; 
  }

  @media (min-width: 768px) and (max-width: 1023px) { 
    // Tablet view
    flex-direction: row;
  }

  @media (max-width: 767px) { 
    // Mobile view (stacked layout)
    flex-direction: column;
  }

`;

const ChatContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  overflow: hidden;

  @media (min-width: 1024px) { 
    // Desktop view: Chat takes the remaining space after the sidebar and nav bar
    width: calc(100% - 500px); 
  }

  @media (min-width: 768px) and (max-width: 1023px) { 
    // Tablet view: Sidebar and chat each occupy 50%
    width: 50%; 
  }


  @media (max-width: 767px) { 
    width: 100%;
    min-height: calc(100vh - 200px); /* Ensure the chat container takes up most of the height */
    height: auto;
    display: flex;
    flex-direction: column;
  }

`;


const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
`;

const Label = styled.span`
  font-size: 16px;
  color: #333;
  margin-right: 20px;
`;

const BotSelector = styled.select`
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  outline: none;
  background-color: #f8f8f8;
`;

const Messages = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: #fafafa;
  display: flex;
  flex-direction: column;

  max-height: calc(100vh - 200px); /* Ensure the chat section is scrollable */

@media (max-width: 767px) {
  max-height: calc(100vh - 200px); /* Adjust this as needed */
}

`;

const EditIcon = styled.span`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
  color: #666; /* subtle gray to keep it minimal */
  transition: color 0.2s;

  &:after {
    content: '\\270E'; /* Unicode character for a pencil/edit icon */
  }

  &:hover {
    color: #333; /* Darken color on hover for better interaction feedback */
  }
`;


const OptionsMenu = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 10;
`;

const OptionItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f5f5f5;
  }
`;
const Message = styled.div`
  margin: 10px 0;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  background-color: ${props => props.user ? '#007bff' : '#e9ecef'};
  color: ${props => props.user ? 'white' : 'black'};
  align-self: ${props => props.user ? 'flex-end' : 'flex-start'};
  text-align: ${props => props.user ? 'right' : 'left'};
  position: relative;
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  border-top: 1px solid #ddd;
  padding: 10px;
  background: #fff;
  @media (max-width: 767px) {
    min-height: 60px; /* Consistent input size */
  }

`;

const UserInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  outline: none;
`;

const SendButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const ToggleButton = styled.button`
  display: none;

  @media (max-width: 767px) {
    display: block;
  margin: 10px auto; /* Centered horizontally */
  padding: 8px 15px;
  background-color: #8a3a7e;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #6e2c63;
  }

  }
`;

// Component Code
const Training = () => {
  const { enterpriseId, isPlatformAdmin } = useAuth(); // Destructure enterpriseId and isPlatformAdmin
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [agentsList, setAgentsList] = useState([]);
  const [selectedBot, setSelectedBot] = useState('');
  const messagesEndRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showMenuIndex, setShowMenuIndex] = useState(null);
  const [sessionId, setSessionId] = useState(uuidv4());

  // Initialize messageSequence using useRef
  const messageSequenceRef = useRef(0);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMenu = (index) => {
    setShowMenuIndex(showMenuIndex === index ? null : index);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  // Fetch agents from Firestore on component mount
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        let agentsCollectionRef;

        if (isPlatformAdmin) {
          // Platform admin: Fetch all agents
          agentsCollectionRef = collection(db, 'agents');
        } else if (enterpriseId) {
          // Regular user: Fetch agents associated with their enterprise
          agentsCollectionRef = query(
            collection(db, 'agents'),
            where('enterprise_id', '==', enterpriseId)
          );
        } else {
          // No enterprise association
          toast.error('User is not associated with any enterprise.');
          setAgentsList([]);
          return;
        }

        const snapshot = await getDocs(agentsCollectionRef);
        const agentsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAgentsList(agentsData);
      } catch (error) {
        console.error('Error fetching agents:', error);
        toast.error('Failed to load agents. Please try again.');
      }
    };

    fetchAgents();
  }, [enterpriseId, isPlatformAdmin]); // Re-run when enterpriseId or isPlatformAdmin changes

  // Reset sessionId, messages, and message sequence when selectedBot changes
  useEffect(() => {
    if (selectedBot) {
      setSessionId(uuidv4()); // Generate a new session ID
      setMessages([]);        // Clear the conversation history
      messageSequenceRef.current = 0; // Reset message sequence
    }
  }, [selectedBot]);

  // Function to save chat log to Firestore
  const saveChatLog = async (chatLogData) => {
    try {
      await addDoc(collection(db, 'chat_logs'), chatLogData);
      console.log('ChatLogData:', chatLogData);
      console.log('Chat log saved successfully.');
    } catch (error) {
      console.error('Error saving chat log:', error);
      console.error('Error code:', error.code);
      toast.error(`Failed to save chat log: ${error.message}`);
    }
  };

  const sendMessage = async () => {
    if (!userInput.trim()) {
      toast.warning('Please enter a message before sending.');
      return;
    }
    if (!selectedBot) {
      toast.warning('Please select an agent before sending messages.');
      return;
    }

    // Increment messageSequence
    messageSequenceRef.current += 1;
    const currentMessageSequence = messageSequenceRef.current;

    const startTime = Date.now();

    // Get the current user ID and email
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const senderId = currentUser ? currentUser.uid : "anonymous";
    const senderName = currentUser
      ? currentUser.displayName || currentUser.email || "anonymous"
      : "anonymous";

      const selectedAgent = agentsList.find(agent => agent.id === selectedBot);
      const agentName = selectedAgent ? selectedAgent.name : "Unknown Agent";
  
    // Tagging the user input with 'user' role
    const newUserMessages = [...messages, { role: 'user', content: userInput }];
    console.log("Sending message:", userInput);

    setMessages(newUserMessages);
    setUserInput('');

    const apiUrl = process.env.REACT_APP_API_URL ||
      (window.location.hostname.includes('helloandy.ai') ? 'https://helloandy.ai/api' :
      (window.location.hostname.includes('localhost') ? 'http://localhost:5001/api' : 'http://52.21.146.149:5001/api'));

    let botResponse = "";
    let result = "Success";
    let errorDetails = "";
    let processingTime = 0;

    try {
      const response = await fetch(`${apiUrl}/chat`, { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          message: userInput, 
          messages: newUserMessages, 
          session_id: sessionId,        // Use the updated sessionId
          selected_agent: selectedBot   // Include selected agent's ID
        })
      });

      processingTime = Date.now() - startTime; // in milliseconds

      if (!response.ok) {
        result = "Error";
        const errorData = await response.json();
        botResponse = `Error: ${errorData.error}`;
        errorDetails = errorData.error;
        setMessages(messages => [...messages, { role: 'assistant', content: botResponse }]);
        toast.error('Failed to fetch response from server.');
      } else {
        const data = await response.json();
        console.log("API Response Data:", data);
        botResponse = data.response;
        setMessages(messages => [...messages, { role: 'assistant', content: botResponse }]);
        toast.success('Message sent successfully!');
        scrollToBottom();
      }
    } catch (error) {
      processingTime = Date.now() - startTime; // in milliseconds
      botResponse = `Error: ${error.message}`;
      result = "Error";
      errorDetails = error.message;
      setMessages(messages => [...messages, { role: 'assistant', content: botResponse }]);
      toast.error('An error occurred while sending the message.');
    }

    // Create the chat log data
    const chatLogData = {
      timestamp: Timestamp.fromDate(new Date()),
      channel: "dashboard",
      ai_model: "gpt-4o",
      country: "Philippines",
      enterprise_id: "none",
      sender_id: senderId,
      sender_name: senderName,
      user_message: userInput,
      bot_response: botResponse,
      feedback: "",
      agent_id: selectedBot,
      agent_name: agentName,        // Added agent_name here
      result: result,
      remarks: "",
      session_id: sessionId,
      message_sequence: currentMessageSequence,
      language: "en-us",
      user_agent: "dashboard",
      sentiment: "",
      processing_time: processingTime || 0,
      error_details: errorDetails || "",
      context: { previous_intent: "" }
    };

    // Save to Firestore
    await saveChatLog(chatLogData);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent form submission
      sendMessage();
    }
  };

  return (
    <>
      <DashboardHeader/>
      <BoxWrapper>
        <BoxContainer>
      <Container>
        <TrainingSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <ChatContainer>
          <ToggleButton onClick={toggleSidebar}>
            {isSidebarOpen ? 'Close Sidebar' : 'Open Sidebar'}
          </ToggleButton>

          <SelectorContainer>
            <Label>Agent Options:</Label>

            <BotSelector value={selectedBot} onChange={(e) => setSelectedBot(e.target.value)}>
              <option value="">-- Select an Agent --</option>
              {agentsList.map(agent => (
                <option key={agent.id} value={agent.id}>{agent.name}</option>
              ))}
            </BotSelector>
          </SelectorContainer>
          <Messages>
            {messages.map((msg, index) => (
              <Message key={index} user={msg.role === 'user'}>
                <FormattedText text={msg.content}/>
                {msg.role === 'assistant' && (
                  <>
                    <EditIcon onClick={() => toggleMenu(index)} />
                    {showMenuIndex === index && (
                      <OptionsMenu>
                        <OptionItem onClick={() => console.log('Train')}>Train</OptionItem>
                        <OptionItem onClick={() => console.log('Comment')}>Comment</OptionItem>
                        <OptionItem onClick={() => console.log('Copy')}>Copy</OptionItem>
                      </OptionsMenu>
                    )}
                  </>
                )}
              </Message>
            ))}
            <div ref={messagesEndRef} />
          </Messages>

          <InputContainer>
            <UserInput
              type="text"
              placeholder="Type your message here"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <SendButton onClick={sendMessage} disabled={!selectedBot}>
              Send
            </SendButton>
          </InputContainer>
        </ChatContainer>
      </Container>
      </BoxContainer>
      </BoxWrapper>
    </>
  );
};

export default Training;
