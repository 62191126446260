// LogDetailsModal.js
import React from 'react';
import styled from 'styled-components';
import { FiX } from 'react-icons/fi';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: #f9f9f9;
  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  border-left: 1px solid #e0e0e0;

  &.open {
    transform: translateX(0);
  }
`;

const CloseButton = styled(FiX)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 28px;
  color: #555;
  transition: color 0.2s;

  &:hover {
    color: #000;
  }
`;

const Content = styled.div`
  padding: 30px;
  margin-top: 20px;
  overflow-y: auto;
  max-height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
`;

const DetailItem = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
`;

const DetailLabel = styled.span`
  font-weight: 600;
  color: #444;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
`;

const DetailValue = styled.span`
  color: #777;
  font-size: 15px;
  line-height: 1.6;
  word-wrap: break-word;
`;

const LogDetailsModal = ({ log, isOpen, onClose }) => {
  if (!log) return null;

  return (
    <ModalOverlay className={isOpen ? 'open' : ''}>
      <CloseButton onClick={onClose} />
      <Content>
        <Header>Log Details</Header>
        {Object.entries(log).map(([key, value]) => (
          <DetailItem key={key}>
            <DetailLabel>{key.replace(/_/g, ' ')}</DetailLabel>
            <DetailValue>
              {value instanceof Object && !(value instanceof Date)
                ? JSON.stringify(value, null, 2)
                : value
                  ? value.toString()
                  : '-'}
            </DetailValue>
          </DetailItem>
        ))}
      </Content>
    </ModalOverlay>
  );
};

export default LogDetailsModal;