import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa'; // For burger menu

// Header Section
const HeaderWrapper = styled.header`
  background: white;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 15px 30px; /* Adjust for smaller screens */
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Logo = styled.a`
  font-size: 24px;
  font-weight: 700;
  color: #6a11cb;
  text-decoration: none;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: #333;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #6a11cb;
  }
`;

const RightSection = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BurgerIcon = styled.div`
  font-size: 24px;
  cursor: pointer;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

// Mobile Menu
const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
`;

const MobileNavLink = styled.a`
  color: #333;
  font-size: 18px;
  margin-bottom: 30px; /* More space between items for elegance */
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #6a11cb;
  }
`;

const MobileButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LoginButton = styled.a`
  padding: 10px 20px;
  font-size: 1rem;
  color: #6a11cb;
  text-decoration: none;
  border: 1px solid #6a11cb;
  border-radius: 5px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #6a11cb;
    color: white;
  }
`;

const SignupButton = styled.a`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #6a11cb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5f0a87;
  }
`;

const Header = () => {
  const [open, setOpen] = useState(false);

  // Scroll Function for Smooth Scrolling
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <HeaderWrapper>
        <LeftSection>
          <Logo href="/">AndyAI</Logo>
          <NavLinks>
            <NavLink onClick={() => scrollToSection('features')}>Features</NavLink>
            <NavLink onClick={() => scrollToSection('use-cases')}>Use Cases</NavLink>
            <NavLink onClick={() => scrollToSection('testimonials')}>Testimonials</NavLink>
          </NavLinks>
        </LeftSection>

        <RightSection>
          <LoginButton href="/login">LOG IN</LoginButton>
          <SignupButton href="/signup">GET STARTED</SignupButton>
        </RightSection>

        <BurgerIcon onClick={() => setOpen(!open)}>
          {open ? <FaTimes /> : <FaBars />}
        </BurgerIcon>
      </HeaderWrapper>

      {/* Mobile Menu */}
      <MobileMenu open={open}>
        <MobileNavLink onClick={() => scrollToSection('features')}>Features</MobileNavLink>
        <MobileNavLink onClick={() => scrollToSection('pricing')}>Pricing</MobileNavLink>
        <MobileNavLink onClick={() => scrollToSection('about')}>About</MobileNavLink>
        <MobileButtonWrapper>
          <LoginButton href="/login">LOG IN</LoginButton>
          <SignupButton href="/signup">GET STARTED</SignupButton>
        </MobileButtonWrapper>
      </MobileMenu>
    </>
  );
};

export default Header;
