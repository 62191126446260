// /src/components/PrivacyPolicy.js
import React from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';

const Container = styled.div`
  padding: 60px 15%;
  background-color: #fff;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  min-height: calc(100vh - 200px); /* Adjusted for header and footer */

  @media (max-width: 768px) {
    padding: 40px 10%;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #6a11cb;
  margin-bottom: 40px;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin: 0;
`;

const Icon = styled(FaChevronDown)`
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  color: #6a11cb;
`;

const Content = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-top: 15px;
`;

const PrivacyPolicy = () => {
  const [activeSections, setActiveSections] = React.useState({});

  const toggleSection = (index) => {
    setActiveSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const sections = [
    {
      title: 'Introduction',
      content: (
        <>
          <Paragraph>
            Welcome to AndyAI. Your privacy is critically important to us. This Privacy Policy explains how we collect, use, and protect your personal information.
          </Paragraph>
          <Paragraph>
            By using our services, you agree to the collection and use of information in accordance with this policy.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Information We Collect',
      content: (
        <>
          <Paragraph>
            We collect various types of information for different purposes to provide and improve our services to you.
          </Paragraph>
          <ul>
            <li>
              <strong>Personal Data:</strong> Information that can be used to identify you personally, such as your name, email address, and phone number.
            </li>
            <li>
              <strong>Usage Data:</strong> Information on how you access and use our services.
            </li>
            <li>
              <strong>Cookies and Tracking Data:</strong> Data collected using cookies and similar tracking technologies.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'How We Use Your Information',
      content: (
        <>
          <Paragraph>AndyAI uses the collected data for various purposes:</Paragraph>
          <ul>
            <li>To provide and maintain our service</li>
            <li>To notify you about changes to our service</li>
            <li>To allow you to participate in interactive features</li>
            <li>To provide customer support</li>
            <li>To gather analysis to improve our service</li>
            <li>To monitor usage</li>
            <li>To detect, prevent, and address technical issues</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Data Sharing and Disclosure',
      content: (
        <>
          <Paragraph>
            We may share your personal data with third parties under certain circumstances:
          </Paragraph>
          <ul>
            <li>
              <strong>Service Providers:</strong> To facilitate our service, provide the service on our behalf, or assist us in analyzing how our service is used.
            </li>
            <li>
              <strong>Compliance with Laws:</strong> If required to do so by law or in response to valid requests by public authorities.
            </li>
            <li>
              <strong>Business Transfers:</strong> In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'Your Rights Under GDPR',
      content: (
        <>
          <Paragraph>
            If you are a resident of the European Economic Area (EEA), you have certain data protection rights:
          </Paragraph>
          <ul>
            <li>The right to access, update, or delete your personal information</li>
            <li>The right to rectification</li>
            <li>The right to object</li>
            <li>The right of restriction</li>
            <li>The right to data portability</li>
            <li>The right to withdraw consent</li>
          </ul>
          <Paragraph>Please contact us to exercise these rights.</Paragraph>
        </>
      ),
    },
    {
      title: 'Data Security',
      content: (
        <>
          <Paragraph>
            We value your trust in providing us your personal information and strive to use commercially acceptable means of protecting it. However, remember that no method of transmission over the internet or electronic storage is 100% secure.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'International Data Transfers',
      content: (
        <>
          <Paragraph>
            Your information may be transferred to and maintained on computers located outside of your state, province, country, or governmental jurisdiction where the data protection laws may differ.
          </Paragraph>
          <Paragraph>
            We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Changes to This Policy',
      content: (
        <>
          <Paragraph>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </Paragraph>
          <Paragraph>
            We recommend reviewing this Privacy Policy periodically for any changes.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Contact Us',
      content: (
        <>
          <Paragraph>
            If you have any questions about this Privacy Policy, please contact us:
          </Paragraph>
          <ul>
            <li>
              Email: <a href="mailto:andy@helloandyai.com">andy@helloandyai.com</a>
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
      <Container>
        <Title>Privacy Policy</Title>
        {sections.map((section, index) => (
          <Section key={index}>
            <SectionHeader
              onClick={() => toggleSection(index)}
              role="button"
              aria-expanded={activeSections[index] ? 'true' : 'false'}
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleSection(index);
                }
              }}
            >
              <SectionTitle>{section.title}</SectionTitle>
              <Icon isOpen={activeSections[index]} />
            </SectionHeader>
            <Content isOpen={activeSections[index]}>
              {section.content}
            </Content>
          </Section>
        ))}
      </Container>
  );
};

export default PrivacyPolicy;
