// training_sidebar.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BotIcon from '@mui/icons-material/Android';
import SaveIcon from '@mui/icons-material/Save';
import PublishIcon from '@mui/icons-material/Publish';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CloseIcon from '@mui/icons-material/Close';  // Import Close icon
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreCircleIcon from '@mui/icons-material/ExpandMore';
import { db } from '../firebase'; // Import Firestore instance
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  setDoc,
  Timestamp,
} from 'firebase/firestore'; // Import Firestore functions
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';


// Styled Components
const Sidebar = styled.div`
  background-color: #fff;
  max-width: 500px;
  overflow-y: auto;
  padding: 20px;
  border-right: 2px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px 0 0 8px;

  @media (min-width: 1024px) {
    width: 500px;
    display: block;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 80%;
    position: fixed; /* Changed to fixed to avoid shifting content */
    top: 0;
    left: ${({ isOpen }) => (isOpen ? '0' : '-120%')}; /* Move completely off-screen */
    height: 100vh;
    z-index: 10;
    transition: left 0.3s ease;
    overflow-x: hidden; /* Prevent horizontal scroll issue */
  }
`;



const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const SystemInstruction = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #444;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: calc(100% - 16px);
  height: ${({ $expanded }) => ($expanded ? '300px' : '40px')}; /* Toggle-able Height */
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: height 0.3s ease; /* Smooth Toggle */
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 8px 10px 8px 34px;
  border: none;
  background: transparent;
  appearance: none;
  color: #333;
  font-size: 16px;
`;

const DropdownIcon = styled(ExpandMoreIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
`;

const BotIconStyled = styled(BotIcon)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  font-size: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  &:hover {
    background-color: #e0e0e0;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const Icon = styled.span`
  margin-right: 8px;
`;

/* Styled Components for Toggle-able Text Areas */
const ToggleContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  margin-bottom: 10px;
`;

// Mobile-specific close button
const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 20px;

  @media (max-width: 767px) {
    display: block;
  }
`;



/* Snackbar Alert Component */
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TrainingSidebar = ({isOpen, toggleSidebar}) => {
  const {enterpriseId, isPlatformAdmin} = useAuth();
  const [agents, setAgents] = useState([]);
  const [aiModels, setAiModels] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);

  /* Editable Fields State */
  const [agentInstruction, setAgentInstruction] = useState('');
  const [storeInformation, setStoreInformation] = useState('');
  const [creativityLevel, setCreativityLevel] = useState('');
  const [responseLength, setResponseLength] = useState('');
  const [internalFeedback, setInternalFeedback] = useState('');

  const [isAgentDescExpanded, setIsAgentDescExpanded] = useState(false);
  const [isStoreInfoExpanded, setIsStoreInfoExpanded] = useState(false);

  /* Snackbar States */
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  // Function to fetch agents
  const fetchAgents = async () => {
    try {
      let agentsCollectionRef;

      if (isPlatformAdmin) {
        // Platform admin: Fetch all agents
        agentsCollectionRef = collection(db, 'agents');
      } else if (enterpriseId) {
        // Regular user: Fetch agents associated with their enterprise
        agentsCollectionRef = query(
          collection(db, 'agents'),
          where('enterprise_id', '==', enterpriseId)
        );
      } else {
        // No enterprise association
        setSnackbarMessage('User is not associated with any enterprise.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        setAgents([]);
        setAiModels([]);
        return;
      }

      const snapshot = await getDocs(agentsCollectionRef);
      const agentsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgents(agentsData);

      // Extract unique AI models for the AI Model dropdown
      const uniqueAiModels = [
        ...new Set(agentsData.map((agent) => agent.ai_model)),
      ];
      setAiModels(uniqueAiModels);
    } catch (error) {
      console.error('Error fetching agents: ', error);
      setSnackbarMessage('Failed to fetch agents.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Fetch agents when the component mounts or when enterpriseId or isPlatformAdmin changes
  useEffect(() => {
    fetchAgents();
  }, [enterpriseId, isPlatformAdmin]);

  // Initialize editable fields when selectedAgent changes
  useEffect(() => {
    if (selectedAgent) {
      setAgentInstruction(selectedAgent.agent_instruction || '');
      setStoreInformation(
        selectedAgent.store_information
          ? JSON.stringify(selectedAgent.store_information, null, 2)
          : ''
      );
      setCreativityLevel(
        selectedAgent.creativity_level !== undefined
          ? selectedAgent.creativity_level
          : ''
      );
      setResponseLength(
        selectedAgent.response_length && selectedAgent.response_length.max
          ? selectedAgent.response_length.max
          : ''
      );
      setInternalFeedback(selectedAgent.internal_feedback || '');
    } else {
      // Reset editable fields if no agent is selected
      setAgentInstruction('');
      setStoreInformation('');
      setCreativityLevel('');
      setResponseLength('');
      setInternalFeedback('');
    }
  }, [selectedAgent]);

  /* Snackbar Close Handler */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  /* Save Handler */
  const handleSave = async () => {
    if (!selectedAgent) {
      setSnackbarMessage('No agent selected to save.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      // Parse the store information JSON
      const parsedStoreInformation = JSON.parse(storeInformation);

      if (selectedAgent.id.endsWith('_saved')) {
        // Agent is already a '_saved' version; perform an overwrite
        const agentRef = doc(db, 'agents', selectedAgent.id);
        await updateDoc(agentRef, {
          name: selectedAgent.name, // Name remains the same
          agent_instruction: agentInstruction,
          store_information: parsedStoreInformation,
          response_length: { max: parseInt(responseLength, 10) || 150 },
          internal_feedback: internalFeedback,
          ai_model: selectedAgent.ai_model,
          last_updated: Timestamp.now(),
        });

        const newAgentSnackbar = "Agent overwritten successfully, check agent name: " + selectedAgent.name; 
        setSnackbarMessage(newAgentSnackbar);

        setSnackbarSeverity('success');
        setOpenSnackbar(true);

        // Refetch agents to update the state
        fetchAgents();
      } else {
        // Agent is not a '_saved' version; create or overwrite a '_saved' agent
        const newAgentName = `${selectedAgent.name}_saved`;
        const newAgentId = `${selectedAgent.id}_saved`;

        // Check if a '_saved' agent already exists for this agent
        const savedAgentQuery = query(
          collection(db, 'agents'),
          where('__name__', '==', newAgentId)
        );
        const savedAgentSnapshot = await getDocs(savedAgentQuery);

        if (!savedAgentSnapshot.empty) {
          // '_saved' agent exists; overwrite it
          const savedAgentDoc = savedAgentSnapshot.docs[0];
          const savedAgentRef = doc(db, 'agents', savedAgentDoc.id);
          await updateDoc(savedAgentRef, {
            name: newAgentName,
            agent_instruction: agentInstruction,
            store_information: parsedStoreInformation,
            response_length: { max: parseInt(responseLength, 10) || 150 },
            internal_feedback: internalFeedback,
            ai_model: selectedAgent.ai_model,
            last_updated: Timestamp.now(),
          });

          const newAgentSnackbar = "Saved agent overwritten successfully, check agent name: " + newAgentName; 

          setSnackbarMessage(newAgentSnackbar);
          setSnackbarSeverity('success');
          setOpenSnackbar(true);

          // Refetch agents to update the state
          fetchAgents();
        } else {
          // '_saved' agent does not exist; create a new one
          const newAgentData = {
            name: newAgentName,
            ai_model: selectedAgent.ai_model,
            agent_instruction: agentInstruction,
            store_information: parsedStoreInformation,
            creativity_level: parseInt(creativityLevel, 10) || 1,
            response_length: { max: parseInt(responseLength, 10) || 150 },
            internal_feedback: internalFeedback,
            knowledge_database: selectedAgent.knowledge_database || [],
            timestamp: Timestamp.now(),
            last_updated: Timestamp.now(),
          };

          // Create a new agent document with a specific ID
          await setDoc(doc(db, 'agents', newAgentId), newAgentData);

          setSnackbarMessage('Agent saved successfully.');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);

          // Refetch agents to update the state
          fetchAgents();
        }
      }
    } catch (error) {
      console.error('Error saving agent:', error);
      setSnackbarMessage('Failed to save agent. Ensure Store Information is valid JSON.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  /* Publish Handler */
  const handlePublish = async () => {
    if (!selectedAgent) {
      setSnackbarMessage('No agent selected to publish.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
  
    const confirmPublish = window.confirm(
      'Are you sure you want to publish this agent? This action will affect the live version.'
    );
  
    if (!confirmPublish) {
      return;
    }
  
    try {
      if (selectedAgent.id.endsWith('_saved')) {
        // Step 1: Identify the original agent's ID
        const originalAgentId = selectedAgent.id.replace('_saved', '');
  
        // Step 2: Find the original agent in the agents array
        const originalAgent = agents.find((agent) => agent.id === originalAgentId);
  
        if (!originalAgent) {
          setSnackbarMessage('Original agent not found.');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
          return;
        }
  
        // Step 3: Update the original agent with the '_saved' agent's data
        const originalAgentRef = doc(db, 'agents', originalAgentId);
        await updateDoc(originalAgentRef, {
          agent_instruction: agentInstruction,
          store_information: JSON.parse(storeInformation),
          creativity_level: parseInt(creativityLevel, 10) || 1,
          response_length: { max: parseInt(responseLength, 10) || 150 },
          internal_feedback: internalFeedback,
          ai_model: selectedAgent.ai_model,
          published: true, // Mark as published
          last_updated: Timestamp.now(),
        });
  
        // Step 4: Show success notification
        setSnackbarMessage('Original agent updated and published successfully.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
  
        // Refetch agents to ensure the UI is updated
        fetchAgents();
  
      } else {
        // Case when publishing the original agent directly
        const agentRef = doc(db, 'agents', selectedAgent.id);
        await updateDoc(agentRef, {
          agent_instruction: agentInstruction,
          store_information: JSON.parse(storeInformation),
          creativity_level: parseInt(creativityLevel, 10) || 1,
          response_length: { max: parseInt(responseLength, 10) || 150 },
          internal_feedback: internalFeedback,
          ai_model: selectedAgent.ai_model,
          published: true, // Mark as published
          last_updated: Timestamp.now(),
        });
  
        // Show success notification for original agent
        setSnackbarMessage('Agent published successfully.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
  
        // Refetch agents to ensure the UI is updated
        fetchAgents();
      }
    } catch (error) {
      console.error('Error publishing agent:', error);
      setSnackbarMessage('Failed to publish agent.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };
  
  return (
    <Sidebar isOpen={isOpen}>
        <CloseButton onClick={toggleSidebar}>
        <CloseIcon />
      </CloseButton>

      <Title>Training</Title>
      <SystemInstruction>
        Here, you can teach and tune your AI agent how to handle customer inquiries.
      </SystemInstruction>

      {/* Agents Dropdown */}
      <DropdownContainer>
        <BotIconStyled />
        <Dropdown
          value={selectedAgent ? selectedAgent.id : ''}
          onChange={(e) => {
            const agentId = e.target.value;
            const agent = agents.find((agent) => agent.id === agentId);
            setSelectedAgent(agent || null);
          }}
        >
          <option value="" disabled>
            Select an Agent
          </option>
          {agents.map((agent) => (
            <option key={agent.id} value={agent.id}>
              {agent.name}
            </option>
          ))}
        </Dropdown>
        <DropdownIcon />
      </DropdownContainer>

      {/* AI Model Dropdown */}
      <InputLabel htmlFor="model-select">Choose an AI Model</InputLabel>
      <Input
        as="select"
        id="model-select"
        value={selectedAgent ? selectedAgent.ai_model : ''}
        onChange={(e) => {
          if (selectedAgent) {
            setSelectedAgent({ ...selectedAgent, ai_model: e.target.value });
          }
        }}
      >
        <option value="" disabled>
          Select AI Model
        </option>
        {aiModels.map((model, index) => (
          <option key={index} value={model}>
            {model}
          </option>
        ))}
      </Input>

      {/* Agent Description */}
      <InputLabel htmlFor="agent-description">Agent Description</InputLabel>
      <ToggleContainerStyled>
        <IconButton onClick={() => setIsAgentDescExpanded(!isAgentDescExpanded)}>
          {isAgentDescExpanded ? <ExpandLessIcon /> : <ExpandMoreCircleIcon />}
        </IconButton>
      </ToggleContainerStyled>
      <TextArea
        id="agent-description"
        placeholder="Describe the agent's personality and functions."
        $expanded={isAgentDescExpanded}
        value={agentInstruction}
        onChange={(e) => {
          setAgentInstruction(e.target.value);
        }}
      />

      {/* Store Information */}
      <InputLabel htmlFor="store-information">Store Information</InputLabel>
      <ToggleContainerStyled>
        <IconButton onClick={() => setIsStoreInfoExpanded(!isStoreInfoExpanded)}>
          {isStoreInfoExpanded ? <ExpandLessIcon /> : <ExpandMoreCircleIcon />}
        </IconButton>
      </ToggleContainerStyled>
      <TextArea
        id="store-information"
        placeholder="Details about the store or services provided."
        $expanded={isStoreInfoExpanded}
        value={storeInformation}
        onChange={(e) => {
          setStoreInformation(e.target.value);
        }}
      />

      {/* Advanced Settings */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="advanced-content"
          id="advanced-header"
        >
          <strong>Advanced</strong>
        </AccordionSummary>
        <AccordionDetails>
          {/* Creativity Level */}
          <InputLabel htmlFor="creativity-level">Creativity Level</InputLabel>
          <Input
            type="number"
            id="creativity-level"
            min="1"
            max="10"
            value={creativityLevel}
            onChange={(e) => {
              let value = parseInt(e.target.value, 10);
              if (isNaN(value)) value = '';
              if (value < 1) value = 1;
              if (value > 10) value = 10;
              setCreativityLevel(value);
            }}
          />

          {/* Response Length */}
          <InputLabel htmlFor="response-length">Response Length (Max)</InputLabel>
          <Input
            type="number"
            id="response-length"
            min="100"
            max="250"
            placeholder="Maximum response length (150-250)"
            value={responseLength}
            onChange={(e) => {
              let value = parseInt(e.target.value, 10);
              if (isNaN(value)) value = '';
              if (value < 100) value = 100;
              if (value > 250) value = 250;
              setResponseLength(value);
            }}
          />

          {/* Internal Feedback */}
          <InputLabel htmlFor="internal-feedback">Internal Feedback</InputLabel>
          <Input
            type="text"
            id="internal-feedback"
            placeholder="Improvement & issue feedback for the devs"
            value={internalFeedback}
            onChange={(e) => {
              setInternalFeedback(e.target.value);
            }}
          />
        </AccordionDetails>
      </Accordion>

      {/* Action Buttons */}
      <ButtonContainer>
        <Button onClick={handleSave}>
          <Icon>
            <SaveIcon />
          </Icon>
          Save
        </Button>
        <Button onClick={handlePublish}>
          <Icon>
            <PublishIcon />
          </Icon>
          Publish
        </Button>
      </ButtonContainer>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Sidebar>
  );
};

export default TrainingSidebar;
