// src/components/toolbox/ReusableModal.js
import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #fff;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  padding: 30px 40px;
  position: relative;
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
  max-height: 80vh;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-out;

  @media (max-width: 768px) {
    padding: 20px 25px;
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  font-size: 1.75rem;
  margin: 0;
  color: #333;
`;

const CloseIcon = styled(FaTimes)`
  cursor: pointer;
  color: #666;
  transition: color 0.2s;

  &:hover {
    color: #000;
  }
`;

const ModalBody = styled.div`
  font-size: 1rem;
  color: #555;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

const ReusableModal = ({ isOpen, onClose, title, children, footer }) => {
  if (!isOpen) return null;

  return (
    <Overlay 
      onClick={onClose} 
      role="dialog" 
      aria-modal="true" 
      aria-labelledby="modal-title"
    >
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle id="modal-title">{title}</ModalTitle>
          <CloseIcon onClick={onClose} aria-label="Close Modal" />
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        {footer && (
          <ButtonGroup>
            {footer}
          </ButtonGroup>
        )}
      </ModalContainer>
    </Overlay>
  );
};

export default ReusableModal;
