// Tabs.js

import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components for Tabs
const TabsContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const TabHeaders = styled.div`
  display: flex;
  border-bottom: 2px solid #e2e8f0;
  border-radius: 8px 8px 0 0; /* Semi-rounded top edges */
  overflow: hidden; /* Prevent overflow of border-radius */
`;

const TabHeader = styled.button`
  flex: 1;
  padding: 12px 16px;
  background: ${({ $active }) => ($active ? '#5a67d8' : '#f0f0f0')}; /* Active: Dark Blue, Inactive: Light Gray */
  color: ${({ $active }) => ($active ? 'white' : '#4a5568')};
  border: none;
  border-right: 1px solid #e2e8f0;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;

  &:last-child {
    border-right: none; /* Remove border from the last tab */
  }

  &:hover {
    background: ${({ $active }) => ($active ? '#434190' : '#dcdcdc')}; /* Darken active tab, lighten inactive on hover */
  }

  &:focus {
    outline: none;
  }

  /* Semi-rounded corners for first and last tabs */
  border-radius: ${({ $active, $index, $total }) => {
    if ($active && $index === 0) return '8px 0 0 0';
    if ($active && $index === $total - 1) return '0 8px 0 0';
    return '0';
  }};
`;

const TabContent = styled.div`
  padding: 20px;
  background: #f9fafb; /* Light background for content area */
  border: 2px solid #e2e8f0;
  border-top: none;
  border-radius: 0 0 8px 8px; /* Semi-rounded bottom edges */
`;

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const totalTabs = tabs.length;

  return (
    <TabsContainer>
      <TabHeaders>
        {tabs.map((tab, index) => (
          <TabHeader
            key={tab.label}
            $active={activeTab === tab.label}
            onClick={() => setActiveTab(tab.label)}
            $index={index}
            $total={totalTabs}
          >
            {tab.label}
          </TabHeader>
        ))}
      </TabHeaders>
      <TabContent>
        {tabs.map(
          (tab) =>
            activeTab === tab.label && <div key={tab.label}>{tab.content}</div>
        )}
      </TabContent>
    </TabsContainer>
  );
};

export default Tabs;
