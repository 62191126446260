// src/utils/enterpriseService.js
import { collection, where, doc, addDoc, writeBatch, getDocs, getDoc, setDoc, updateDoc, deleteDoc, serverTimestamp, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
/**
 * Generates the next enterprise ID in the format 'enterprise_XXXX'.
 * Note: This implementation is simplistic and may lead to race conditions in concurrent environments.
 * For production, consider using Firestore transactions or a dedicated counters collection.
 */
const generateEnterpriseId = async () => {
  const enterprisesRef = collection(db, 'enterprises');
  const q = query(enterprisesRef, orderBy('created_at', 'desc'), limit(1));
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    return 'enterprise_0001';
  }

  const lastDoc = snapshot.docs[0];
  const lastId = lastDoc.id; // e.g., 'enterprise_0001'
  const lastNumber = parseInt(lastId.split('_')[1], 10);
  const nextNumber = lastNumber + 1;

  return `enterprise_${nextNumber.toString().padStart(4, '0')}`;
};

/**
 * Fetches all enterprises from Firestore.
 */
export const getEnterprises = async () => {
  try {
    const enterprisesRef = collection(db, 'enterprises');
    const snapshot = await getDocs(enterprisesRef);
    const enterprises = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return { success: true, enterprises };
  } catch (error) {
    console.error('Error fetching enterprises:', error);
    return { success: false, error };
  }
};

/**
 * Fetches all agents from Firestore.
 * @returns {Promise<{success: boolean, agents?: Array, error?: string}>}
 */
export const getAgents = async (enterprise_id = null) => {
  try {
    const agentsCollection = collection(db, 'agents');
    let agentsQuery = agentsCollection;

    if (enterprise_id) {
      // If enterprise_id is provided, filter agents by enterprise_id
      agentsQuery = query(agentsCollection, where('enterprise_id', '==', enterprise_id));
    }

    const snapshot = await getDocs(agentsQuery);
    const agents = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    return { success: true, agents };
  } catch (error) {
    console.error('Error fetching agents:', error);
    return { success: false, error };
  }
};

/**
 * Fetches all users from Firestore.
 * Returns an array of objects with `uid` and `email`.
 */
export const getUsersForDropdown = async () => {
  try {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    const users = snapshot.docs.map(doc => ({ uid: doc.data().uid, email: doc.data().email }));
    return { success: true, users };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { success: false, error };
  }
};


/**
 * Adds a new enterprise to Firestore and associates selected users.
 * @param {Object} enterpriseData - The data of the enterprise to add.
 * @param {Array<string>} userIds - Array of user UIDs to associate with the enterprise.
 */
export const addEnterprise = async (enterpriseData, userIds = []) => {
  try {
    const enterpriseId = await generateEnterpriseId();
    const enterpriseRef = doc(db, 'enterprises', enterpriseId);

    // Check for duplicate enterprise name
    const q = query(collection(db, 'enterprises'), where('name', '==', enterpriseData.name));
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
      return { success: false, error: 'Enterprise with this name already exists.' };
    }

    const dataToAdd = {
      ...enterpriseData,
      id: enterpriseId,
      created_at: serverTimestamp(),
      last_updated: serverTimestamp(),
      active: true, // Default to active
    };

    const batch = writeBatch(db);

    // Add the enterprise document
    batch.set(enterpriseRef, dataToAdd);

    // Associate users with the enterprise
    const usersRef = collection(db, 'users');
    userIds.forEach(uid => {
      const userDocRef = doc(usersRef, uid);
      batch.update(userDocRef, { enterprise_id: enterpriseId });
    });

    // Commit the batch
    await batch.commit();

    // Fetch the newly added document to get the serverTimestamp values
    const addedDoc = await getDoc(enterpriseRef);
    if (addedDoc.exists()) {
      const addedData = { id: addedDoc.id, ...addedDoc.data() };
      return { success: true, id: enterpriseId, enterprise: addedData };
    } else {
      return { success: false, error: 'Failed to retrieve the added enterprise.' };
    }
  } catch (error) {
    console.error('Error adding enterprise:', error);
    return { success: false, error };
  }
};

/**
 * Updates an existing enterprise in Firestore and manages user associations.
 * @param {string} id - The document ID of the enterprise to update.
 * @param {Object} updatedData - The data to update.
 * @param {Array<string>} newUserIds - Array of user UIDs to associate with the enterprise.
 */
export const updateEnterprise = async (id, updatedData, newUserIds = []) => {
  try {
    const enterpriseRef = doc(db, 'enterprises', id);

    const batch = writeBatch(db);

    // Update the enterprise document
    batch.update(enterpriseRef, {
      ...updatedData,
      last_updated: serverTimestamp(),
    });

    // Fetch current associated users
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('enterprise_id', '==', id));
    const currentUsersSnapshot = await getDocs(q);
    const currentUserIds = currentUsersSnapshot.docs.map(doc => doc.id);

    // Determine users to add and remove
    const usersToAdd = newUserIds.filter(uid => !currentUserIds.includes(uid));
    const usersToRemove = currentUserIds.filter(uid => !newUserIds.includes(uid));

    // Associate new users
    usersToAdd.forEach(uid => {
      const userDocRef = doc(usersRef, uid);
      batch.update(userDocRef, { enterprise_id: id });
    });

    // Dissociate removed users
    usersToRemove.forEach(uid => {
      const userDocRef = doc(usersRef, uid);
      batch.update(userDocRef, { enterprise_id: null }); // or delete the field
    });

    // Commit the batch
    await batch.commit();

    return { success: true };
  } catch (error) {
    console.error('Error updating enterprise:', error);
    return { success: false, error };
  }
};

/**
 * Deletes an enterprise and all associated enterprise_agents from Firestore.
 * @param {string} id - The document ID of the enterprise to delete.
 * @returns {Object} - Success status and message.
 */
export const deleteEnterprise = async (id) => {
  try {
    const batch = writeBatch(db);
    
    // Reference to the 'enterprise_agents' collection
    const enterpriseAgentsRef = collection(db, 'enterprise_agents');
    
    // Query all 'enterprise_agents' where 'enterprise_id' == id
    const q = query(
      enterpriseAgentsRef, 
      where('enterprise_id', '==', id)
    );
    
    const snapshot = await getDocs(q);
    
    // Iterate through each 'enterprise_agents' document and add a delete operation to the batch
    snapshot.forEach(docSnapshot => {
      batch.delete(doc(db, 'enterprise_agents', docSnapshot.id));
    });
    
    // Reference to the enterprise document
    const enterpriseRef = doc(db, 'enterprises', id);
    
    // Add a delete operation for the enterprise document to the batch
    batch.delete(enterpriseRef);
    
    // Commit the batch
    await batch.commit();
    
    return { success: true };
  } catch (error) {
    console.error('Error deleting enterprise and its associations:', error);
    return { success: false, error: error.message || error };
  }
};


/**
 * Associates an agent with an enterprise.
 * @param {string} enterpriseId - The ID of the enterprise.
 * @param {string} agentId - The ID of the agent.
 * @returns {Object} - Success status and message.
 */

export const associateAgentWithEnterprise = async (enterpriseId, agentId) => {
  try {
    const enterpriseAgentsRef = collection(db, 'enterprise_agents');
    const q = query(
      enterpriseAgentsRef, 
      where('enterprise_id', '==', enterpriseId), 
      where('agent_id', '==', agentId)
    );

    const existingAssoc = await getDocs(q);

    if (!existingAssoc.empty) {
      console.log(`Association between Enterprise "${enterpriseId}" and Agent "${agentId}" already exists.`);
      return { success: false, message: 'Association already exists.' };
    }

    await addDoc(enterpriseAgentsRef, {
      enterprise_id: enterpriseId,
      agent_id: agentId,
      timestamp: serverTimestamp(),
    });

    return { success: true };
  } catch (error) {
    console.error('Error associating agent with enterprise:', error);
    return { success: false, error: error.message || error };
  }
};

/**
 * Dissociates an agent from an enterprise.
 * @param {string} enterpriseId - The ID of the enterprise.
 * @param {string} agentId - The ID of the agent.
 * @returns {Object} - Success status and message.
 */
export const dissociateAgentFromEnterprise = async (enterpriseId, agentId) => {
  try {
    const enterpriseAgentsRef = collection(db, 'enterprise_agents');
    const q = query(
      enterpriseAgentsRef, 
      where('enterprise_id', '==', enterpriseId), 
      where('agent_id', '==', agentId)
    );

    const assocSnapshot = await getDocs(q);

    if (assocSnapshot.empty) {
      console.log(`No existing association between Enterprise "${enterpriseId}" and Agent "${agentId}".`);
      return { success: false, message: 'No existing association found.' };
    }

    const batch = writeBatch(db);

    assocSnapshot.forEach(docSnapshot => {
      batch.delete(doc(db, 'enterprise_agents', docSnapshot.id));
    });

    await batch.commit();

    return { success: true };
  } catch (error) {
    console.error('Error dissociating agent from enterprise:', error);
    return { success: false, error };
  }
};



/**
 * Updates the enterprise_id of a specific agent.
 * @param {string} agentId - The ID of the agent to update.
 * @param {string} enterpriseId - The new enterprise ID to assign.
 * @returns {Promise<{success: boolean, error?: string}>}
 */
export const updateAgentEnterpriseId = async (agentId, enterpriseId) => {
  try {
    const agentRef = doc(db, 'agents', agentId);
    await updateDoc(agentRef, { enterprise_id: enterpriseId, last_updated: new Date() });
    return { success: true };
  } catch (error) {
    console.error(`Error updating enterprise_id for agent ${agentId}:`, error);
    return { success: false, error: error.message };
  }
};

