// BoxContainer.js (Reusable Container Styling Component)
import styled from 'styled-components';

export const BoxWrapper = styled.div`
  background: var(--color-light-overall-background);
  padding: 20px 50px; // Provides space around the container
  box-sizing: border-box; // Ensures padding is included in the total size
`;

export const BoxContainer = styled.div`
  background: #ffffff;
  color: rgb(0, 0, 0);
  padding: 20px;
  box-sizing: border-box; // Ensures padding is included in the total size
  border-radius: 8px; // Less rounded corners for a sharper look
  box-shadow: var(--box-shadow-light); /* Lighter, modern shadow */
`;

export const Loader = styled.div`
border: 8px solid #f3f3f3; /* Light gray */
border-top: 8px solid #5a67d8; /* Blue */
border-radius: 50%;
width: 60px;
height: 60px;
animation: spin 1s linear infinite;
margin: 50px auto; /* Center horizontally and add margin-top */

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;
