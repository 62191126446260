// ChatLogs.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DashboardHeader from './DashboardHeader';
import { db } from '../firebase';
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  limit,
  startAfter,
  where,
  Timestamp,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import {
  FiSearch,
  FiRefreshCw,
} from 'react-icons/fi';
import { HiOutlineDownload } from 'react-icons/hi';
import { FaThumbsUp, FaThumbsDown, FaExpandArrowsAlt } from 'react-icons/fa';
import Fuse from 'fuse.js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import LogDetailsModal from './toolbox/LogDetailsModal';
import { BoxWrapper, BoxContainer } from './toolbox/Reusables';
import { useAuth } from '../contexts/AuthContext';


// Styled Components

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: #333;
  display: flex;
  align-items: center;
`;

const RefreshButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    transform: rotate(20deg);
  }
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 400px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-right: 20px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: none;
  outline: none;
`;

const FilterSelectGroup = styled.div`
  display: flex;
  align-items: center;
`;

const FilterSelect = styled.select`
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-right: 10px;
`;

const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;
const ChatTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  font-family: 'Inter', sans-serif; /* Modern font */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05); /* Soft shadow for table */
  table-layout: auto; /* Allows columns to adjust based on content */

  @media (max-width: 768px) {
    table-layout: fixed; /* Fix table layout on smaller screens */
  }
`;
const ChatTableHeader = styled.thead`
  background-color: var(--color-header-bg);
  position: sticky;
  top: 0;
  z-index: 0; /* Increased z-index to stay above other elements */
  border-bottom: 2px solid var(--color-border);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
`;


const TableHeaderCell = styled.th`
  padding: 14px 20px; /* Increased horizontal padding for better spacing */
  text-align: left;
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  color: var(--color-header-text);
  border-bottom: 1px solid var(--color-border);
  position: relative;
  transition: color var(--transition-speed), background-color var(--transition-speed);
  
  &:hover {
    cursor: pointer;
    color: var(--color-header-hover-text);
    background-color: var(--color-header-hover-bg);
  }
  
  .sort-icon {
    margin-left: 8px;
    color: var(--color-sort-icon);
    transition: transform var(--transition-speed), color var(--transition-speed);
    vertical-align: middle;
    font-size: 12px; /* Smaller size for subtlety */
    
    &.active {
      color: var(--color-sort-icon-active);
      transform: rotate(180deg); /* Indicate sort direction */
    }
  }
`;
const ChatTableRow = styled.tr`
  transition: background-color var(--transition-speed);
  background-color: #ffffff;

  &:nth-child(even) {
    background-color: #f9fafb; /* Very light gray */
  }

  &:hover {
    background-color: var(--color-row-hover);
  }
`;

const ChatTableCell = styled.td`
  padding: 16px 20px; /* Balanced padding */
  border-bottom: 1px solid #e5e7eb; /* Light gray border */
  vertical-align: middle;
  text-align: left;
  transition: background-color var(--transition-speed);
  
  /* Ensure text wraps and cells expand */
  word-wrap: break-word;
  white-space: normal;
  
  /* For Action icon centering */
  &.action-cell {
    display: flex; /* Flex container */
    align-items: center; /* Vertically center content */
    justify-content: center; /* Horizontally center content */
    padding: 0; /* Remove padding for precise icon centering */
  }

  /* Optional: Prevent table from exceeding container */
  max-width: 200px; /* Adjust as necessary */
  overflow: hidden;
  text-overflow: ellipsis;
`;


const StatusCell = styled(ChatTableCell)`
  font-weight: bold;
  color: ${props => (props.success ? '#3c763d' : '#a94442')};
  background-color: ${props => (props.success ? '#dff0d8' : '#f2dede')};
  text-align: center;
  border-radius: 4px;
  padding: 8px;
`;


const ExportButton = styled.button`
  padding: 8px 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #45a049;
  }

  svg {
    margin-left: 8px;
  }
`;

const ColumnDropdown = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 10px;
`;


const DropdownButton = styled.button`
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;  /* Rounded corners */
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: ${(props) => (props.$show ? 'block' : 'none')};
  z-index: 10;
  min-width: 150px;
  right: ${(props) => (props.positionX && window.innerWidth - props.positionX < 250 ? '0' : 'auto')};
  left: ${(props) => (props.positionX && window.innerWidth - props.positionX < 250 ? 'auto' : '0')};
  transform: translateX(-60px) translateY(10px);
  
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;


const SearchWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;


const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;  /* Space between items */
  font-size: 14px;      /* Modern font size */
  color: #555;

  input {
    margin-right: 10px;  /* Space between checkbox and label */
    accent-color: #5a67d8;  /* Updated checkbox color to match modern UI theme */
  }
`;

const ActionMenu = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  top: 25px;
  right: 0;

  button {
    background: none;
    border: none;
    padding: 8px 15px;
    text-align: left;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: #f7f7f7;
    }
  }
`;


// Pagination Styled Components
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
`;

const PageButton = styled.button`
  padding: 8px 12px;
  margin: 5px;
  border: none;
  background-color: ${props => (props.$active ? '#5a67d8' : '#fff')};
  color: ${props => (props.$active ? '#fff' : '#5a67d8')};
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #5a67d8;
  transition: background-color 0.2s;
  min-width: 44px;

  &:hover {
    background-color: #434190;
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Ellipsis = styled.span`
  padding: 8px 12px;
  margin: 5px;
  color: #5a67d8;
  min-width: 44px;
  text-align: center;
`;

const FeedbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThumbsUpIcon = styled(FaThumbsUp)`
  cursor: pointer;
  margin-right: 8px;
  color: ${(props) => (props.selected ? 'green' : 'gray')};
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  transition: opacity 0.2s;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

const ThumbsDownIcon = styled(FaThumbsDown)`
  cursor: pointer;
  color: ${(props) => (props.selected ? 'red' : 'gray')};
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  transition: opacity 0.2s;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

const ExpandButton = styled.button`
  background-color: rgb(137, 58, 126);
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 1px 4px;
  border-radius: 2px;
  margin-left: 3px;
  

  &:hover {
    background-color: rgba(137, 58, 126, 0.8);
  }
`;

const ExpandIcon = styled.span`
  cursor: pointer;
  margin-left: 5px;
  color: rgb(137, 58, 126);
  display: inline-flex;
  align-items: center;
  font-size: 16px; /* Adjust font size to match text */
  vertical-align: middle;
  transition: transform 0.2s, opacity 0.2s; /* For hover effect */

  &:hover {
    opacity: 0.8;
    transform: scale(1.1); /* For hover effect */
  }
`;
const ActionIcon = styled(FaExpandArrowsAlt)`
  cursor: pointer;
  color: #999; /* Initially gray */
  transition: color 0.3s, transform 0.3s;
  transform: translateX(15px);


  &:hover {
    color: #3b82f6; /* Turns blue on hover */
    transform: translateX(15px) scale(1.1); /* Slightly larger on hover for better interactivity */
  }
`;


const Loader = styled.div`
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #5a67d8; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 50px auto; /* Center horizontally and add margin-top */

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;



// Pagination Component
const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Determine the page numbers to display (e.g., show 5 pages at a time)
  let startPage = Math.max(currentPage - 2, 1);
  let endPage = Math.min(currentPage + 2, totalPages);

  // Adjust if near the start or end
  if (currentPage <= 3) {
    endPage = Math.min(5, totalPages);
  }
  if (currentPage >= totalPages - 2) {
    startPage = Math.max(totalPages - 4, 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <PaginationContainer>
      <PageButton onClick={() => onPageChange(1)} disabled={currentPage === 1}>
        First
      </PageButton>
      <PageButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
        Prev
      </PageButton>
      {startPage > 1 && <Ellipsis>...</Ellipsis>}
      {pageNumbers.map(number => (
        <PageButton
          key={number}
          onClick={() => onPageChange(number)}
          $active={currentPage === number}
        >
          {number}
        </PageButton>
      ))}
      {endPage < totalPages && <Ellipsis>...</Ellipsis>}
      <PageButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        Next
      </PageButton>
      <PageButton onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>
        Last
      </PageButton>
    </PaginationContainer>
  );
};



// Component Logic
const ChatLogs = () => {
  const { enterpriseId, isPlatformAdmin } = useAuth(); // Destructure enterpriseId from useAuth
  const [agentIds, setAgentIds] = useState([]);
  const [chatLogs, setChatLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [lastVisible, setLastVisible] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showColumnDropdown, setShowColumnDropdown] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    timestamp: true,
    sender_name: true,
    sender_id: false,
    user_message: true,
    bot_response: true,
    feedback: true,
    response_method: false,
    channel: false,
    agent_id: true,
    result: false,
    actions: true,
    agent_name: false
  });
  const [showActionMenu, setShowActionMenu] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [filters, setFilters] = useState({
    channel: "",
    result: "",
    feedback: "",
    timeRange: ""
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'timestamp',
    direction: 'desc'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleToggleExpand = (logId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [logId]: !prevExpandedRows[logId],
    }));
  };


  // Fetch Chat Logs with Essential Server-Side Filters
  const fetchChatLogs = async (loadMore = false) => {
    if (!loadMore) {
      setLoading(true);
    } else {
      setIsFetchingMore(true);
    }
  
    try {
      const constraints = [];
  
      // Essential server-side filter: Fetch only the past 14 days
      const now = new Date();
      let pastDate = new Date();
      pastDate.setDate(now.getDate() - 14);
      constraints.push(where('timestamp', '>=', Timestamp.fromDate(pastDate)));
  
      // Add ordering
      constraints.push(orderBy('timestamp', 'desc'));
  
      // Handle pagination
      if (loadMore && lastVisible) {
        constraints.push(startAfter(lastVisible));
      }
  
      if (isPlatformAdmin) {
        console.log("🚀 ~ fetchChatLogs ~ isPlatformAdmin:", isPlatformAdmin)
        // Platform admin: Fetch all chat_logs with timestamp >= pastDate
        const adminQuery = query(
          collection(db, 'chat_logs'),
          ...constraints,
          limit(100)
        );
        const adminSnapshot = await getDocs(adminQuery);
        const adminChatLogs = adminSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        if (loadMore) {
          setChatLogs(prevLogs => [...prevLogs, ...adminChatLogs]);
        } else {
          setChatLogs(adminChatLogs);
        }
  
        setLastVisible(adminSnapshot.docs[adminSnapshot.docs.length - 1] || null);
      } else {
        // Non-admin: Fetch chat_logs where agent_id is in agentIds (batched if necessary)
        if (agentIds.length === 0) {
          toast.info('No agents found for your enterprise.');
          setChatLogs([]);
          setLastVisible(null);
          setLoading(false);
          return;
        }
  
        const batchSize = 10;
        const batches = [];
        for (let i = 0; i < agentIds.length; i += batchSize) {
          const batchAgentIds = agentIds.slice(i, i + batchSize);
          const batchQuery = query(
            collection(db, 'chat_logs'),
            where('agent_id', 'in', batchAgentIds),
            ...constraints,
            limit(100)
          );
          batches.push(batchQuery);
        }
  
        // Execute all batch queries and combine results
        const promises = batches.map(batch => getDocs(batch));
        const snapshots = await Promise.all(promises);
        const allChatLogs = snapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
  
        if (loadMore) {
          setChatLogs(prevLogs => [...prevLogs, ...allChatLogs]);
        } else {
          setChatLogs(allChatLogs);
        }
  
        // Update the last visible document for pagination
        if (snapshots.length > 0 && snapshots[snapshots.length - 1].docs.length > 0) {
          setLastVisible(snapshots[snapshots.length - 1].docs[snapshots[snapshots.length - 1].docs.length - 1]);
        } else {
          setLastVisible(null);
        }
      }
    } catch (error) {
      console.error('Error fetching chat logs:', error);
      toast.error(`Failed to fetch chat logs: ${error.message}`);
    }
  
    if (!loadMore) {
      setLoading(false);
    } else {
      setIsFetchingMore(false);
    }
  };
  
    useEffect(() => {
    const fetchAgentIds = async () => {
      try {
        const agentsRef = collection(db, 'agents');
        const q = query(agentsRef, where('enterprise_id', '==', enterpriseId));
        const snapshot = await getDocs(q);
        const ids = snapshot.docs.map(doc => doc.id);
        setAgentIds(ids);
      } catch (error) {
        console.error('Error fetching agents:', error);
        toast.error('Failed to fetch agents.');
      }
    };
  
    if (enterpriseId) {
      fetchAgentIds();
    }
  }, [enterpriseId]);

  useEffect(() => {
    if (isPlatformAdmin || agentIds.length > 0) {
      fetchChatLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentIds]);
  
  // Apply Client-Side Filters, Search, and Sorting
  useEffect(() => {
    let filtered = chatLogs;

    // Apply client-side filters
    if (filters.channel) {
      filtered = filtered.filter(log => log.channel === filters.channel);
    }
    if (filters.result) {
      filtered = filtered.filter(log => log.result === filters.result);
    }
    if (filters.feedback) {
      if (filters.feedback === 'Unfiltered') {
        filtered = filtered.filter(
          (log) => log.feedback !== 'Helpful' && log.feedback !== 'Not Helpful'
        );
      } else {
        filtered = filtered.filter((log) => log.feedback === filters.feedback);
      }
    }
    if (filters.timeRange) {
      const now = new Date();
      let pastDate = new Date();
      if (filters.timeRange === 'Past 24 Hours') {
        pastDate = new Date(now.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours in milliseconds
      } else if (filters.timeRange === 'Last 7 Days') {
        pastDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      } else if (filters.timeRange === 'Last 14 Days') {
        pastDate = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
      } else if (filters.timeRange === 'Last 30 Days') {
        pastDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      }
      filtered = filtered.filter((log) => log.timestamp.toDate() >= pastDate);
    }
    
    
    // Apply fuzzy search
    if (searchTerm) {
      const fuse = new Fuse(filtered, {
        keys: [
          'user_message',
          'sender_id',
          'sender_name', // Included sender_name
          'bot_response',
          'feedback',
          'remarks',
          'agent_id',
          'channel',
          'agent_name',
          'result',
          'channel',
          'session_id',
          // Add any other fields you wish to include
        ],
        threshold: 0.3,
      });
      const results = fuse.search(searchTerm).map(result => result.item);
      filtered = results;
    }

    // Apply sorting
    const sorted = filtered.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle timestamp and other field types
      if (sortConfig.key === 'timestamp') {
        aValue = aValue ? aValue.toDate() : new Date(0);
        bValue = bValue ? bValue.toDate() : new Date(0);
      } else {
        aValue = aValue || '';
        bValue = bValue || '';
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredLogs(sorted);
    setCurrentPage(1); // Reset to first page when filters change
  }, [chatLogs, filters, searchTerm, sortConfig]);

    // Calculate pagination indices
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentLogs = filteredLogs.slice(indexOfFirstItem, indexOfLastItem);

  const handleColumnToggle = (column) => {
    setVisibleColumns((prevColumns) => ({
      ...prevColumns,
      [column]: !prevColumns[column],
    }));
  };

  const handleFilterChange = (e, filterType) => {
    const value = e.target.value;
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const handleRefresh = () => {
    setFilters({
      channel: "",
      result: "",
      feedback: "",
      timeRange: ""
    });
    setSearchTerm("");
    fetchChatLogs();
  };

  // const handleThreeDotClick = (logId) => {
  //   setShowActionMenu(showActionMenu === logId ? null : logId);
  // };

  const handleThreeDotClick = (log) => {
    setSelectedLog(log);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLog(null);
  };
  
  

  const handleDelete = async (logId) => {
    try {
      await deleteDoc(doc(db, 'chat_logs', logId));
      setChatLogs(chatLogs.filter(log => log.id !== logId));
      toast.success('Chat log deleted successfully!');
    } catch (error) {
      console.error('Error deleting chat log:', error);
      toast.error('Failed to delete chat log. Please try again.');
    }
  };

  // Sorting Functionality
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Fetch more logs for pagination
  const fetchMoreLogs = async () => {
    if (!lastVisible) return;
    await fetchChatLogs(true);
  };

  const handleFeedbackClick = async (logId, feedbackValue) => {
    try {
      const logRef = doc(db, 'chat_logs', logId);
      await updateDoc(logRef, {
        feedback: feedbackValue,
        last_updated: new Date(),
      });
      setChatLogs(chatLogs.map(log => (log.id === logId ? { ...log, feedback: feedbackValue } : log)));
      toast.success('Feedback updated successfully!');
    } catch (error) {
      console.error('Error updating feedback:', error);
      toast.error('Failed to update feedback. Please try again.');
    }
  };
  

  return (
    <>
      <DashboardHeader />
      <BoxWrapper>
        <BoxContainer>
        <Header>
          <Title>
            AI Agent Chat Logs
            <RefreshButton onClick={handleRefresh}>
              <FiRefreshCw size={24} />
            </RefreshButton>
          </Title>
          <ActionButtonGroup>
            <ExportButton>
              Export Logs
              <HiOutlineDownload size={20} />
            </ExportButton>
            <ColumnDropdown>
              <DropdownButton onClick={() => setShowColumnDropdown(!showColumnDropdown)}>
                Columns
              </DropdownButton>
              <DropdownContent $show={showColumnDropdown}>
                {Object.keys(visibleColumns).map((column) => (
                  <CheckboxLabel key={column}>
                    <input
                      type="checkbox"
                      checked={visibleColumns[column]}
                      onChange={() => handleColumnToggle(column)}
                    />
                    {column.replace(/_/g, ' ')}
                  </CheckboxLabel>
                ))}
              </DropdownContent>
            </ColumnDropdown>
          </ActionButtonGroup>
        </Header>
        <SearchWrapper>
        <SearchAndFilterContainer>
          <SearchBar>
            <FiSearch />
            <SearchInput
              type="text"
              placeholder="Search by any field..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBar>
          <FilterSelectGroup>
            <FilterSelect value={filters.channel} onChange={(e) => handleFilterChange(e, 'channel')}>
              <option value="">All Channels</option>
              <option value="Messenger">Messenger</option>
              <option value="Website">Website</option>
              <option value="WhatsApp">WhatsApp</option>
              <option value="Viber">Viber</option>
              <option value="Dashboard">Dashboard</option>
            </FilterSelect>
            <FilterSelect value={filters.result} onChange={(e) => handleFilterChange(e, 'result')}>
              <option value="">All Results</option>
              <option value="Success">Success</option>
              <option value="Error">Error</option>
            </FilterSelect>
            <FilterSelect value={filters.feedback} onChange={(e) => handleFilterChange(e, 'feedback')}>
                        <option value="">All Feedback</option>
              <option value="Helpful">Helpful</option>
              <option value="Not Helpful">Not Helpful</option>
              <option value="Unfiltered">Unfiltered</option>
            </FilterSelect>
            <FilterSelect value={filters.timeRange} onChange={(e) => handleFilterChange(e, 'timeRange')}>
              <option value="">All Time</option>
              <option value="Past 24 Hours">Past 24 Hours</option>
              <option value="Last 7 Days">Last 7 Days</option>
              <option value="Last 14 Days">Last 14 Days</option>
              <option value="Last 30 Days">Last 30 Days</option>
            </FilterSelect>
          </FilterSelectGroup>
        </SearchAndFilterContainer>
        </SearchWrapper>

        {/* Chat Logs Table */}
        {loading ? (
          <Loader />
        ) : (
          <>
            <TableWrapper>
            <ChatTable>
              <ChatTableHeader>
                <ChatTableRow>
                  {visibleColumns.timestamp && (
                    <TableHeaderCell onClick={() => handleSort('timestamp')}>
                      Timestamp   
                      {sortConfig.key === 'timestamp' && (
    sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />
  )}
                    </TableHeaderCell>
                  )}
                  {visibleColumns.sender_id && (
                    <TableHeaderCell onClick={() => handleSort('sender_id')}>
                      Sender ID {sortConfig.key === 'sender_id' && (
    sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />
  )}
                    </TableHeaderCell>
                  )}
                                  {visibleColumns.sender_name && (
                  <TableHeaderCell onClick={() => handleSort('sender_name')}>
                    Sender Name {sortConfig.key === 'sender_name' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                  </TableHeaderCell>
                )}
                  {visibleColumns.agent_id && (
                    <TableHeaderCell onClick={() => handleSort('agent_id')}>
                      Agent ID {sortConfig.key === 'agent_id' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                    </TableHeaderCell>
                  )}
                  {visibleColumns.agent_name && (
                    <TableHeaderCell onClick={() => handleSort('agent_name')}>
                      Agent Name {sortConfig.key === 'agent_name' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                    </TableHeaderCell>
                  )}

                  {visibleColumns.user_message && (
                    <TableHeaderCell onClick={() => handleSort('user_message')}>
                      User Message {sortConfig.key === 'user_message' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                    </TableHeaderCell>
                  )}
                  {visibleColumns.bot_response && (
                    <TableHeaderCell onClick={() => handleSort('bot_response')}>
                      Bot Response{' '}
                      {sortConfig.key === 'bot_response'
                        ? sortConfig.direction === 'asc'
                          ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />
                        : ''}
                    </TableHeaderCell>
                  )}
                  {visibleColumns.feedback && (
                    <TableHeaderCell onClick={() => handleSort('feedback')}>
                      Feedback {sortConfig.key === 'feedback' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                    </TableHeaderCell>
                  )}
                  {visibleColumns.response_method && (
                    <TableHeaderCell onClick={() => handleSort('response_method')}>
                      Response Method {sortConfig.key === 'response_method' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                    </TableHeaderCell>
                  )}
                  {visibleColumns.channel && (
                    <TableHeaderCell onClick={() => handleSort('channel')}>
                      Channel {sortConfig.key === 'channel' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                    </TableHeaderCell>
                  )}

                  {visibleColumns.result && (
                    <TableHeaderCell onClick={() => handleSort('result')}>
                      Result {sortConfig.key === 'result' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="sort-icon active" /> : <AiOutlineArrowDown className="sort-icon active" />) : ''}
                    </TableHeaderCell>
                  )}
                  {visibleColumns.actions && <TableHeaderCell>Actions</TableHeaderCell>}
                </ChatTableRow>
              </ChatTableHeader>
              <tbody>
                {currentLogs.map(log => (
                  <ChatTableRow key={log.id}>
                    {visibleColumns.timestamp && <ChatTableCell>{new Date(log.timestamp?.toDate()).toLocaleString()}</ChatTableCell>}
                    {visibleColumns.sender_id && <ChatTableCell>{log.sender_id}</ChatTableCell>}
                    {visibleColumns.sender_name && <ChatTableCell>{log.sender_name || '-'}</ChatTableCell>}
                    {visibleColumns.agent_id && <ChatTableCell>{log.agent_id ?? '-'}</ChatTableCell>}
                    {visibleColumns.agent_name && <ChatTableCell>{log.agent_name ?? '-'}</ChatTableCell>}
                    {visibleColumns.user_message && <ChatTableCell>{log.user_message}</ChatTableCell>}
                    {visibleColumns.bot_response && (
                      <ChatTableCell>
  {expandedRows[log.id] ? (
    <>
      {log.bot_response || '-'}
      <ExpandIcon onClick={() => handleToggleExpand(log.id)}>
        <AiOutlineMinusCircle  data-tooltip-id="minus-circle"
 data-tooltip-content="Show Less"
        />
      </ExpandIcon>
    </>
  ) : (
    <>
      {log.bot_response && log.bot_response.length > 100 ? (
        <>
          {log.bot_response.substring(0, 100)}...
          <ExpandIcon onClick={() => handleToggleExpand(log.id)}>
            <AiOutlinePlusCircle  data-tooltip-id="minus-circle" data-tooltip-content="Show More"
            />
          </ExpandIcon>
          <ReactTooltip id="minus-circle" />
        </>
      ) : (
        log.bot_response || '-'
      )}
    </>
  )}
</ChatTableCell>

                    )}
                    {visibleColumns.feedback && (
  <ChatTableCell>
    <FeedbackContainer>
    <ThumbsUpIcon
  selected={log.feedback === 'Helpful'}
  onClick={() => handleFeedbackClick(log.id, 'Helpful')}
  data-tooltip-id="feedback-tooltip"
  data-tooltip-content="Mark as Helpful"
/>
<ThumbsDownIcon
  selected={log.feedback === 'Not Helpful'}
  onClick={() => handleFeedbackClick(log.id, 'Not Helpful')}
  data-tooltip-id="feedback-tooltip"
  data-tooltip-content="Mark as Not Helpful"
/>

<ReactTooltip id="feedback-tooltip" />

    </FeedbackContainer>
  </ChatTableCell>
)}

                    {visibleColumns.response_method && <ChatTableCell>{log.response_method ?? '-'}</ChatTableCell>}
                    {visibleColumns.channel && <ChatTableCell>{log.channel ?? '-'}</ChatTableCell>}
                    {visibleColumns.result && (
                      <StatusCell success={log.result === 'Success'}>
                        {log.result}
                      </StatusCell>
                    )}
                    {visibleColumns.actions && (
                      <ChatTableCell>
                        <div style={{ position: 'relative' }}>
                        <ActionIcon size={16} onClick={() => handleThreeDotClick(log)} />
                        {showActionMenu === log.id && (
                            <ActionMenu>
                              <button onClick={() => handleDelete(log.id)}>Delete</button>
                            </ActionMenu>
                          )}
                        </div>
                      </ChatTableCell>
                    )}
                  </ChatTableRow>
                ))}
              </tbody>
            </ChatTable>
            </TableWrapper>
            <Pagination
              totalItems={filteredLogs.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
            {/* {lastVisible && (
              <LoadMoreButton onClick={fetchMoreLogs} disabled={isFetchingMore}>
                {isFetchingMore ? 'Loading...' : 'Load More'}
              </LoadMoreButton>
            )} */}
          </>
        )}
          <LogDetailsModal log={selectedLog} isOpen={isModalOpen} onClose={closeModal} />
      </BoxContainer>
      </BoxWrapper>
    </>
  );
};

export default ChatLogs;
