// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

// Import ToastContainer and CSS
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Public Pages
import LandingPage from './components/Public/LandingPage';
import PrivacyPolicy from './components/Public/PrivacyPolicy';
import AboutUs from './components/Public/AboutUs';
import Newsletter from './components/Public/Newsletter';

// Private Pages
import Dashboard from './components/Dashboard';
import Agents from './components/Agents';
import Training from './components/Training';
import Settings from './components/Settings';
import UserAccess from './components/UserAccess';
import Orders from './components/Orders';
import Reports from './components/Reports';
import Enterprises from './components/Enterprises';

// Auth Pages
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import NotFoundPage from './components/Public/NotFoundPage';
import Unauthorized from './components/Unauthorized';

// Route Protection Component
import PrivateRoute from './components/PrivateRoute';
import ChatLogs from './components/ChatLog';
import ProtectedRoute from './components/ProtectedRoute';
import PublicLayout from './components/Public/PublicLayout';
import PrivateLayout from './components/Private/PrivateLayout';
import { ThemeProviderContext } from './contexts/ThemeContext';


function Layout() {

  return (
    <>
        <Routes>
          {/* Public Routes */}
          <Route element={<PublicLayout/>}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          
          
          {/* Protected Routes */}
          <Route element={<PrivateLayout/>}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute requiredRoles={['admin', 'team', 'support']}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/agents"
            element={
              <PrivateRoute requiredRoles={['admin', 'team']}>
                <Agents />
              </PrivateRoute>
            }
          />
          <Route
            path="/training"
            element={
              <PrivateRoute requiredRoles={['admin', 'support']}>
                <Training />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat-logs"
            element={
              <PrivateRoute requiredRoles={['admin', 'support']}>
                <ChatLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute requiredRoles={['admin']}>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <PrivateRoute requiredRoles={['admin']}>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-access"
            element={
              <ProtectedRoute>
                <UserAccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/enterprises"
            element={
              <ProtectedRoute>
                <Enterprises />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute requiredRoles={['admin', 'team']}>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route path="/unauthorized" element={<Unauthorized />} />
          </Route>
          
        </Routes>
      
    </>
  );
}

function App() {
    
  return (
    <AuthProvider>
      <ThemeProviderContext>
      <Router>
        <Layout />

      {/* Include ToastContainer here */}
      <ToastContainer 
        position="bottom-right"
        autoClose={7000} // Automatically close after 5 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" // You can choose different themes: "light", "dark", "colored"
      />
      </Router>
      </ThemeProviderContext>
    </AuthProvider>
  );
}

export default App;
