import React, { useState } from 'react';
import styled from 'styled-components';
import { collection, addDoc } from 'firebase/firestore'; // Firestore imports
import { db } from '../../firebase'; // Import Firestore instance
import AIDemoVideo from '../../assets/AI-Demo-1.mov';
// Announcement Bar
const AnnouncementBar = styled.div`
  background-color: #6a11cb;
  color: white;
  text-align: center;
  padding: 8px;
  font-size: 0.85rem;
`;

// Landing Section
const LandingSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  background: white;
  color: #333;
  min-height: 100vh;
`;

const TextSection = styled.div`
  max-width: 700px;
  text-align: center;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  margin: 0;
  margin-bottom: 15px;
  color: #333;
  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  margin-bottom: 30px;
  color: #666;
  line-height: 1.4;
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 25px;
  }
`;

const WaitlistContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const EmailInput = styled.input`
  padding: 14px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 70%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const WaitlistButton = styled.button`
  padding: 14px;
  font-size: 1.2rem;
  background-color: #6a11cb; /* Purple theme color */
  color: white;
  border: none;
  border-radius: 8px;
  width: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #5a0da7;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const StatusMessage = styled.p`
  margin-top: 10px;
  color: ${({ success }) => (success ? 'green' : 'red')};
  font-weight: bold;
`;

const VideoSection = styled.div`
  width: 100%;
  margin-top: 40px;

  video {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    video {
      width: 100%;
      object-fit: contain;
    }
  }
`;

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to handle email submission
  const handleJoinWaitlist = async () => {
    if (!validateEmail(email)) {
      setStatusMessage('Please enter a valid email.');
      setSuccess(false);
      return;
    }

    if (isSubmitting) {
      return; // Prevent multiple submissions within 1 second
    }

    setIsSubmitting(true);

    try {
      // Add email to Firestore collection
      await addDoc(collection(db, 'waitlist'), { email });
      setStatusMessage('Thank you for joining the waitlist!');
      setSuccess(true);
      setEmail(''); // Clear the email input
    } catch (error) {
      setStatusMessage('Error occurred. Please try again.');
      setSuccess(false);
      console.error('Error adding document: ', error);
    }

    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000); // Allow submission after 1 second
  };

  return (
    <>
      <AnnouncementBar>
        Discover AndyAI, your AI-powered enterprise companion! Join the waitlist now!
      </AnnouncementBar>

      <LandingSection>
        <TextSection>
          <Title>Meet AndyAI – Your Enterprise AI Companion</Title>
          <Description>
            Built by engineers with 8+ years of machine learning experience. Automate workflows, make data-driven decisions, and transform your enterprise with AI. Join the waitlist to experience the future of AI for enterprises.
          </Description>
          <WaitlistContainer>
            <EmailInput
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email to join the waitlist"
            />
            <WaitlistButton onClick={handleJoinWaitlist} disabled={isSubmitting}>
              Join Waitlist
            </WaitlistButton>
          </WaitlistContainer>
          {statusMessage && <StatusMessage success={success}>{statusMessage}</StatusMessage>}
        </TextSection>

        <VideoSection>
          <video autoPlay muted loop playsInline>
            <source src={AIDemoVideo} type="video/webm" />
            <source src={AIDemoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </VideoSection>
      </LandingSection>
      </>
  );
};

export default LandingPage;
