// Agents.js

import { FaFacebookMessenger, FaToggleOn, FaToggleOff, FaClone, FaTrash, FaEdit } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DashboardHeader from './DashboardHeader';
import AgentForm from './toolbox/AgentForm'; // Import the AgentForm component
import Tabs from './toolbox/Tabs'; // Import the Tabs component
import MetaTest from './toolbox/MetaTest'; // Import the MetaTest component
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  addDoc,
  setDoc,
  getDoc,
  Timestamp,
  query,
  where,
  runTransaction,
} from 'firebase/firestore';
import { toast } from 'react-toastify'; // Importing react-toastify
import { BoxWrapper, BoxContainer, Loader } from './toolbox/Reusables';
import { getAgents, getEnterprises, updateAgentEnterpriseId } from '../utils/enterpriseService'; // Updated import
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import WebhookEditForm from './toolbox/WebhookEditForm';

// Styled Components for Agents.js
const AgentsContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`;

const AgentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;

  /* Responsive Design: Adjust grid columns on smaller screens */
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
`;

const CardStatus = styled.p`
  margin: 5px 0;
  font-size: 1rem;
`;

const CardIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  svg {
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #ddd;
    }
  }
`;

const AgentCard = styled.div`
  background: #5a67d8;
  border-radius: 12px;
  padding: 20px;
  color: white;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  /* Responsive Design: Adjust padding on smaller screens */
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const AddAgentCard = styled.div`
  background: none;
  border: 2px dashed #cbd5e0;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #718096;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #edf2f7;
  }

  /* Responsive Design: Adjust padding on smaller screens */
  @media (max-width: 480px) {
    padding: 15px;
    font-size: 1rem;
  }
`;

// Add this new styled component
const CardCreatedAt = styled.p`
  margin: 5px 0;
  font-size: 0.9rem;
  color: #cbd5e0; /* Light gray color for less emphasis */
`;


// Schedule Schema with Default Values
const defaultSchedule = {
  monday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  tuesday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  wednesday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  thursday: { start: '', end: '', is24Hours: false, closed: true, isScheduled: false },
  friday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  saturday: { start: '', end: '', is24Hours: false, closed: true, isScheduled: false },
  sunday: { start: '', end: '', is24Hours: false, closed: true, isScheduled: false },
};

// Default Agent Data
const defaultAgentData = {
  active: true,
  agent_instruction:
    "You are Lia, a courteous customer service agent for a shop who will reply in taglish (60% english, 40% tagalog). You will not respond to anything beyond your scope as a customer service agent - meaning irrelevant topics outside your work.",
  ai_model: "default",
  creativity_level: 1,
  integrations: [], // This will be set from the form
  internal_feedback: "",
  knowledge_database: [],
  published: false,
  response_length: {
    max: 250,
  },
  store_information: {
    offerings: "We're currently on beta, about to sell you tons of amazing cookies!",
    // Add other keys with default values or empty strings if needed
  },
  // We'll set 'schedule' and 'name' separately
};

// Component Logic
const Agents = () => {
  const { currentUser, userRoles, isPlatformAdmin, enterpriseId } = useAuth(); // Destructure useAuth
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isAddAgent,setIsAddAgent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null); // Track selected agent
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAgentName, setNewAgentName] = useState('');
  const [newIntegrations, setNewIntegrations] = useState({ Messenger: false, Website: false });
  const [schedule, setSchedule] = useState(defaultSchedule);
  const [errors, setErrors] = useState({}); // To track validation errors
  const [enterprises, setEnterprises] = useState([]); // State to hold enterprises for platform_admin
  const [userEnterpriseName, setUserEnterpriseName] = useState('');
  const [isEditWebhook, setIsEditWebhook] = useState(false); // New state
  const [selectedWebhookAgent, setSelectedWebhookAgent] = useState(null); // New state
  const [showWebhookModal, setShowWebhookModal] = useState(false); // New state
  const [verifyToken, setVerifyToken] = useState('');
  const [pageAccessToken, setPageAccessToken] = useState('');
  const [pageId, setPageId] = useState('');

  const handleEditWebhookClick = (agent) => {
    setSelectedWebhookAgent(agent);
    setShowWebhookModal(true);
  };

  // Determine the current user's enterprise_id
  const userEnterpriseId = enterpriseId || null;
  const [newEnterpriseId, setNewEnterpriseId] = useState(
    isPlatformAdmin ? (selectedAgent?.enterprise_id || '') : enterpriseId
  );
  
  // Fetch agents from Firestore based on user role and enterprise_id
// Inside the fetchAgents function

const fetchAgents = async () => {
  setLoading(true);
  try {
    const agentsResponse = await getAgents(isPlatformAdmin ? null : enterpriseId);
    if (agentsResponse.success) {
      // Sort agents: active agents first
      const sortedAgents = agentsResponse.agents.sort((a, b) => b.active - a.active);
      setAgents(sortedAgents);
    } else {
      console.error(agentsResponse.error);
      toast.error('Failed to fetch agents.');
    }
  } catch (error) {
    console.error('Error fetching agents:', error);
    toast.error('An unexpected error occurred while fetching agents.');
  } finally {
    setLoading(false);
  }
};

  // Fetch enterprises if the user is a platform_admin
  const fetchEnterprisesData = async () => {
    if (isPlatformAdmin) {
      try {
        const enterprisesResponse = await getEnterprises();
        if (enterprisesResponse.success) {
          setEnterprises(enterprisesResponse.enterprises);
        } else {
          console.error(enterprisesResponse.error);
          toast.error('Failed to fetch enterprises.');
        }
      } catch (error) {
        console.error('Error fetching enterprises:', error);
        toast.error('An unexpected error occurred while fetching enterprises.');
      }
    }
  };


  // Fetch enterprise name for non-admin users
  const fetchUserEnterpriseName = async () => {
    if (!isPlatformAdmin && userEnterpriseId) {
      try {
        const enterpriseDoc = await getDoc(doc(db, 'enterprises', userEnterpriseId));
        if (enterpriseDoc.exists()) {
          console.log("🚀 ~ fetchUserEnterpriseName ~ enterpriseDoc:", enterpriseDoc)
          const enterpriseData = enterpriseDoc.data();
          console.log("🚀 ~ fetchUserEnterpriseName ~ enterpriseData:", enterpriseData)
          setUserEnterpriseName(enterpriseData.name);
          console.log("🚀 ~ fetchUserEnterpriseName ~ enterpriseData.name:", enterpriseData.name)
        } else {
          console.error('Enterprise not found');
          setUserEnterpriseName('Unknown Enterprise');
        }
      } catch (error) {
        console.error('Error fetching user enterprise name:', error);
        setUserEnterpriseName('Unknown Enterprise');
      }
    }
  };

  
  useEffect(() => {
    fetchAgents();
    fetchEnterprisesData();
    fetchUserEnterpriseName(); // Fetch enterprise name for non-admin users
    // Optionally, set up a listener for real-time updates if needed
  }, [currentUser, userRoles, isPlatformAdmin, enterpriseId, userEnterpriseId]); // Added userEnterpriseId to dependencies



  // Handle Add Agent Click
  // Handle Add Agent Click
  const handleAddAgentClick = () => {
    setSelectedAgent(null); // Ensure no agent is selected for adding
    setNewAgentName('');
    setNewIntegrations({ Messenger: false, Website: false });
    setSchedule(defaultSchedule);
    setErrors({});
    setIsAddAgent(true);
    setShowModal(true);
  };

  // Handle Edit Click
  const handleEditClick = (agent) => {
    setSelectedAgent(agent);
    setNewAgentName(agent.name);

    // Ensure integrations is always an array
    const integrationsArray = Array.isArray(agent.integrations) ? agent.integrations : [];

    setNewIntegrations({
      Messenger: integrationsArray.includes('Messenger'),
      Website: integrationsArray.includes('Website'),
    });
    setSchedule(agent.schedule || defaultSchedule);
    setErrors({});
    setShowModal(true);
  };

  // Toggle agent status in Firestore
// Agents.js

// Agents.js

// Replace the existing toggleAgentStatus function with the following

// Replace the existing toggleAgentStatus function with the following

const toggleAgentStatus = async (agent) => {
  const updatedStatus = !agent.active;

  if (updatedStatus) { // Attempting to set to Active
    try {
      await runTransaction(db, async (transaction) => {
        const agentRef = doc(db, 'agents', agent.id);
        const agentSnapshot = await transaction.get(agentRef);

        if (!agentSnapshot.exists()) {
          throw new Error('Agent does not exist.');
        }

        const currentAgentData = agentSnapshot.data();
        const currentPageId = currentAgentData.page_id;

        const activeAgentRef = doc(db, 'activeAgents', currentPageId);
        const activeAgentSnapshot = await transaction.get(activeAgentRef);

        if (activeAgentSnapshot.exists()) {
          const currentActiveAgentId = activeAgentSnapshot.data().agent_id;
          if (currentActiveAgentId !== agent.id) {
            // Conflict exists, prevent activation
            const conflictingAgentRef = doc(db, 'agents', currentActiveAgentId);
            const conflictingAgentSnapshot = await transaction.get(conflictingAgentRef);
            const conflictingAgentName = conflictingAgentSnapshot.exists() ? conflictingAgentSnapshot.data().name : 'Unknown Agent';

            throw new Error(`Cannot activate "${currentAgentData.name}" for Page ID "${currentPageId}" because it is already assigned to "${conflictingAgentName}".`);
          }
        }

        // No conflict, proceed to activate
        transaction.set(activeAgentRef, { agent_id: agent.id, last_updated: Timestamp.now() }, { merge: true });
        transaction.update(agentRef, { active: true, last_updated: Timestamp.now() });
      });

      // Refresh agents list and notify user
      fetchAgents();
      toast.success(`Agent "${agent.name}" activated successfully!`);
    } catch (error) {
      console.error('Error toggling agent status:', error);
      toast.error(error.message || 'Failed to activate agent. Please try again.');
    }
  } else { // Deactivating the agent
    try {
      await runTransaction(db, async (transaction) => {
        const agentRef = doc(db, 'agents', agent.id);
        const agentSnapshot = await transaction.get(agentRef);

        if (!agentSnapshot.exists()) {
          throw new Error('Agent does not exist.');
        }

        const currentAgentData = agentSnapshot.data();
        const currentPageId = currentAgentData.page_id;

        // Ensure currentPageId is defined before trying to create activeAgentRef
        if (currentPageId) {
          const activeAgentRef = doc(db, 'activeAgents', currentPageId);
          const activeAgentSnapshot = await transaction.get(activeAgentRef);

          // Check if activeAgentRef exists before attempting to delete
          if (activeAgentSnapshot.exists()) {
            transaction.delete(activeAgentRef);
          }
        }

        transaction.update(agentRef, { active: false, last_updated: Timestamp.now() });
      });

      // Refresh agents list and notify user
      fetchAgents();
      toast.success(`Agent "${agent.name}" deactivated successfully!`);
    } catch (error) {
      console.error('Error toggling agent status:', error);
      toast.error('Failed to deactivate agent. Please try again.');
    }
  }
};

  // Add new agent to Firestore with agent name as ID
// Agents.js


// Inside the Agents component

// Agents.js

// Agents.js

// Replace the existing addAgent function with the following

// Replace the existing addAgent function with the following

const addAgent = async () => {
  const integrations = Object.keys(newIntegrations).filter(key => newIntegrations[key]);
  const agentNameTrimmed = newAgentName.trim();
  const agentRef = doc(db, 'agents', agentNameTrimmed);
  let conflictingAgentName = '';

  try {
    await runTransaction(db, async (transaction) => {
      const agentSnapshot = await transaction.get(agentRef);
      if (agentSnapshot.exists()) {
        throw new Error('An agent with this name already exists. Please choose a different name.');
      }

      // Reference to the activeAgents document for this page_id
      const activeAgentRef = doc(db, 'activeAgents', pageId);
      const activeAgentSnapshot = await transaction.get(activeAgentRef);

      let activeStatus = false;

      if (activeAgentSnapshot.exists()) {
        const currentActiveAgentId = activeAgentSnapshot.data().agent_id;
        if (currentActiveAgentId) {
          // Fetch the current active agent's data
          const currentActiveAgentRef = doc(db, 'agents', currentActiveAgentId);
          const currentActiveAgentSnapshot = await transaction.get(currentActiveAgentRef);

          if (currentActiveAgentSnapshot.exists()) {
            activeStatus = false; // Set new agent to inactive
            conflictingAgentName = currentActiveAgentSnapshot.data().name;
          }
        }
      }

      // Prepare new agent data
      const newAgentData = {
        ...defaultAgentData,
        name: agentNameTrimmed,
        integrations,
        schedule,
        verify_token: verifyToken,
        page_access_token: pageAccessToken,
        page_id: pageId,
        active: activeStatus,
        created_at: Timestamp.now(),
        last_updated: Timestamp.now(),
        enterprise_id: isPlatformAdmin ? newEnterpriseId || enterpriseId : enterpriseId,
      };

      // Add the new agent within the transaction
      transaction.set(agentRef, newAgentData);

      if (activeStatus) {
        // Set the new agent as active in activeAgents collection
        transaction.set(activeAgentRef, { agent_id: agentRef.id, last_updated: Timestamp.now() }, { merge: true });
      }
    });

    // Refresh agents list and close modal
    fetchAgents();
    handleCloseModal();

    // Notify user based on conflict
    if (conflictingAgentName) {
      toast.error(
        `Another agent "${conflictingAgentName}" is already active for Page ID "${pageId}". The new agent has been saved in Maintenance Mode.`
      );
    } else {
      toast.success('Agent added successfully!');
    }
  } catch (error) {
    console.error('Error adding agent:', error);
    toast.error(error.message || 'Failed to add agent. Please try again.');
  }
};


// Update agent in Firestore
const updateAgent = async (agentId) => {
  const integrations = Object.keys(newIntegrations).filter(key => newIntegrations[key]);
  try {
    // Fetch the current agent data
    const agentRef = doc(db, 'agents', agentId);
    const agentSnapshot = await getDoc(agentRef);

    if (!agentSnapshot.exists()) {
      throw new Error('Agent does not exist.');
    }

    const currentAgentData = agentSnapshot.data();
    const currentPageId = currentAgentData.page_id;
    const newPageIdTrimmed = pageId.trim();

    // Check if the page_id is being changed
    const isPageIdChanging = currentPageId !== newPageIdTrimmed;

    await runTransaction(db, async (transaction) => {
      if (isPageIdChanging) {
        // Check if the new page_id is already occupied
        const newActiveAgentRef = doc(db, 'activeAgents', newPageIdTrimmed);
        const newActiveAgentSnapshot = await transaction.get(newActiveAgentRef);

        if (newActiveAgentSnapshot.exists()) {
          const conflictingAgentId = newActiveAgentSnapshot.data().agent_id;
          if (conflictingAgentId !== agentId) {
            // Conflict exists, prevent page_id change
            const conflictingAgentRef = doc(db, 'agents', conflictingAgentId);
            const conflictingAgentSnapshot = await transaction.get(conflictingAgentRef);
            const conflictingAgentName = conflictingAgentSnapshot.exists() ? conflictingAgentSnapshot.data().name : 'Unknown Agent';

            throw new Error(`Cannot assign Page ID "${newPageIdTrimmed}" to "${currentAgentData.name}" because it is already assigned to "${conflictingAgentName}".`);
          }
        }

        // If the agent is active, update the activeAgents collection
        if (currentAgentData.active) {
          // Remove from old page_id's activeAgents
          const oldActiveAgentRef = doc(db, 'activeAgents', currentPageId);
          transaction.delete(oldActiveAgentRef);

          // Assign to new page_id's activeAgents
          transaction.set(newActiveAgentRef, { agent_id: agentId, last_updated: Timestamp.now() }, { merge: true });
        }
      } else {
        // If page_id is not changing, ensure that the agent's active status aligns with activeAgents
        if (currentAgentData.active) {
          const activeAgentRef = doc(db, 'activeAgents', currentPageId);
          transaction.set(activeAgentRef, { agent_id: agentId, last_updated: Timestamp.now() }, { merge: true });
        }
      }

      // Update the agent's details
      transaction.update(agentRef, {
        name: newAgentName.trim(),
        integrations,
        schedule,
        page_id: newPageIdTrimmed,
        last_updated: Timestamp.now(),
        enterprise_id: isPlatformAdmin ? newEnterpriseId || currentAgentData.enterprise_id : currentAgentData.enterprise_id,
      });
    });

    // Refresh agents list and close modal
    fetchAgents();
    handleCloseModal();
    toast.success('Agent updated successfully!');
  } catch (error) {
    console.error('Error updating agent:', error);
    toast.error(error.message || 'Failed to update agent. Please try again.');
  }
};

// Handle Duplicate Click
const handleDuplicateClick = (agent) => {
  setSelectedAgent(agent);
  setNewAgentName('Copy of ' + agent.name);
  setNewIntegrations({
    Messenger: agent.integrations.includes('Messenger'),
    Website: agent.integrations.includes('Website'),
  });
  setSchedule(agent.schedule || defaultSchedule);
  setErrors({});
  setShowModal(true);
  setIsDuplicating(true);
};


  // Duplicate agent in Firestore with agent name as ID
// Agents.js

// Replace the existing duplicateAgent function with the following

// Replace the existing duplicateAgent function with the following

const duplicateAgent = async () => {
  const integrations = Object.keys(newIntegrations).filter(key => newIntegrations[key]);
  const newAgentNameTrimmed = newAgentName.trim();

  const newAgentRef = doc(db, 'agents', newAgentNameTrimmed);

  try {
    await runTransaction(db, async (transaction) => {
      const newAgentSnapshot = await transaction.get(newAgentRef);
      if (newAgentSnapshot.exists()) {
        throw new Error('An agent with this name already exists. Please choose a different name.');
      }

      // Exclude system fields
      const { id, name, created_at, last_updated, ...agentData } = selectedAgent;

      // Prepare duplicated agent data
      const duplicatedAgentData = {
        ...agentData,
        name: newAgentNameTrimmed,
        integrations,
        schedule,
        created_at: Timestamp.now(),
        last_updated: Timestamp.now(),
        enterprise_id: isPlatformAdmin ? newEnterpriseId || agentData.enterprise_id : agentData.enterprise_id,
      };

      duplicatedAgentData.active = false;

      // Add the duplicated agent
      transaction.set(newAgentRef, duplicatedAgentData);

    });

    // Refresh agents list and close modal
    fetchAgents();
    handleCloseModal();

    // Notify user based on conflict
    if (!selectedAgent.active) {
      toast.success('Agent duplicated successfully!');
    } else {
      toast.error(
        `Another agent is already active for this Page ID. The duplicated agent has been saved in Maintenance Mode.`
      );
    }
  } catch (error) {
    console.error('Error duplicating agent:', error);
    if (error.message.startsWith('An agent with this name already exists')) {
      toast.error(error.message);
    } else {
      toast.error('Failed to duplicate agent. Please try again.');
    }
  }
};

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAgent(null);
    setNewAgentName('');
    setNewIntegrations({ Messenger: false, Website: false });
    setSchedule(defaultSchedule);
    setErrors({});
    setIsDuplicating(false);
  };

  // Handle Delete Click
  const handleDeleteClick = (agent) => {
    if (
      window.confirm(
        `Are you sure you want to delete the agent "${agent.name}"? This action cannot be undone.`
      )
    ) {
      deleteAgent(agent.id);
    } else {
      toast.info('Agent deletion cancelled.');
    }
  };

  // Delete agent from Firestore
// Agents.js

// Replace the existing deleteAgent function with the following

// Replace the existing deleteAgent function with the following

const deleteAgent = async (agentId) => {
  try {
    // Find the agent to be deleted
    const agentToDelete = agents.find(a => a.id === agentId);
    if (!agentToDelete) {
      toast.error('Agent not found.');
      return;
    }

    await runTransaction(db, async (transaction) => {
      const agentRef = doc(db, 'agents', agentId);
      transaction.delete(agentRef);

      if (agentToDelete.active) {
        const activeAgentRef = doc(db, 'activeAgents', agentToDelete.page_id);
        transaction.delete(activeAgentRef);
      }
    });

    // Refresh agents list and close modal
    fetchAgents();
    handleCloseModal();
    toast.success('Agent deleted successfully!');
  } catch (error) {
    console.error('Error deleting agent:', error);
    toast.error('Failed to delete agent. Please try again.');
  }
};


  // Define the tabs
  const tabs = [
    {
      label: 'Agents',
      content: (
        <AgentsContainer>
          <Title>Manage Your Agents</Title>
          <AgentGrid>
            {agents.map((agent) => (
              <AgentCard key={agent.id}>
                <CardHeader>
                  <CardTitle>{agent.name}</CardTitle>
                </CardHeader>
                <CardStatus>Status: {agent.active ? 'Active' : 'Maintenance Mode'}</CardStatus>
                <CardCreatedAt>Created on: {formatTimestamp(agent.created_at)}</CardCreatedAt>
                <CardIcons>
                  <FaClone
                    onClick={() => handleDuplicateClick(agent)}
                    title="Duplicate Agent"
                    aria-label="Duplicate Agent"
                  />
                  <FaEdit // New Edit Icon
                    onClick={() => handleEditClick(agent)} // New handler
                    title="Edit Webhook"
                    aria-label="Edit Webhook"
                  />

                  <FaFacebookMessenger
                    onClick={() => handleEditWebhookClick(agent)} // Updated handler if necessary
                    title="Edit Webhook"
                    aria-label="Edit Webhook"
                  />
                  {agent.active ? (
                    <FaToggleOn
                      onClick={() => toggleAgentStatus(agent)}
                      title="Set to Maintenance Mode"
                      aria-label="Set to Maintenance Mode"
                    />
                  ) : (
                    <FaToggleOff
                      onClick={() => toggleAgentStatus(agent)}
                      title="Set to Active"
                      aria-label="Set to Active"
                    />
                  )}
                  <FaTrash onClick={() => handleDeleteClick(agent)} title="Delete Agent" aria-label="Delete Agent" />
                </CardIcons>
              </AgentCard>
            ))}
            {/* Only show Add Agent option if the user has permission */}
            {isPlatformAdmin || userEnterpriseId ? (
              <AddAgentCard onClick={handleAddAgentClick} title="Add New Agent">
                + Add New Agent
              </AddAgentCard>
            ) : null}
          </AgentGrid>

          {/* Modal for Adding/Editing/Duplicating Agent */}
          <AgentForm
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            selectedAgent={selectedAgent}
            newAgentName={newAgentName}
            setNewAgentName={setNewAgentName}
            newIntegrations={newIntegrations}
            setNewIntegrations={setNewIntegrations}
            schedule={schedule}
            setSchedule={setSchedule}
            errors={errors}
            setErrors={setErrors}
            addAgent={addAgent}
            updateAgent={updateAgent}
            deleteAgent={deleteAgent}
            defaultSchedule={defaultSchedule}
            isAddAgent={isAddAgent}
            isDuplicating={isDuplicating} // New prop
            duplicateAgent={duplicateAgent} // New prop
            agents={agents} // Pass agents for name uniqueness validation
            isPlatformAdmin={isPlatformAdmin} // Pass admin status
            userEnterpriseId={userEnterpriseId} // Pass user's enterprise_id
            enterprises={enterprises} // Pass enterprises for platform_admin
            setNewEnterpriseId={setNewEnterpriseId}
            userEnterpriseName={userEnterpriseName}
          />

<WebhookEditForm
  showModal={showWebhookModal}
  handleCloseModal={() => setShowWebhookModal(false)}
  selectedAgent={selectedWebhookAgent}
  
  // Pass the new state variables and their setters
  verifyToken={verifyToken}
  setVerifyToken={setVerifyToken}
  pageAccessToken={pageAccessToken}
  setPageAccessToken={setPageAccessToken}
  pageId={pageId}
  setPageId={setPageId}
/>
        </AgentsContainer>
      ),
    },
    {
      label: 'Meta Access Validation',
      content: <MetaTest />, // Render the MetaTest component
    },
  ];

  return (
    <>
      <DashboardHeader />
      <BoxWrapper>
        <BoxContainer>
          {loading ? (
            <Loader />
          ):(
            <Tabs tabs={tabs} />
          )}
        </BoxContainer>
      </BoxWrapper>
          {/* Webhook Edit Modal */}
    </>
  );
};

export default Agents;