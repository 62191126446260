// AuthContext.js

import React, { useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Ensure db is correctly initialized and imported
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  sendPasswordResetEmail, 
  onAuthStateChanged, 
} from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { updateLastLogin } from '../utils/userService';
import { toast } from 'react-toastify';

const AuthContext = React.createContext();

/**
 * Custom hook to use the AuthContext.
 * @returns {Object} - Context value containing authentication details.
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 * AuthProvider component that wraps around parts of the app that need access to authentication.
 * @param {Object} props - React props.
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element} - Provider component.
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]); // State to hold user roles
  const [isPlatformAdmin, setIsPlatformAdmin] = useState(false); // New state for platform_admin
  const [enterpriseId, setEnterpriseId] = useState(null); // New state for enterprise_id
  const [loading, setLoading] = useState(true);

  /**
   * Signup function to create a new user.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   * @returns {Promise<Object>} - User credentials or throws an error.
   */
  async function signup(email, password) {
    try {
      console.log("🚀 ~ signup ~ Starting signup process for email:", email);
      
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log("🚀 ~ signup ~ userCredential:", userCredential);
      
      const user = userCredential.user;
      console.log("🚀 ~ signup ~ user:", user);

      // Prepare user data for Firestore
      const userData = {
        uid: user.uid,
        email: user.email,
        roles: ['unverified'],
        timestamp: serverTimestamp(),
        last_login: serverTimestamp(),
        status: 'active', // Adjust based on your needs
        first_name: '',
        last_name: '',
        profile_image_url: '',
        phone_number: '',
        metadata: {},
        enterprise_id: null, // Initialize enterprise_id as null or assign a default value if needed
      };

      // Reference to the user document in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      console.log(`🚀 ~ signup ~ Setting user data for UID: ${user.uid}`);

      // Set the user document in Firestore
      await setDoc(userDocRef, userData);
      console.log(`🚀 ~ signup ~ User data set successfully for UID: ${user.uid}`);

      // Fetch and set user roles
      console.log(`🚀 ~ signup ~ Fetching user roles for UID: ${user.uid}`);
      await fetchUserRoles(user);
      console.log(`🚀 ~ signup ~ Fetched user roles for UID: ${user.uid}`);

      return userCredential;
    } catch (error) {
      console.error('🚀 ~ signup ~ Error during signup:', error);
      toast.error('Signup failed: ' + error.message);
      throw error;
    }
  }

  /**
   * Login function to authenticate a user.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   * @returns {Promise<Object>} - User credentials or throws an error.
   */
  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  /**
   * Logout function to sign out the current user.
   * @returns {Promise<void>}
   */
  function logout() {
    return signOut(auth).then(() => {
      setCurrentUser(null);
      setUserRoles([]);
      setEnterpriseId(null); // Reset enterprise_id on logout
    });
  }

  /**
   * Reset password function to send a password reset email.
   * @param {string} email - User's email.
   * @returns {Promise<void>}
   */
  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  /**
   * Function to fetch user roles from Firestore.
   * @param {Object} user - Firebase user object.
   */
  const fetchUserRoles = async (user) => {
    if (user) {
      try {
        console.log("🚀 ~ fetchUserRoles ~ Fetching roles for user:", user.uid);
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          console.log("🚀 ~ fetchUserRoles ~ User document data:", data);
          const roles = data.roles || [];
          console.log("🚀 ~ fetchUserRoles ~ Fetched roles:", roles);
          
          // Fetch role details from the 'roles' collection
          const roleDetailsPromises = roles.map(async (roleId) => {
            const roleDoc = await getDoc(doc(db, 'roles', roleId));
            if (roleDoc.exists()) {
              return { id: roleDoc.id, ...roleDoc.data() };
            } else {
              console.warn(`🚀 ~ fetchUserRoles ~ Role document ${roleId} does not exist.`);
              return null;
            }
          });

          const roleDetails = await Promise.all(roleDetailsPromises);
          // Filter out any nulls in case some roles do not exist
          setUserRoles(roleDetails.filter(role => role !== null));

          // Update isPlatformAdmin based on roles
          const platformAdminRole = roleDetails.find(role => role.id === 'platform_admin');
          setIsPlatformAdmin(!!platformAdminRole);

          // Set enterprise_id from user document
          setEnterpriseId(data.enterprise_id || null);

          console.log("🚀 ~ fetchUserRoles ~ User roles with details:", roleDetails.filter(role => role !== null));
          console.log("🚀 ~ fetchUserRoles ~ User enterprise_id:", data.enterprise_id || null);
        } else {
          console.log("🚀 ~ fetchUserRoles ~ User document does not exist.");
          setUserRoles([]);
          setEnterpriseId(null);
        }
      } catch (error) {
        console.error('🚀 ~ fetchUserRoles ~ Error fetching user roles:', error);
        setUserRoles([]);
        setEnterpriseId(null);
      }
    } else {
      setUserRoles([]);
      setEnterpriseId(null);
    }
  };

  /**
   * Listen to authentication state changes and update context accordingly.
   */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      await fetchUserRoles(user);

      if (user) {
        try {
          // Update last_login in Firestore
          await updateLastLogin(user.uid);
          console.log(`🚀 ~ onAuthStateChanged ~ Updated last_login for UID: ${user.uid}`);

          // Fetch Custom Claims to set isPlatformAdmin
          const tokenResult = await user.getIdTokenResult(true); // Force refresh to get latest claims
          const platformAdminClaim = tokenResult.claims.platform_admin;
          setIsPlatformAdmin(!!platformAdminClaim);
          console.log(`🚀 ~ onAuthStateChanged ~ platform_admin claim: ${platformAdminClaim}`);
        } catch (error) {
          console.error(`🚀 ~ onAuthStateChanged ~ Error updating last_login or fetching claims for UID: ${user.uid}:`, error);
          setIsPlatformAdmin(false); // Reset flag on error
        }
      } else {
        setIsPlatformAdmin(false); // Reset flag if no user is signed in
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userRoles, // Provide user roles in context
    isPlatformAdmin, // Flag indicating if user is a platform_admin
    enterpriseId, // New: Provide enterprise_id in context
    signup,
    login,
    logout,
    resetPassword,
    fetchUserRoles, // Optionally provide a method to refresh roles
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
