// src/components/UserAccess.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Container,
  Header,
  Title,
  SearchExportContainer,
  SearchInput,
  ExportButton,
  AddUserButton,
  ColumnToggleContainer,
  ColumnToggleButton,
  ColumnOptions,
  ColumnOption,
  Table,
  Th,
  HeaderContent,
  Td,
  ActionIconButton,
  SortIcon,
  Spacer,
  FormGroup,
  CheckboxLabel,
  ButtonGroup,
  SaveButton,
  CloseButton,
  DeleteButton,
  TableWrapper,
  HighlightedRow
} from './component-styles/UserAccess.styled';
import { getUsers, updateUser, deleteUser, addUserToFirestore, getRoles, getEnterprises } from '../utils/userService';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { 
  FaEye, 
  FaEdit, 
  FaTrash, 
  FaSort, 
  FaSortUp, 
  FaSortDown, 
  FaFileExport,
  FaUserPlus,
  FaCog
} from 'react-icons/fa';
import DashboardHeader from './DashboardHeader';
import * as XLSX from 'xlsx';
import { BoxContainer, BoxWrapper } from './toolbox/Reusables';
import ReusableModal from './toolbox/ReusableModal'; // Ensure the path is correct
import { Tooltip } from 'react-tooltip';
import Pagination from './toolbox/Pagination'; // Import the reusable Pagination component
import { HiOutlineDownload } from 'react-icons/hi';


const UserAccess = () => {
  const { currentUser, userRoles } = useAuth();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editableData, setEditableData] = useState({});
  const [visibleColumns, setVisibleColumns] = useState({
    enterprise_id: false,       // Default: false
    enterprise_name: true,      // Default: true
    uid: false,
    email: true,
    first_name: true,
    last_name: false,
    status: false,
    phone_number: false,
    roles: true,
    metadata: false,
  });
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);
  const [newUserUID, setNewUserUID] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [availableRoles, setAvailableRoles] = useState([]);
  const [allEnterprises, setAllEnterprises] = useState([]);
  const [enterpriseMap, setEnterpriseMap] = useState(new Map());

  const isAdmin = userRoles.some(role => role.id === 'admin');

  useEffect(() => {
    const fetchUsersData = async () => {
      if (!currentUser) {
        console.log('User is not authenticated.');
        return;
      }
  
      const [usersResponse, rolesResponse, enterprisesResponse] = await Promise.all([
        getUsers(),
        getRoles(),
        getEnterprises(), // Assuming you have a getEnterprises function in userService.js
      ]);
  
      if (usersResponse.success) {
        setUsers(usersResponse.users);
        setFilteredUsers(usersResponse.users);
      } else {
        console.error(usersResponse.error);
        toast.error('Error fetching users.');
      }
  
      if (rolesResponse.success) {
        setAvailableRoles(rolesResponse.roles);
      } else {
        console.error(rolesResponse.error);
        toast.error('Error fetching roles.');
      }
  
      if (enterprisesResponse.success) {
        setAllEnterprises(enterprisesResponse.enterprises);
        const map = new Map();
        enterprisesResponse.enterprises.forEach(ent => {
          map.set(ent.id, ent.name);
        });
        setEnterpriseMap(map);
      } else {
        console.error(enterprisesResponse.error);
        toast.error('Error fetching enterprises.');
      }
    };
  
    fetchUsersData();
  }, [currentUser]);
  
  useEffect(() => {
    const fetchRolesData = async () => {
      const response = await getRoles();
      if (response.success) {
        setAvailableRoles(response.roles);
      } else {
        console.error(response.error);
        toast.error('Error fetching roles.');
      }
    };

    fetchRolesData();
  }, []);

  useEffect(() => {
    let updatedUsers = [...users];

    if (searchTerm.trim() !== '') {
      const lowercasedTerm = searchTerm.toLowerCase();
      updatedUsers = updatedUsers.filter(user =>
        (user.first_name && user.first_name.toLowerCase().includes(lowercasedTerm)) ||
        (user.last_name && user.last_name.toLowerCase().includes(lowercasedTerm)) ||
        (user.email && user.email.toLowerCase().includes(lowercasedTerm)) ||
        (user.roles && user.roles.join(', ').toLowerCase().includes(lowercasedTerm)) ||
        (user.status && user.status.toLowerCase().includes(lowercasedTerm)) ||
        (user.phone_number && user.phone_number.toLowerCase().includes(lowercasedTerm))
      );
    }

    if (sortConfig.key) {
      updatedUsers.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key.startsWith('metadata_')) {
          const metadataKey = sortConfig.key.split('_')[1];
          aValue = a.metadata ? a.metadata[metadataKey] : '';
          bValue = b.metadata ? b.metadata[metadataKey] : '';
        }

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
        }
        if (typeof bValue === 'string') {
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredUsers(updatedUsers);
    setCurrentPage(1);
  }, [searchTerm, users, sortConfig]);

  const handleViewUser = (user) => {
    setSelectedUser(user);
    setIsViewModalOpen(true);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditableData({
      uid: user.uid || '',
      email: user.email || '',
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      status: user.status || '',
      phone_number: user.phone_number || '',
      roles: user.roles || [],
      metadata_signup_source: user.metadata?.signup_source || '',
      metadata_preferences_newsletter: user.metadata?.preferences?.newsletter || false,
      metadata_preferences_notifications: user.metadata?.preferences?.notifications || false,
    });
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteUser = async () => {
    if (!selectedUser) return;

    const response = await deleteUser(selectedUser.id);
    if (response.success) {
      const updatedUsers = users.filter(user => user.id !== selectedUser.id);
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      toast.success('User deleted successfully!');
      setIsDeleteModalOpen(false);
    } else {
      console.error(response.error);
      toast.error('Error deleting user.');
    }
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    if (!selectedUser) return;

    const selectedRoles = editableData.roles;
    if (!selectedRoles || selectedRoles.length === 0) {
      toast.error('Please select at least one role.');
      return;
    }

    const validRoleIds = availableRoles.map((role) => role.id);
    const invalidRoles = selectedRoles.filter(
      (role) => !validRoleIds.includes(role)
    );

    if (invalidRoles.length > 0) {
      toast.error(`Invalid roles selected: ${invalidRoles.join(', ')}`);
      return;
    }

    const updatedData = {
      uid: editableData.uid,
      email: editableData.email,
      first_name: editableData.first_name,
      last_name: editableData.last_name,
      status: editableData.status,
      phone_number: editableData.phone_number,
      roles: selectedRoles,
      metadata: {
        signup_source: editableData.metadata_signup_source,
        preferences: {
          newsletter: editableData.metadata_preferences_newsletter,
          notifications: editableData.metadata_preferences_notifications,
        },
      },
    };

    const response = await updateUser(selectedUser.id, updatedData);
    if (response.success) {
      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id ? { ...user, ...updatedData } : user
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      toast.success('User updated successfully!');
      setIsEditModalOpen(false);
    } else {
      console.error(response.error);
      toast.error('Error updating user.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, options } = e.target;

    if (name === 'roles') {
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      setEditableData((prev) => ({
        ...prev,
        roles: selectedOptions,
      }));
    } else {
      setEditableData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleColumnToggle = (columnKey) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const renderTableHeaders = () => {
    return Object.keys(allColumns).map(columnKey => (
      visibleColumns[columnKey] && (
        <Th key={columnKey} onClick={() => handleSort(columnKey)}>
          <HeaderContent>
            {allColumns[columnKey]}
            {sortConfig.key === columnKey ? (
              sortConfig.direction === 'ascending' ? (
                <SortIcon><FaSortUp /></SortIcon>
              ) : (
                <SortIcon><FaSortDown /></SortIcon>
              )
            ) : (
              <SortIcon><FaSort /></SortIcon>
            )}
          </HeaderContent>
        </Th>
      )
    ));
  };
  
  const renderTableRow = (user) => {
    const hasUnverified = user.roles.includes('unverified');
  
    // Get enterprise details
    const enterpriseId = user.enterprise_id || 'N/A';
    const enterpriseName = user.enterprise_id ? enterpriseMap.get(user.enterprise_id) || 'N/A' : 'N/A';
  
    return (
      <HighlightedRow key={user.id} hasUnverified={hasUnverified}>
        {visibleColumns.enterprise_id && <Td>{enterpriseId}</Td>}
        {visibleColumns.enterprise_name && <Td>{enterpriseName}</Td>}
        {visibleColumns.uid && <Td>{user.uid}</Td>}
        {visibleColumns.email && <Td>{user.email}</Td>}
        {visibleColumns.first_name && <Td>{user.first_name}</Td>}
        {visibleColumns.last_name && <Td>{user.last_name}</Td>}
        {visibleColumns.status && <Td>{user.status}</Td>}
        {visibleColumns.phone_number && <Td>{user.phone_number}</Td>}
        {visibleColumns.roles && (
          <Td>
            {user.roles && user.roles.length > 0 ? user.roles.join(', ') : 'N/A'}
          </Td>
        )}
        {visibleColumns.metadata && (
          <Td>
            {user.metadata ? (
              <details>
                <summary>View Details</summary>
                <p>Signup Source: {user.metadata.signup_source || 'N/A'}</p>
                <p>
                  Newsletter: {user.metadata.preferences?.newsletter ? 'Yes' : 'No'}
                </p>
                <p>
                  Notifications: {user.metadata.preferences?.notifications ? 'Yes' : 'No'}
                </p>
              </details>
            ) : (
              'N/A'
            )}
          </Td>
        )}
        <Td>
          <ActionIconButton
            variant="view"
            onClick={() => handleViewUser(user)}
            data-tooltip-id={`view-tooltip-${user.id}`}
            data-tooltip-content="View Details"
          >
            <FaEye />
          </ActionIconButton>
          <ActionIconButton
            variant="edit"
            onClick={() => handleEditUser(user)}
            data-tooltip-id={`edit-tooltip-${user.id}`}
            data-tooltip-content="Edit User"
          >
            <FaEdit />
          </ActionIconButton>
          <ActionIconButton
            variant="delete"
            onClick={() => handleDeleteUser(user)}
            data-tooltip-id={`delete-tooltip-${user.id}`}
            data-tooltip-content="Delete User"
          >
            <FaTrash />
          </ActionIconButton>
          {/* Initialize tooltips */}
          <Tooltip id={`view-tooltip-${user.id}`} place="top" effect="solid" />
          <Tooltip id={`edit-tooltip-${user.id}`} place="top" effect="solid" />
          <Tooltip id={`delete-tooltip-${user.id}`} place="top" effect="solid" />
        </Td>
      </HighlightedRow>
    );
  };
  
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleExport = () => {
    const dataToExport = filteredUsers.map(user => {
      const exportData = {};
      Object.keys(allColumns).forEach(key => {
        if (visibleColumns[key]) {
          if (key === 'enterprise_id') {
            exportData[allColumns[key]] = user.enterprise_id || 'N/A';
          } else if (key === 'enterprise_name') {
            const enterpriseName = user.enterprise_id ? enterpriseMap.get(user.enterprise_id) || 'N/A' : 'N/A';
            exportData[allColumns[key]] = enterpriseName;
          } else if (key === 'metadata') {
            exportData[allColumns[key]] = JSON.stringify(user[key]);
          } else {
            exportData[allColumns[key]] = user[key] || 'N/A';
          }
        }
      });
      return exportData;
    });
  
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, 'UserData.xlsx');
  };
  

  const handleAddUser = async (e) => {
    e.preventDefault();

    try {
      await addUserToFirestore(newUserUID, newUserEmail);
      toast.success('User added successfully!');
      setIsAddUserModalOpen(false);
      setNewUserUID('');
      setNewUserEmail('');
      const response = await getUsers();
      if (response.success) {
        setUsers(response.users);
        setFilteredUsers(response.users);
      }
    } catch (error) {
      console.error('Error adding user:', error);
      toast.error(error.message || 'Failed to add user.');
    }
  };

  const allColumns = {
    enterprise_id: 'Enterprise ID',     // New Column
    enterprise_name: 'Enterprise Name', // New Column
    uid: 'UID',
    email: 'Email',
    first_name: 'First Name',
    last_name: 'Last Name',
    status: 'Status',
    phone_number: 'Phone Number',
    roles: 'Roles',
    metadata: 'Metadata',
  };
  
  return (
    <>
      <DashboardHeader />
      <BoxWrapper>
        <BoxContainer>
          <Header>
            <Title>User Management</Title>
            <SearchExportContainer>
              <SearchInput
                type="text"
                placeholder="Search by name, email..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <ExportButton onClick={handleExport}>
                Export <HiOutlineDownload />
              </ExportButton>
              {isAdmin && (
                <AddUserButton onClick={() => setIsAddUserModalOpen(true)}>
                  <FaUserPlus /> Add User
                </AddUserButton>
              )}
            </SearchExportContainer>
            <ColumnToggleContainer>
              <ColumnToggleButton onClick={() => setIsColumnOptionsOpen(!isColumnOptionsOpen)}>
                Columns
              </ColumnToggleButton>
              {isColumnOptionsOpen && (
                <ColumnOptions>
                  {Object.keys(allColumns).map(columnKey => (
                    <ColumnOption key={columnKey}>
                      <input
                        type="checkbox"
                        id={`toggle-${columnKey}`}
                        checked={visibleColumns[columnKey]}
                        onChange={() => handleColumnToggle(columnKey)}
                      />
                      <label htmlFor={`toggle-${columnKey}`}>{allColumns[columnKey]}</label>
                    </ColumnOption>
                  ))}
                </ColumnOptions>
              )}
            </ColumnToggleContainer>
          </Header>
          
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  {renderTableHeaders()}
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.length > 0 ? (
                  currentUsers.map(user => renderTableRow(user))
                ) : (
                  <tr>
                    <Td colSpan={Object.values(visibleColumns).filter(Boolean).length + 1} style={{ textAlign: 'center' }}>
                      No users found.
                    </Td>
                  </tr>
                )}
              </tbody>
            </Table>
          </TableWrapper>
          
          {/* Pagination Controls */}
          {totalPages > 1 && (
            <Pagination
              totalItems={filteredUsers.length}
              itemsPerPage={usersPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}

          {/* View User Modal */}
          <ReusableModal
            isOpen={isViewModalOpen}
            onClose={() => setIsViewModalOpen(false)}
            title="User Details"
          >
            {selectedUser && (
              <div>
                <p><strong>UID:</strong> {selectedUser.uid}</p>
                <p><strong>Email:</strong> {selectedUser.email}</p>
                <p><strong>First Name:</strong> {selectedUser.first_name}</p>
                <p><strong>Last Name:</strong> {selectedUser.last_name}</p>
                <p><strong>Status:</strong> {selectedUser.status}</p>
                <p><strong>Phone Number:</strong> {selectedUser.phone_number}</p>
                <p><strong>Roles:</strong> {selectedUser.roles.join(', ')}</p>
                <p><strong>Signup Source:</strong> {selectedUser.metadata?.signup_source || 'N/A'}</p>
                <p><strong>Newsletter:</strong> {selectedUser.metadata?.preferences?.newsletter ? 'Yes' : 'No'}</p>
                <p><strong>Notifications:</strong> {selectedUser.metadata?.preferences?.notifications ? 'Yes' : 'No'}</p>
              </div>
            )}
          </ReusableModal>

          {/* Edit User Modal */}
          <ReusableModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            title="Edit User Details"
          >
            {selectedUser && (
              <form onSubmit={handleSaveEdit}>
                {/* UID Field (Uneditable and Not Required) */}
                <FormGroup>
                  <label htmlFor="uid">UID:</label>
                  <input
                    type="text"
                    id="uid"
                    name="uid"
                    value={editableData.uid}
                    disabled
                  />
                </FormGroup>
                
                {/* Email Field (Uneditable and Not Required) */}
                <FormGroup>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editableData.email}
                    disabled
                  />
                </FormGroup>
                
                {/* First Name Field (Optional) */}
                <FormGroup>
                  <label htmlFor="first_name">First Name:</label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={editableData.first_name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                
                {/* Last Name Field (Optional) */}
                <FormGroup>
                  <label htmlFor="last_name">Last Name:</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={editableData.last_name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                
                {/* Status Field (Optional) */}
                <FormGroup>
                  <label htmlFor="status">Status:</label>
                  <select
                    id="status"
                    name="status"
                    value={editableData.status}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="pending">Pending</option>
                    {/* Add more statuses as needed */}
                  </select>
                </FormGroup>
                
                {/* Phone Number Field (Optional) */}
                <FormGroup>
                  <label htmlFor="phone_number">Phone Number:</label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    value={editableData.phone_number}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                
                {/* Roles Field (Required) */}
                <FormGroup>
                  <label htmlFor="roles">Roles:</label>
                  <select
                    id="roles"
                    name="roles"
                    multiple
                    value={editableData.roles}
                    onChange={handleInputChange}
                    required
                  >
                    {availableRoles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                  <small>Hold down the Ctrl (Windows) or Command (Mac) key to select multiple options.</small>
                </FormGroup>
                
                {/* Metadata Fields (Optional) */}
                <FormGroup>
                  <label htmlFor="metadata_signup_source">Signup Source:</label>
                  <input
                    type="text"
                    id="metadata_signup_source"
                    name="metadata_signup_source"
                    value={editableData.metadata_signup_source}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                
                <FormGroup>
                  <CheckboxLabel>
                    <input
                      type="checkbox"
                      name="metadata_preferences_newsletter"
                      checked={editableData.metadata_preferences_newsletter}
                      onChange={handleInputChange}
                    />
                    Newsletter Subscription
                  </CheckboxLabel>
                </FormGroup>
                
                <FormGroup>
                  <CheckboxLabel>
                    <input
                      type="checkbox"
                      name="metadata_preferences_notifications"
                      checked={editableData.metadata_preferences_notifications}
                      onChange={handleInputChange}
                    />
                    Enable Notifications
                  </CheckboxLabel>
                </FormGroup>
                
                {/* Save and Cancel Buttons */}
                <ButtonGroup>
                  <SaveButton type="submit">Save</SaveButton>
                  <CloseButton type="button" onClick={() => setIsEditModalOpen(false)}>
                    Cancel
                  </CloseButton>
                </ButtonGroup>
              </form>
            )}
          </ReusableModal>

          {/* Delete Confirmation Modal */}
          <ReusableModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            title="Confirm Deletion"
          >
            {selectedUser && (
              <div>
                <p>Are you sure you want to delete <strong>{selectedUser.first_name} {selectedUser.last_name}</strong>?</p>
                <ButtonGroup>
                  <DeleteButton onClick={confirmDeleteUser}>Delete</DeleteButton>
                  <CloseButton onClick={() => setIsDeleteModalOpen(false)}>Cancel</CloseButton>
                </ButtonGroup>
              </div>
            )}
          </ReusableModal>

          {/* Add User Modal */}
          <ReusableModal
            isOpen={isAddUserModalOpen}
            onClose={() => setIsAddUserModalOpen(false)}
            title="Add New User"
          >
            <form onSubmit={handleAddUser}>
              <FormGroup>
                <label htmlFor="newUserUID">User UID:</label>
                <input
                  type="text"
                  id="newUserUID"
                  name="newUserUID"
                  value={newUserUID}
                  onChange={(e) => setNewUserUID(e.target.value)}
                  required
                  placeholder="Enter UID of the user"
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="newUserEmail">User Email:</label>
                <input
                  type="email"
                  id="newUserEmail"
                  name="newUserEmail"
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  required
                  placeholder="Enter email of the user"
                />
              </FormGroup>
              <ButtonGroup>
                <SaveButton type="submit">Add User</SaveButton>
                <CloseButton type="button" onClick={() => setIsAddUserModalOpen(false)}>
                  Cancel
                </CloseButton>
              </ButtonGroup>
            </form>
          </ReusableModal>
        </BoxContainer>
      </BoxWrapper>
    </>
  );
};

export default UserAccess;
