// src/layouts/PrivateLayout.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationBar from '../NavigationBar';
import styled from 'styled-components';


const AppContainer = styled.div`
  display: flex;
  height: 100vh; 
  overflow: hidden; 
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(239, 239, 248); /* Set the desired background color for private routes */
`;


const PrivateLayout = () => {
  return (
    <>
    <AppContainer>
        <NavigationBar />
        <ContentContainer>
        <Outlet />
        </ContentContainer>
    </AppContainer>
    </>
  );
};

export default PrivateLayout;
