// Dashboard.js
import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { FaEnvelope, FaUsers, FaChartLine, FaCheckCircle } from 'react-icons/fa';
import DashboardHeader from './DashboardHeader';
import { BoxWrapper, BoxContainer } from './toolbox/Reusables';
import WordCloudComponent from './toolbox/WordCloudComponent'; // Ensure the path is correct

// Register the necessary components in Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

// Styled Components

const DashboardContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff; /* Changed to white */
`;

const TopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: linear-gradient(135deg, #ff6f61 0%, #de3adf 50%, #4a90e2 100%);
  border-radius: 12px;
  padding: 20px;
  color: white;
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow-deep);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  margin-right: 10px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: var(--font-weight-header);
`;

const CardValue = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: var(--font-weight-header);
  text-align: center;
  margin-bottom: 20px;
`;

const ChartContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 20px;
  box-shadow: var(--box-shadow-light);
  transition: box-shadow var(--transition-speed);

  &:hover {
    box-shadow: var(--box-shadow-deep);
  }

  @media (max-width: 767px) {
    padding: 15px;
  }
`;

const ChartHeader = styled.h3`
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
  color: var(--color-header-text);
`;

const SampleText = styled.p`
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`;

// Define the data for the Line Chart
const lineData = {
  labels: [
    'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun',
    'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
  ],
  datasets: [
    {
      label: 'Messages Sent',
      data: [120, 200, 150, 170, 90, 100, 250, 300, 280, 350, 400, 380, 420, 450],
      fill: false,
      backgroundColor: '#2563eb',
      borderColor: '#2563eb',
      tension: 0.3,
      pointBackgroundColor: '#ffffff',
      pointBorderColor: '#2563eb',
      pointHoverBackgroundColor: '#2563eb',
      pointHoverBorderColor: '#ffffff',
    },
  ],
};

// Define the options for the Line Chart
const lineOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'top',
      labels: {
        color: 'var(--color-header-text)',
      },
    },
    title: {
      display: false,
      text: 'Messages Sent per Day',
      color: 'var(--color-header-text)',
    },
  },
  scales: {
    x: {
      ticks: {
        color: 'var(--color-header-text)',
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: 'var(--color-header-text)',
        beginAtZero: true,
      },
      grid: {
        color: 'var(--color-border)',
      },
    },
  },
};

// Updated wordData for customer service AI agents in medical supplies
const wordData = [
  { text: 'Prescription', value: 900 },
  { text: 'Delivery', value: 850 },
  { text: 'Order', value: 800 },
  { text: 'Insurance', value: 750 },
  { text: 'Refund', value: 700 },
  { text: 'Support', value: 650 },
  { text: 'Shipment', value: 600 },
  { text: 'Billing', value: 550 },
  { text: 'Inventory', value: 500 },
  { text: 'Availability', value: 450 },
  { text: 'Product Info', value: 400 },
  { text: 'Technical Support', value: 350 },
  { text: 'Account', value: 300 },
  { text: 'Tracking', value: 250 },
  { text: 'Feedback', value: 200 },
  { text: 'Warranty', value: 180 },
  { text: 'Return Policy', value: 160 },
  { text: 'Compliance', value: 140 },
  { text: 'Scheduling', value: 120 },
  { text: 'Customization', value: 100 },
  { text: 'Training', value: 80 },
  { text: 'Consultation', value: 60 },
  { text: 'Reorder', value: 50 },
  { text: 'Clinical Support', value: 40 },
  { text: 'Order Confirmation', value: 30 },
  { text: 'Medical Supplies', value: 25 },
  { text: 'Pharmacy', value: 20 },
];

// Note: Removed duplicate 'Availability' entry

const Dashboard = () => (
  <>
    <DashboardHeader />

    <BoxWrapper>
      <BoxContainer>
        <DashboardContainer>
          <Title>Dashboard</Title>
          <TopContainer>
            <Card>
              <IconWrapper><FaEnvelope /></IconWrapper>
              <CardContent>
                <CardTitle>Total Messages</CardTitle>
                <CardValue>1500</CardValue>
              </CardContent>
            </Card>
            <Card>
              <IconWrapper><FaUsers /></IconWrapper>
              <CardContent>
                <CardTitle>Total Users</CardTitle>
                <CardValue>120</CardValue>
              </CardContent>
            </Card>
            <Card>
              <IconWrapper><FaChartLine /></IconWrapper>
              <CardContent>
                <CardTitle>Active Sessions</CardTitle>
                <CardValue>15</CardValue>
              </CardContent>
            </Card>
            <Card>
              <IconWrapper><FaCheckCircle /></IconWrapper>
              <CardContent>
                <CardTitle>System Status</CardTitle>
                <CardValue>Good</CardValue>
              </CardContent>
            </Card>
          </TopContainer>

          <ChartContainer>
            <ChartHeader>Messages Sent per Day</ChartHeader>
            <Line data={lineData} options={lineOptions} />
            <SampleText>Sample Data: Messages Sent Trend</SampleText>
          </ChartContainer>

          <ChartContainer>
            <ChartHeader>Word Cloud</ChartHeader>
            <WordCloudComponent 
              data={wordData} 
              sampleText="Sample Data: Word Bank Cloud"
            />
          </ChartContainer>
        </DashboardContainer>
      </BoxContainer>
    </BoxWrapper>
  </>
);

export default Dashboard;
