// src/components/AssignAdmin.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import { getApiUrl } from '../../utils/api';

// Styled Components
const AssignAdminContainer = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const AssignButton = styled.button`
  padding: 10px 20px;
  background-color: #5a67d8; /* Indigo */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #434190;
  }

  &:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
`;

const AssignAdmin = () => {
  const [uid, setUid] = useState('');
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState({});

  useEffect(() => {
    const fetchRoles = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        setRoles(idTokenResult.claims);
      }
    };

    fetchRoles();
  }, []);

  const assignPlatformAdmin = async (uidToAssign) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error('No authenticated user found.');
      }

      // Fetch the latest ID token to ensure it contains the latest custom claims
      const idToken = await currentUser.getIdToken(true); // Force refresh

      const apiUrl = getApiUrl(); // Get the dynamic API URL

      const response = await fetch(`${apiUrl}/assign-platform-admin`, { // Use the dynamic URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ uid: uidToAssign }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to assign platform_admin role.');
      }

      const data = await response.json();
      toast.success(data.message || 'platform_admin role assigned successfully!');
      setUid(''); // Clear the input field upon success

      // Optional: Refresh roles display
      const updatedIdToken = await currentUser.getIdTokenResult(true);
      setRoles(updatedIdToken.claims);
      toast.info('Your roles have been refreshed. Please reload the page if necessary.');
    } catch (error) {
      console.error('Error assigning platform_admin role:', error);
      toast.error(error.message || 'An error occurred while assigning the role.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!uid.trim()) {
      toast.warning('Please enter a valid UID.');
      return;
    }

    setLoading(true);
    await assignPlatformAdmin(uid.trim());
  };

  return (
    <AssignAdminContainer>
      <h2>Assign Platform Admin Role</h2>
      <div>
        <h3>Your Roles:</h3>
        <pre>{JSON.stringify(roles, null, 2)}</pre>
      </div>

      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="uid">User UID:</Label>
          <Input
            type="text"
            id="uid"
            name="uid"
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            placeholder="Enter the UID of the user"
            required
          />
        </FormGroup>
        <AssignButton type="submit" disabled={loading}>
          {loading ? 'Assigning...' : 'Assign Role'}
        </AssignButton>
      </form>
    </AssignAdminContainer>
  );
};

export default AssignAdmin;
