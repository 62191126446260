import React from 'react';
import DOMPurify from 'dompurify';

// Helper function to convert text
function convertMarkdownToHTML(text) {
    let formattedText = text;

    // Convert markdown bold syntax '**' to HTML bold tags '<b>'
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Add new line characters to structure the output
    formattedText = formattedText.replace(/ - /g, '\n<b>');
    formattedText = formattedText.replace(/: /g, ':</b> ');
    formattedText = formattedText.replace(/### (.*?)(\n|$)/g, '<h3>$1</h3>');

    // Ensuring the list starts on a new line
    formattedText = formattedText.replace('Narito po ang ilang mga produkto na available sa amin:', 'Narito po ang ilang mga produkto na available sa amin:\n');

    // Add an extra new line at the end for spacing if needed
    formattedText += '\n';

    return formattedText;
}

// React component to display formatted text
const FormattedText = ({ text }) => {
    const htmlText = convertMarkdownToHTML(text);
    const safeHTML = DOMPurify.sanitize(htmlText);  // Sanitize HTML

    return (
        <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: safeHTML }}>
            {/* HTML will be rendered here */}
        </div>
    );
}

export default FormattedText;
