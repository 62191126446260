// src/components/NavigationBar.js
import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import InventoryIcon from '@mui/icons-material/Inventory'; // Orders Icon
import BusinessIcon from '@mui/icons-material/Business'; // Enterprise Icon
import AssessmentIcon from '@mui/icons-material/Assessment'; // Reports Icon
import ChevronDownIcon from '@mui/icons-material/ExpandMore'; // Down arrow icon
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // Right arrow icon
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // Left arrow icon
import BuildIcon from '@mui/icons-material/Build';
import AndyAiIcon from '../assets/AndyAI-Ready.png'; // Colored logo
import AndyAiIconWhite from '../assets/AndyAI-White.png'; // White logo
import { useAuth } from '../contexts/AuthContext';
import useIsPlatformAdmin from '../utils/useIsPlatformAdmin';
import { ThemeContext } from '../contexts/ThemeContext'; // Import ThemeContext

// Styled Components with Transient Props (prefixed with $)
const Nav = styled.nav`
  background: var(--navbar-background);
  color: var(--navbar-inactive);
  width: ${({ $collapsed }) => ($collapsed ? '80px' : '200px')};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  font-family: 'Roboto', sans-serif;
  flex-shrink: 0;
`;

const BrandLink = styled(NavLink)`
  display: block;
  margin: 0 auto 25px auto;
  width: 100%;
  max-width: ${({ $collapsed }) => ($collapsed ? '40px' : '80px')};
  transition: max-width 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const BrandIcon = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin-top: 15px;
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: var(--navbar-inactive);
  padding: 14px 18px;
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  position: relative;

  &.active {
    color: var(--navbar-active);
    background-color: var(--navbar-active-background);
    font-weight: 600;
  }

  &:hover {
    background-color: var(--navbar-hover-background);
    transform: scale(1.02);
    color: var(--navbar-active);
  }

  svg {
    margin-right: ${({ $collapsed }) => ($collapsed ? '0' : '10px')};
    transition: margin-right 0.3s ease, transform 0.3s ease, color 0.3s ease;
    font-size: 18px;
    color: inherit;
  }
`;

const ComingSoonBadge = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff6347; /* Tomato color for visibility */
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 12px;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: var(--navbar-inactive);
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;

  &:hover {
    background-color: var(--navbar-hover-background);
    color: var(--navbar-active);
  }

  svg {
    transition: transform 0.3s ease;
    font-size: 24px;
  }
`;

const LogoutButton = styled.button`
  background: transparent;
  color: var(--navbar-inactive);
  border: none;
  padding: 14px 18px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: var(--navbar-hover-background);
    color: var(--navbar-active);
    transform: scale(1.02);
  }

  svg {
    margin-right: 10px;
    transition: margin-right 0.3s ease, color 0.3s ease;
    font-size: 18px;
    color: inherit;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid var(--navbar-divider);
  margin: 4px 0;
`;

// Styled Components for Dropdown
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  color: var(--navbar-inactive);
  padding: 14px 18px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${({ $isBuildToolsOpen }) => ($isBuildToolsOpen ? '8px' : '0px')};
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  
  &:hover {
    background-color: var(--navbar-hover-background);
    transform: scale(1.02);
    color: var(--navbar-active);
  }

  svg {
    margin-right: 8px;
    transition: margin-right 0.3s ease, transform 0.3s ease, color 0.3s ease;
    font-size: 16px;
    color: inherit;
  }
`;

const DropdownMenu = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  margin-left: ${({ $collapsed }) => ($collapsed ? '0px' : '24px')};
  gap: 8px;
  transition: all 0.3s ease;
`;

const DropdownNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: var(--navbar-inactive);
  padding: 10px 18px;
  text-decoration: none;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;

  &.active {
    color: var(--navbar-active);
    background-color: var(--navbar-active-background);
    font-weight: 600;
  }

  &:hover {
    background-color: var(--navbar-hover-background);
    transform: scale(1.02);
    color: var(--navbar-active);
  }

  svg {
    margin-right: ${({ $collapsed }) => ($collapsed ? '0' : '8px')};
    transition: margin-right 0.3s ease, transform 0.3s ease, color 0.3s ease;
    font-size: 16px;
    color: inherit;
  }
`;

const NavigationBar = () => {
  const { logout } = useAuth();
  const isPlatformAdmin = useIsPlatformAdmin(); // Utilize the custom hook
  const { theme } = useContext(ThemeContext); // Access the current theme

  const [collapsed, setCollapsed] = useState(false);
  const [isBuildToolsOpen, setIsBuildToolsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Clear authentication
    navigate('/login');
  };

  const toggleBuildTools = () => {
    setIsBuildToolsOpen(prevState => !prevState);
  };

  // Determine which logo to display based on the current theme
  const logoSrc = theme === 'light' ? AndyAiIcon : AndyAiIconWhite;

  return (
    <Nav $collapsed={collapsed}>
      <div>
        <BrandLink to="/" $collapsed={collapsed}>
          <BrandIcon src={logoSrc} alt="AndyAI Logo" />
        </BrandLink>

        <NavLinks>
          <StyledNavLink to="/dashboard" $collapsed={collapsed}>
            <DashboardIcon />
            {!collapsed && 'Dashboard'}
          </StyledNavLink>

          <StyledNavLink to="/reports" $collapsed={collapsed}>
            <AssessmentIcon />
            {!collapsed && (
              <>
                Reports
                <ComingSoonBadge>Soon</ComingSoonBadge>
              </>
            )}
          </StyledNavLink>

          <StyledNavLink to="/orders" $collapsed={collapsed}>
            <InventoryIcon />
            {!collapsed && (
              <>
                Orders
                <ComingSoonBadge>Soon</ComingSoonBadge>
              </>
            )}
          </StyledNavLink>

          <StyledNavLink to="/agents" $collapsed={collapsed}>
            <PeopleIcon />
            {!collapsed && 'Agents'}
          </StyledNavLink>

          <StyledNavLink to="/chat-logs" $collapsed={collapsed}>
            <ManageSearchIcon />
            {!collapsed && 'Chat Logs'}
          </StyledNavLink>

          <Divider />

          {/* Dropdown for Build Tools */}
          <DropdownContainer>
            <DropdownHeader
              onClick={toggleBuildTools}
              $isBuildToolsOpen={isBuildToolsOpen}
              aria-label="Toggle Build Tools Dropdown"
            >
              <BuildIcon /> 
              {!collapsed && <span>Build Tools</span>}
              {!collapsed && (
                isBuildToolsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />
              )}
            </DropdownHeader>

            <DropdownMenu $isOpen={isBuildToolsOpen} $collapsed={collapsed}>
              {/* Training */}
              <DropdownNavLink to="/training" $collapsed={collapsed}>
                <FitnessCenterIcon />
                {!collapsed && 'Training'}
              </DropdownNavLink>

              {/* Settings */}
              <DropdownNavLink to="/settings" $collapsed={collapsed}>
                <SettingsIcon />
                {!collapsed && 'Settings'}
              </DropdownNavLink>

              {/* Enterprises (Admin Only) */}
              {isPlatformAdmin && (
                <DropdownNavLink to="/enterprises" $collapsed={collapsed}>
                  <BusinessIcon />
                  {!collapsed && 'Enterprises'}
                </DropdownNavLink>
              )}

              {/* Users (Admin Only) */}
              {isPlatformAdmin && (
                <DropdownNavLink to="/user-access" $collapsed={collapsed}>
                  <SupervisedUserCircleIcon />
                  {!collapsed && 'Users'}
                </DropdownNavLink>
              )}
            </DropdownMenu>
          </DropdownContainer>

          <Divider />
        </NavLinks>
      </div>

      <div>
        <LogoutButton onClick={handleLogout}>
          <ExitToAppIcon />
          {!collapsed && 'Logout'}
        </LogoutButton>
        <ToggleButton onClick={() => setCollapsed(!collapsed)} aria-label="Toggle Navigation">
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </ToggleButton>
      </div>
    </Nav>
  );
};

export default NavigationBar;
