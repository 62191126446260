// Unauthorized.js
import React from 'react';
import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';

const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8d7da;
  text-align: center;
  padding: 0px 20px;
`;

const WarningIcon = styled(FaExclamationTriangle)`
  font-size: 50px;
  color: #721c24;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #721c24;
  margin-bottom: 10px;
`;

const Message = styled.p`
  font-size: 18px;
  color: #721c24;
  margin-bottom: 30px;
`;

const Unauthorized = () => {
  return (
    <UnauthorizedContainer>
      <WarningIcon />
      <Title>Unauthorized Access</Title>
      <Message>
        Sorry if you can't access this page at the moment. /n For approvals to access, please reach out to likha@helloandy.ai. Thank you!
      </Message>
    </UnauthorizedContainer>
  );
};

export default Unauthorized;
