// src/components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';
import { FaLock } from 'react-icons/fa';

// Styled Components for Unauthorized Access
const RestrictedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(244, 244, 249); // New background color
  text-align: center;
  padding: 0px 20px;
`;

const LockIcon = styled(FaLock)`
  font-size: 50px;
  color: #e74c3c;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const Message = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 30px;
`;

const ContactLink = styled.a`
  color: #007bff;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// Unauthorized Component
const UnauthorizedAccess = () => (
  <RestrictedContainer>
    <LockIcon />
    <Title>Unauthorized Access</Title>
    <Message>
      Sorry, you don't have permission to access this page. <br /> <br /> For assistance, please contact{' '}
      <ContactLink href="mailto:likha@helloandy.ai">likha@helloandy.ai</ContactLink>.
    </Message>
  </RestrictedContainer>
);

/**
 * PrivateRoute component to restrict access based on user roles.
 * @param {Object} props - React props.
 * @param {React.ReactNode} props.children - Child components to render if authorized.
 * @param {Array} props.requiredRoles - Array of role IDs required to access the route.
 * @returns {JSX.Element} - Rendered component based on authorization.
 */
const PrivateRoute = ({ children, requiredRoles = [] }) => {
  const { currentUser, userRoles, isPlatformAdmin } = useAuth();

  // **1. Authentication Check**
  if (!currentUser) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // **2. Platform Admin Override**
  if (isPlatformAdmin) {
    // Platform admins have access to all routes
    return children;
  }

  // **3. Authorization Check**
  if (requiredRoles.length > 0) {
    // Check if the user has at least one of the required roles
    const hasRequiredRole = userRoles.some((role) => requiredRoles.includes(role.id));

    if (!hasRequiredRole) {
      // If the user lacks the required role, redirect to the unauthorized page
      return <UnauthorizedAccess />;
    }
  }

  // **4. Access Granted**
  // If the user is authenticated and has the required role(s), render the protected component
  return children;
};

export default PrivateRoute;
