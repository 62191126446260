// src/styles/GlobalStyles.js
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: ${({ theme }) => theme.background};
    --text: ${({ theme }) => theme.text};
    --text-light: ${({ theme }) => theme.textLight};
    --header-background: ${({ theme }) => theme.headerBg};
    --header-text: ${({ theme }) => theme.headerText};
    --transition-speed: ${({ theme }) => theme.transitionSpeed || '0.5s'};
    --box-shadow-light: ${({ theme }) => theme.boxShadow};
    --font-size-input: 14px; /* Assuming a default value */
    --font-weight-header: 600; /* Assuming a default value */
    --font-weight-body: 400; /* Assuming a default value */
    --placeholder-color: ${({ theme }) => theme.placeholderColor};
    --dropdown-bg: ${({ theme }) => theme.dropdownBg};
    --dropdown-border: ${({ theme }) => theme.dropdownBorder};
    --hover-bg: ${({ theme }) => theme.hoverBg};
    --icon: ${({ theme }) => theme.icon};
    --icon-secondary: ${({ theme }) => theme.iconSecondary};
    
    /* Navigation-Specific CSS Variables */
    --navbar-background: ${({ theme }) => theme.navbarBackground};
    --navbar-active: ${({ theme }) => theme.navbarActive};
    --navbar-inactive: ${({ theme }) => theme.navbarInactive};
    --navbar-divider: ${({ theme }) => theme.navbarDivider};
    --navbar-hover-background: ${({ theme }) => theme.navbarHoverBackground};
    --navbar-active-background: ${({ theme }) => theme.navbarActiveBackground};


    /* internal-Specific CSS Variables */
    --internal-background: ${({ theme }) => theme.background};
    --internal-section-background: ${({ theme }) => theme.internalSectionBackground};
    --internal-section-shadow: ${({ theme }) => theme.internalSectionShadow};
    --internal-title-color: ${({ theme }) => theme.internalTitleColor};
    --internal-subtitle-color: ${({ theme }) => theme.internalSubtitleColor};
    --internal-icon-color: ${({ theme }) => theme.internalIconColor};
    --internal-font-size-title: ${({ theme }) => theme.internalFontSizeTitle};
    --internal-font-size-subtitle: ${({ theme }) => theme.internalFontSizeSubtitle};
    --internal-font-weight-title: ${({ theme }) => theme.internalFontWeightTitle};
    --internal-font-weight-subtitle: ${({ theme }) => theme.internalFontWeightSubtitle};
  }

  body {
    background-color: var(--background);
    color: var(--text);
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
  }

  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--background);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--text);
    border-radius: 4px;
    border: 2px solid var(--background);
  }

`;

export const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;

  &.primary {
    background-color: #5A67D8;
    color: #fff;

    &:hover {
      background-color: #434190;
    }
  }

  &.secondary {
    background-color: #4A5568;
    color: #fff;

    &:hover {
      background-color: #2D3748;
    }
  }

  &.danger {
    background-color: #E53E3E;
    color: #fff;

    &:hover {
      background-color: #C53030;
    }
  }

  &.link {
    background: none;
    color: #5A67D8;
    padding: 0;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #CBD5E0;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #5A67D8;
    box-shadow: 0 0 0 2px rgba(90, 103, 216, 0.2);
  }
`;

export const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #CBD5E0;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #5A67D8;
    box-shadow: 0 0 0 2px rgba(90, 103, 216, 0.2);
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  padding: 24px;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #A0AEC0;

  &:hover {
    color: #4A5568;
  }
`;
