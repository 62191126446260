import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
// Styled Components for AgentForm.js
const ModalOverlay = styled.div`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  /* Responsive Design: Adjust alignment on smaller screens */
  @media (max-width: 480px) {
    align-items: flex-start;
    padding-top: 20px;
  }
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 700px; /* Increased width to accommodate schedule settings */
  width: 90%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  /* Responsive Design: Adjust width on smaller screens */
  @media (max-width: 768px) {
    max-width: 600px;
  }

  @media (max-width: 480px) {
    max-width: 95%;
    padding: 20px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #e53e3e;
  }
`;

const ModalTitle = styled.h3`
  margin-bottom: 20px;
  text-align: center;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ $error }) => ($error ? '#e53e3e' : '#ccc')};
  border-radius: 8px;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #5a67d8;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const DeleteButton = styled.button`
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #e53e3e;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c53030;
  }
`;

const SaveButton = styled.button`
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #38a169; /* Changed to a success color */
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2f855a;
  }

  &:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
`;

// Styled Component for Error Messages
const ErrorText = styled.p`
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 5px;
`;

// Styled Component for Reset Button
const ResetButton = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  background-color: #cbd5e0;
  color: #2d3748;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a0aec0;
  }
`;

// Styled Component for Enterprise Select (platform_admin only)
const SelectEnterpriseGroup = styled.div`
  display: flex;
  flex-direction: column;
`;


const WebhookEditForm = ({
    showModal,
    handleCloseModal,
    selectedAgent,
    verifyToken,
    setVerifyToken,
    pageAccessToken,
    setPageAccessToken,
    pageId,
    setPageId,
  }) => {
    const [errors, setErrors] = useState({});
  
    useEffect(() => {
      if (selectedAgent) {
        setVerifyToken(selectedAgent.verify_token || '');
        setPageAccessToken(selectedAgent.page_access_token || '');
        setPageId(selectedAgent.page_id || '');
      }
    }, [selectedAgent, setVerifyToken, setPageAccessToken, setPageId]);
  
  
  const validateForm = () => {
    const newErrors = {};
    if (!verifyToken.trim()) newErrors.verifyToken = 'VERIFY_TOKEN is required.';
    if (!pageAccessToken.trim()) newErrors.pageAccessToken = 'PAGE_ACCESS_TOKEN is required.';
    if (!pageId.trim()) newErrors.pageId = 'PAGE_ID is required.';
    // Add more validation as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Please fix the errors in the form.');
      return;
    }

    try {
      const agentRef = doc(db, 'agents', selectedAgent.id);
      await updateDoc(agentRef, {
        verify_token: verifyToken,
        page_access_token: pageAccessToken,
        page_id: pageId,
      });
      toast.success('Webhook details updated successfully!');
      handleCloseModal();
    } catch (error) {
      console.error('Error updating webhook details:', error);
      toast.error('Failed to update webhook details. Please try again.');
    }
  };

  return (
    <ModalOverlay $show={showModal}>
      <ModalContent>
        <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
        <ModalTitle>Edit Webhook Details for {selectedAgent?.name}</ModalTitle>
        <ModalForm onSubmit={handleSubmit}>
{/* VERIFY_TOKEN */}
<div>
  <Label>VERIFY_TOKEN</Label>
  <Input
    type="text"
    value={verifyToken}
    onChange={(e) => setVerifyToken(e.target.value)}
    required
    placeholder="Enter VERIFY_TOKEN"
    $error={errors.verifyToken}
  />
  {errors.verifyToken && <ErrorText>{errors.verifyToken}</ErrorText>}
</div>

{/* PAGE_ACCESS_TOKEN */}
<div>
  <Label>PAGE_ACCESS_TOKEN</Label>
  <Input
    type="text"
    value={pageAccessToken}
    onChange={(e) => setPageAccessToken(e.target.value)}
    required
    placeholder="Enter PAGE_ACCESS_TOKEN"
    $error={errors.pageAccessToken}
  />
  {errors.pageAccessToken && <ErrorText>{errors.pageAccessToken}</ErrorText>}
</div>

{/* PAGE_ID */}
<div>
  <Label>PAGE_ID</Label>
  <Input
    type="text"
    value={pageId}
    onChange={(e) => setPageId(e.target.value)}
    required
    placeholder="Enter PAGE_ID"
    $error={errors.pageId}
  />
  {errors.pageId && <ErrorText>{errors.pageId}</ErrorText>}
</div>
          {/* Save Button */}
          <SaveButton type="submit">Save Webhook Details</SaveButton>
        </ModalForm>
      </ModalContent>
    </ModalOverlay>
  );
};

export default WebhookEditForm;
