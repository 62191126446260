// src/components/DashboardHeader.js
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Brightness7RoundedIcon from '@mui/icons-material/Brightness7Rounded'; // Modern sun icon
import DarkModeIcon from '@mui/icons-material/DarkMode'; // Dark theme icon
import MemoryIcon from '@mui/icons-material/Memory'; // AI theme icon
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ThemeContext } from '../contexts/ThemeContext';


// Styled Icons using CSS variables
const SunIcon = styled(Brightness7RoundedIcon)`
  color: var(--icon);
  filter: drop-shadow(0 0 10px var(--icon));
`;

const MoonIcon = styled(DarkModeIcon)`
  color: var(--icon);
`;

const AiIcon = styled(MemoryIcon)`
  color: var(--icon);
`;

// Wrapper for the header background color, updated with dynamic props for theme
const HeaderWrapper = styled.div`
  background: var(--background);
  padding: 20px 50px;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--header-background);
  color: var(--header-text);
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: ${({ theme }) =>
    theme === 'ai'
      ? '0 4px 12px rgba(106, 17, 203, 0.5)'
      : 'var(--box-shadow-light)'};
  height: 55px;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px; /* Increased gap for better spacing */
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-left: 20px;
  flex-grow: 1;

  input {
    border: none;
    outline: none;
    font-size: var(--font-size-input);
    color: var(--text);
    margin-left: 10px;
    background: transparent;
    width: 100%;

    &::placeholder {
      color: var(--placeholder-color);
      opacity: 1; /* Override default opacity */
    }
  }
`;

// Language Selector Styled Components
const LanguageSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;

const LanguageDropdown = styled.div`
  position: absolute;
  background: var(--dropdown-bg);
  border: 1px solid var(--dropdown-border);
  border-radius: 4px;
  top: 50px;
  z-index: 11;
  width: 200px;
  padding: 10px;
  box-shadow: var(--box-shadow-light);
`;

const LanguageOption = styled.div`
  color: var(--text);
  font-size: var(--font-size-message);
`;

// Theme Selector Styled Components
const ThemeSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;

const ThemeDropdown = styled.div`
  position: absolute;
  background: var(--dropdown-bg);
  border: 1px solid var(--dropdown-border);
  border-radius: 4px;
  top: 50px;
  z-index: 11;
  width: 150px;
  box-shadow: var(--box-shadow-light);
`;

const ThemeOption = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text);

  &:hover {
    background: var(--hover-bg);
  }

  svg {
    color: var(--icon);
  }
`;

// Notification Icon Styled Component
const NotificationIcon = styled(NotificationsNoneIcon)`
  font-size: 28px;
  cursor: pointer;
  transition: color 0.3s;

  color: var(--text);

  &:hover {
    color: #5f0a87;
  }
`;

// Profile Section Styled Components
const ProfileSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;

const ProfileIcon = styled(AccountCircleIcon)`
  font-size: 48px;
  color: var(--text);
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: var(--text);

  .name {
    font-weight: var(--font-weight-header);
  }
  .role {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-light);
  }
`;

const ProfileDropdown = styled.div`
  position: absolute;
  background: var(--dropdown-bg);
  border: 1px solid var(--dropdown-border);
  border-radius: 4px;
  top: 60px;
  right: 0;
  z-index: 11;
  width: 150px;
  box-shadow: var(--box-shadow-light);
`;

const ProfileOption = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text);

  &:hover {
    background: var(--hover-bg);
  }

  svg {
    color: var(--icon-secondary);
  }
`;

const DashboardHeader = () => {
  // Consume ThemeContext
  const { theme, changeTheme } = useContext(ThemeContext);

  const [isThemeDropdownOpen, setThemeDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const { logout, enterpriseId, currentUser, isPlatformAdmin } = useAuth();
  console.log("🚀 ~ DashboardHeader ~ currentUser:", currentUser);
  const [enterpriseName, setEnterpriseName] = useState('');
  const navigate = useNavigate();

  const toggleThemeDropdown = () => {
    setThemeDropdownOpen(!isThemeDropdownOpen);
    setProfileDropdownOpen(false);
    setLanguageDropdownOpen(false);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
    setThemeDropdownOpen(false);
    setLanguageDropdownOpen(false);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!isLanguageDropdownOpen);
    setThemeDropdownOpen(false);
    setProfileDropdownOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const goToSettings = () => {
    navigate('/settings');
  };

  useEffect(() => {
    const fetchEnterpriseName = async () => {
      if (enterpriseId) {
        try {
          const enterpriseRef = doc(db, 'enterprises', enterpriseId);
          const enterpriseSnap = await getDoc(enterpriseRef);
          if (enterpriseSnap.exists()) {
            const enterpriseData = enterpriseSnap.data();
            console.log("🚀 ~ fetchEnterpriseName ~ enterpriseData:", enterpriseData);
            setEnterpriseName(enterpriseData.name || 'Enterprise');
          } else {
            setEnterpriseName('Enterprise'); // Fallback if enterprise document doesn't exist
          }
        } catch (error) {
          console.error('Error fetching enterprise name:', error);
          setEnterpriseName('Enterprise'); // Fallback on error
        }
      } else {
        setEnterpriseName('Enterprise'); // Fallback if no enterpriseId
      }
    };

    fetchEnterpriseName();
  }, [enterpriseId]);

  // Access theme from ThemeContext
  const currentThemeIcon =
    theme === 'light' ? (
      <SunIcon />
    ) : theme === 'dark' ? (
      <MoonIcon />
    ) : (
      <AiIcon />
    );

  // Handle theme change using global context
  const handleThemeChange = (newTheme) => {
    changeTheme(newTheme);
    setThemeDropdownOpen(false);
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        {/* Search Bar */}
        <SearchBar>
          <SearchIcon />
          <input type="text" placeholder="Search..." />
        </SearchBar>

        {/* Language Dropdown, Theme Selector, Notifications, Profile */}
        <HeaderSection>
          {/* Language Dropdown */}
          <LanguageSelect onClick={toggleLanguageDropdown}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197484.png"
              alt="USA Flag"
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
              }}
            />
            Eng (US)
            <ArrowDropDownIcon />
            {isLanguageDropdownOpen && (
              <LanguageDropdown>
                <LanguageOption>
                  Only English Available Now
                </LanguageOption>
              </LanguageDropdown>
            )}
          </LanguageSelect>

          {/* Theme Dropdown */}
          <ThemeSelect onClick={toggleThemeDropdown}>
            {currentThemeIcon}
            <ArrowDropDownIcon />
            {isThemeDropdownOpen && (
              <ThemeDropdown>
                <ThemeOption onClick={() => handleThemeChange('light')}>
                  <Brightness7RoundedIcon />
                  Light
                </ThemeOption>
                <ThemeOption onClick={() => handleThemeChange('dark')}>
                  <DarkModeIcon />
                  Dark
                </ThemeOption>
                <ThemeOption onClick={() => handleThemeChange('ai')}>
                  <MemoryIcon />
                  AI Theme
                </ThemeOption>
              </ThemeDropdown>
            )}
          </ThemeSelect>

          {/* Notification Icon */}
          <NotificationIcon />

          {/* Profile Section */}
          <ProfileSelect onClick={toggleProfileDropdown}>
            <ProfileIcon />
            <ProfileInfo>
              <span className="name">{isPlatformAdmin ? "AndyAI" : enterpriseName}</span>
              <span className="role">{isPlatformAdmin ? "Platform Admin" : currentUser.displayName || "Support"}</span>
            </ProfileInfo>

            <ArrowDropDownIcon />
            {isProfileDropdownOpen && (
              <ProfileDropdown>
                <ProfileOption onClick={goToSettings}>
                  <SettingsIcon />
                  Settings
                </ProfileOption>
                <ProfileOption onClick={handleLogout}>
                  <ExitToAppIcon />
                  Logout
                </ProfileOption>
              </ProfileDropdown>
            )}
          </ProfileSelect>
        </HeaderSection>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default DashboardHeader;
