// src/contexts/ThemeContext.js
import React, { createContext, useState, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, aiTheme } from '../theme';
import { GlobalStyle } from '../styles/GlobalStyles';

// Create the ThemeContext
export const ThemeContext = createContext();

// Create a ThemeProviderContext component
export const ThemeProviderContext = ({ children }) => {
  // Initialize theme state with localStorage or default to 'light'
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  // Update localStorage whenever theme changes
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Function to change theme
  const changeTheme = (newTheme) => {
    setTheme(newTheme);
  };

  // Select the appropriate theme object
  const themeObject =
    theme === 'light' ? lightTheme : theme === 'dark' ? darkTheme : aiTheme;

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      <StyledThemeProvider theme={themeObject}>
        <GlobalStyle />
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
