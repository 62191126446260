// /src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Footer Section
const FooterContainer = styled.footer`
  background-color: #000;
  color: white;
  padding: 60px 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  @media (max-width: 768px) {
    align-items: center;
    margin-bottom: 30px;
  }
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
`;

const FooterLink = styled(Link)`
  color: #aaa;
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const FooterExternalLink = styled.a`
  color: #aaa;
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 25px;
`;

const SocialIcon = styled.a`
  color: #aaa;
  font-size: 1.8rem;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }
`;

const FooterBottom = styled.div`
  width: 100%;
  background-color: #000;
  padding: 30px 0;
  font-size: 0.9rem;
  text-align: center;
  color: #ccc;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterSection>
          <FooterTitle>Company</FooterTitle>
          <FooterLink to="/about-us">About Andy</FooterLink>
          <FooterLink to="/jobs">Jobs</FooterLink>
          <FooterLink to="/innovation">Innovation</FooterLink>
          <FooterLink to="/why-we-do-this">Why We Do This</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Communities</FooterTitle>
          <FooterLink to="/newsletter">Newsletter</FooterLink>
          <FooterLink to="/entrepreneurs">Entrepreneurs</FooterLink>
          <FooterLink to="/builders">Builders</FooterLink>
          <FooterLink to="/training">Training</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Useful Links</FooterTitle>
          <FooterLink to="/support">Support</FooterLink>
          <FooterLink to="/feedback">Feedback</FooterLink>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Social</FooterTitle>
          <SocialIcons>
            <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialIcon>
            <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialIcon>
            <SocialIcon href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </SocialIcon>
          </SocialIcons>
        </FooterSection>
      </FooterContainer>

      <FooterBottom>
        <p>© 2024 AndyAI. Revolutionizing the Future of AI. All rights reserved.</p>
      </FooterBottom>
    </>
  );
};

export default Footer;
