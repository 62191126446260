import React, { useRef, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import AndyAiIcon from '../assets/AndyAI-Ready.png'; // Adjust the path as necessary
import { db } from '../firebase';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

const SignupContainer = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f0f0f0;
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  box-sizing: border-box;  // Ensures padding and border are included in the element's total width and height
`;

const Form = styled.form`
  width: 100%;
  max-width: 400px;  // Set a max width for the form
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 12px;
  margin: 10px 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 12px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 12px;
  margin-top: 20px;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
`;

const SideContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: linear-gradient(135deg, #a4508b, #5f0a87);
  color: white;
`;

const SideTitle = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`;

const SideDescription = styled.p`
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  max-width: 300px;
`;

const SignupPage = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }


    try {
      setError('');
      setLoading(true);
      // await signup(emailRef.current.value, passwordRef.current.value);
      await signup(emailRef.current.value, passwordRef.current.value);

      navigate('/dashboard');
    } catch (err) {
      console.error("Error during signup:", err);
      setError('Failed to create an account');
    }

    setLoading(false);
  }


  return (
    <SignupContainer>
      <FormContainer>
        <Logo src={AndyAiIcon} alt="AndyAI Logo" />
        <Title>Sign Up</Title>
        {error && <p>{error}</p>}
        <Form onSubmit={handleSubmit}>
          <Input type="email" ref={emailRef} required placeholder="Email" />
          <Input type="password" ref={passwordRef} required placeholder="Password" />
          <Input type="password" ref={passwordConfirmRef} required placeholder="Confirm Password" />
          <Button disabled={loading} type="submit">Sign Up</Button>
        </Form>
        <StyledLink to="/login" style={{ marginTop: '20px', display: 'block', textAlign: 'center' }}>Already have an account? Log In</StyledLink>
        <StyledLink to="/" style={{ marginTop: '10px', display: 'block', textAlign: 'center' }}>Back to Landing Page</StyledLink>
      </FormContainer>
      <SideContainer>
        <SideTitle>Join Our Community</SideTitle>
        <SideDescription>
          Be inspired by insights created by designers around the globe. Stay connected and up-to-date with the latest trends.
        </SideDescription>
      </SideContainer>
    </SignupContainer>
  );
};

export default SignupPage;
