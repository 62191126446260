// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import UnauthorizedAccess from './Unauthorized'; // Ensure the path is correct

/**
 * ProtectedRoute component to restrict access exclusively to platform_admin users.
 * @param {Object} props - React props.
 * @param {React.ReactNode} props.children - Child components to render if authorized.
 * @returns {JSX.Element} - Rendered component based on authorization.
 */
const ProtectedRoute = ({ children }) => {
  const { currentUser, isPlatformAdmin } = useAuth();

  // **1. Authentication Check**
  if (!currentUser) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // **2. Authorization Check**
  if (!isPlatformAdmin) {
    // If the user is not a platform_admin, redirect to the unauthorized page
    return <UnauthorizedAccess />;
  }

  // **3. Access Granted**
  // If the user is authenticated and is a platform_admin, render the protected component
  return children;
};

export default ProtectedRoute;
