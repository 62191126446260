// src/hooks/useIsPlatformAdmin.js

import { useAuth } from '../contexts/AuthContext';

const useIsPlatformAdmin = () => {
  const { userRoles } = useAuth();

  // Debugging: Log the userRoles to verify their structure
  console.log("userRoles:", userRoles);

  // Check if any role has id 'platform_admin'
  const isPlatformAdmin = userRoles && userRoles.some(role => role.id === 'platform_admin');

  return isPlatformAdmin;
};

export default useIsPlatformAdmin;
