// src/components/UserAccess.styled.js
import styled from 'styled-components';
import { BoxWrapper, BoxContainer } from '../toolbox/Reusables'; // Ensure the correct import path

// Outer Container with gray background (Private Route Background)
export const Container = styled(BoxWrapper)`
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`;

// Inner Container with white background and proper padding
export const InnerContainer = styled(BoxContainer)`
  width: 100%;
  max-width: 1400px;
  padding: 20px;
  padding-bottom: 40px; /* Existing padding-bottom */
  margin-bottom: 20px;  /* Added margin-bottom for external spacing */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Corrected typo */
  border-radius: 8px;
`;

// Header Styles
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

// Title Styles
export const Title = styled.h2`
  font-size: 24px;
  color: #333333;
`;

// Search and Export Container Styles
export const SearchExportContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

// Search Input Styles
export const SearchInput = styled.input`
  padding: 8px 12px;
  width: 250px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    outline: none;
    border-color: #6a11cb;
    box-shadow: 0 0 0 2px rgba(106, 17, 203, 0.2);
  }
`;

// Export Button Styles - Reverted to Rectangular Style
export const ExportButton = styled.button`
  padding: 8px 16px;
  background-color: #4caf50; /* Modern green */
  color: #fff;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  svg {
    margin-left: 8px;
  }
`;

// Add User Button Styles - Reverted to Rectangular Style
export const AddUserButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff; /* Modern blue */
  color: #fff;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0069d9;
  }

  svg {
    margin-right: 8px;
  }
`;

// Column Toggle Styles
export const ColumnToggleContainer = styled.div`
  position: relative;
`;

// Column Toggle Button Styles - Styled Like ChatLogs
export const ColumnToggleButton = styled.button`
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;  /* Rounded corners */
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  &:hover {
    background-color: #f8f8f8;
  }
`;

// Column Options Styles
// src/components/UserAccess.styled.js
export const ColumnOptions = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 15px;
  /* Removed display property */
  z-index: 1000; /* Increased z-index */
  min-width: 200px;
  right: 0;
  top: 45px;
`;

// Column Option Styles
export const ColumnOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;  /* Space between items */
  font-size: 14px;      /* Modern font size */
  color: #555;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    margin-right: 10px;  /* Space between checkbox and label */
    accent-color: #6a11cb;  /* Updated checkbox color to match modern UI theme */
  }

  label {
    cursor: pointer;
  }
`;

// Table Wrapper for Responsiveness
export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

// Table Styles
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Allows columns to adjust based on content */
  font-family: 'Inter', sans-serif;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

// Table Header Styles
export const Th = styled.th`
  text-align: left;
  padding: 12px 20px; /* Increased horizontal padding for better spacing */
  background-color: #f8f9fa;
  border-bottom: 2px solid #dddddd;
  font-size: 14px;
  color: #555555;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #e2e8f0;
    color: #2d3748;
  }

  &:nth-child(1) {
    width: 100px; /* Adjusted width for UID */
  }

  &:nth-child(2) {
    width: 300px; /* Expanded width for Email */
  }

  &:nth-child(7) { /* Assuming 'Roles' is the 7th column */
    width: 200px; /* Reduced width for Roles */
  }
`;

// Header Content Styles
export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

// Sort Icon Styles
export const SortIcon = styled.span`
  margin-left: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #3C3D37;
`;

// Table Data Cell Styles
export const Td = styled.td`
  padding: 10px 12px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 13px;
  color: #555555;
  word-wrap: break-word;
`;

// Highlighted Row Styles
export const HighlightedRow = styled.tr`
  background-color: ${({ hasUnverified }) => hasUnverified ? '#fffbe6' : 'transparent'}; /* Light pastel yellow */
`;

// Action Icon Button Styles - Lightened Colors and Positioned Tooltips
export const ActionIconButton = styled.button`
  background: none;
  border: none;
  color: rgb(128, 128, 128);
  cursor: pointer;
  padding: 6px;
  margin-right: 8px;
  transition: transform 0.2s, color 0.3s;

  &:hover {
    transform: scale(1.1);
    color: ${({ variant }) =>
      variant === 'view' ? '#1e90ff' :  // Lighter blue
      variant === 'edit' ? '#32cd32' : // Lighter green
      variant === 'delete' ? '#ff6347' : // Lighter red
      '#95a5a6'};
  }

  &:focus {
    outline: none;
  }

  svg {
    font-size: 18px;
  }
`;

// Reuse the PaginationContainer and PageButton from Pagination.js if needed
// Otherwise, keep existing styles

// Form Group Styles
export const FormGroup = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 5px;
    font-weight: 500;
    color: #2d3748;
  }

  input, select {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #5a67d8;
      box-shadow: 0 0 0 2px rgba(90, 103, 216, 0.2);
    }
  }
`;

// Checkbox Label Styles
export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;  /* Space between items */
  font-size: 14px;      /* Modern font size */
  color: #555;

  input {
    margin-right: 10px;  /* Space between checkbox and label */
    accent-color: #6a11cb;  /* Updated checkbox color to match modern UI theme */
  }

  &:hover {
    color: #000;
  }
`;

// Button Group Styles
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

// Save Button Styles
export const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: #5a67d8;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #434190;
    transform: translateY(-2px);
  }
`;

// Delete Button Styles
export const DeleteButton = styled.button`
  padding: 10px 20px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #c53030;
    transform: translateY(-2px);
  }
`;

// Close Button Styles
export const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: #a0aec0;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #718096;
    transform: translateY(-2px);
  }
`;

// Spacer Styles
export const Spacer = styled.div`
  height: 20px;
  background-color: rgb(244, 244, 249); /* Match the gray background */
`;
