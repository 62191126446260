// src/components/toolbox/Pagination.js
import React from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 8px;
`;

const PageButton = styled.button`
  padding: 8px 12px;
  margin: 5px;
  border: none;
  background-color: ${({ active }) => (active ? '#6a11cb' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#6a11cb')};
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #6a11cb;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: ${({ active }) => (active ? '#5a0da7' : '#f0e8ff')};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Ellipsis = styled.span`
  padding: 8px 12px;
  margin: 5px;
  color: #6a11cb;
  min-width: 44px;
  text-align: center;
`;

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Early return if no pages
  if (totalPages === 0) return null;

  // Determine the page numbers to display (e.g., show 5 pages at a time)
  let startPage = Math.max(currentPage - 2, 1);
  let endPage = Math.min(currentPage + 2, totalPages);

  // Adjust if near the start or end
  if (currentPage <= 3) {
    endPage = Math.min(5, totalPages);
  }
  if (currentPage >= totalPages - 2) {
    startPage = Math.max(totalPages - 4, 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <PaginationContainer>
      <PageButton onClick={() => onPageChange(1)} disabled={currentPage === 1}>
        First
      </PageButton>
      <PageButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
        Prev
      </PageButton>
      {startPage > 1 && <Ellipsis>...</Ellipsis>}
      {pageNumbers.map(number => (
        <PageButton
          key={number}
          onClick={() => onPageChange(number)}
          active={currentPage === number}
        >
          {number}
        </PageButton>
      ))}
      {endPage < totalPages && <Ellipsis>...</Ellipsis>}
      <PageButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        Next
      </PageButton>
      <PageButton onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>
        Last
      </PageButton>
    </PaginationContainer>
  );
};

export default Pagination;
