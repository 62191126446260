// MetaTest.js

import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { getApiUrl } from '../../utils/api'; // Ensure correct path
import { useAuth } from '../../contexts/AuthContext';

// Styled Components
const MetaTestContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h3`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledButton = styled.button`
  background-color: #4a5568;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2d3748;
  }

  &:focus {
    outline: none;
  }
`;

// Custom styles for React Modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '12px',
    padding: '20px',
  },
};

// Initialize React Modal
Modal.setAppElement('#root'); // Ensure the root element has the id 'root'

const MetaTest = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const { isPlatformAdmin } = useAuth();

  // Function to fetch Pages list
  const fetchPagesList = async () => {
    try {
      const apiUrl = getApiUrl();

      const response = await fetch(`${apiUrl}/pages_show_list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Removed Authorization header
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch pages list.');
      }

      const data = await response.json();
      setModalContent(JSON.stringify(data.pages, null, 2));
      setModalIsOpen(true);
      toast.success('Pages list fetched successfully!');
    } catch (error) {
      console.error('Error fetching pages list:', error);
      toast.error(`Failed to fetch pages list: ${error.message}`);
    }
  };

  // Function to manage Page metadata
  const managePageMetadata = async () => {
    try {
      const apiUrl = getApiUrl();

      // Prompt user for Page ID
      const pageId = prompt("Enter the Page ID you want to manage:", "113653070321274");
      if (!pageId) {
        toast.warning('Page ID is required to manage metadata.');
        return;
      }

      // Prompt user for subscribed fields (comma-separated)
      const fieldsInput = prompt("Enter the subscribed fields (comma-separated):", "messages,messaging_postbacks");
      if (!fieldsInput) {
        toast.warning('Subscribed fields are required.');
        return;
      }

      // Convert comma-separated string to array
      const subscribedFields = fieldsInput.split(',').map(field => field.trim());

      // Validate subscribed fields
      if (subscribedFields.length === 0) {
        toast.warning('At least one subscribed field is required.');
        return;
      }

      const response = await fetch(`${apiUrl}/pages_manage_metadata`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Removed Authorization header
        },
        body: JSON.stringify({
          page_id: pageId,
          subscribed_fields: subscribedFields
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to manage page metadata.');
      }

      const data = await response.json();
      setModalContent(JSON.stringify(data, null, 2));
      setModalIsOpen(true);
      toast.success('Page metadata managed successfully!');
    } catch (error) {
      console.error('Error managing page metadata:', error);
      toast.error(`Failed to manage page metadata: ${error.message}`);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };

  return (
    <MetaTestContainer>
          {isPlatformAdmin ? (
            <>
                  <Title>Meta Access Check</Title>
                  <ButtonContainer>
                    <StyledButton onClick={fetchPagesList}>
                      Fetch Pages List
                    </StyledButton>
                    <StyledButton onClick={managePageMetadata}>
                      Manage Page Metadata
                    </StyledButton>
                  </ButtonContainer>
            </>
            
          ): (
            <Title>Sorry, this is for admin access only</Title>
          )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="JSON Response"
        style={customStyles}
      >
        <h2>JSON Response</h2>
        <pre>{modalContent}</pre>
        <StyledButton onClick={closeModal}>Close</StyledButton>
      </Modal>
    </MetaTestContainer>
  );
};

export default MetaTest;
