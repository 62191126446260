import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import DashboardHeader from './DashboardHeader';

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Section = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  display: block;
`;

const Select = styled.select`
  padding: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-bottom: 20px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const Input = styled.input`
  padding: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-bottom: 20px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 12px;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

const SettingsPage = () => {
  const { currentUser } = useAuth();
  const [notification, setNotification] = useState('weekly');

  const handleNotificationChange = (e) => {
    setNotification(e.target.value);
  };

  const handleSaveNotification = () => {
    // Save notification preference logic
    console.log('Notification preference saved:', notification);
  };

  const handleRequestPasswordReset = () => {
    // Logic to request password reset
    console.log('Password reset requested');
  };

  return (
    <>
    <DashboardHeader />
    <SettingsContainer>
      <Section>
        <Title>Notification Settings</Title>
        <Label htmlFor="notification">Notification Frequency:</Label>
        <Select id="notification" value={notification} onChange={handleNotificationChange}>
          <option value="daily">Notified every day</option>
          <option value="weekly">Notified every week</option>
          <option value="monthly">Notified every month</option>
        </Select>
        <Button onClick={handleSaveNotification}>Save</Button>
      </Section>

      <Section>
        <Title>Profile Update</Title>
        <Label>Email Address:</Label>
        <Input type="email" value={currentUser?.email || ''} disabled />
        
        <Label>Mobile Number:</Label>
        <Input type="text" value={currentUser?.phoneNumber || 'Not provided'} disabled />

        <Button onClick={handleRequestPasswordReset}>Request Reset Password</Button>
      </Section>
    </SettingsContainer>
    </>
  );
};

export default SettingsPage;
