// src/theme.js

import AndyAiIconWhite from './assets/AndyAI-White.png'; // Import the white logo
import AndyAiIcon from './assets/AndyAI-Ready.png'; // Colored logo

export const lightTheme = {
  background: 'rgb(239, 239, 248, 0.8)',
  text: '#2D3748',
  textLight: 'rgb(61, 69, 86)',
  headerBg: '#ffffff',
  headerText: '#1f2937',
  hoverBg: '#f3f4f6',
  icon: '#ffc107',
  iconSecondary: '#4A4A4A',
  transitionSpeed: '0.5s',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.08)',
  dropdownBg: '#ffffff',
  dropdownBorder: '#ddd',
  placeholderColor: '#aaa',

  // Navigation-Specific Variables
  navbarBackground: '#FFFFFF', // White background
  navbarActive: '#6200EE', // Bright purple for active links
  navbarInactive: '#4A4A4A', // Darker gray for inactive links
  navbarDivider: '#E0E0E0', // Light gray divider
  navbarHoverBackground: 'rgba(98, 0, 238, 0.05)', // Softer hover effect
  navbarActiveBackground: 'rgba(98, 0, 238, 0.1)', // Light purple background for active state

  // Logo
  navbarLogo: AndyAiIcon, // Colored logo

  // Used for components in the dashboard
  internalSectionBackground: '#ffffff', // Section background
  internalSectionShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // Section box-shadow
  internalTitleColor: '#4c4c4c', // Title color
  internalSubtitleColor: '#666666', // Subtitle color
  internalIconColor: '#8b5cf6', // Icon color
  internalFontSizeTitle: '36px', // Title font size
  internalFontSizeSubtitle: '18px', // Subtitle font size
  internalFontWeightTitle: '700', // Title font weight
  internalFontWeightSubtitle: '400', // Subtitle font weight
};

export const darkTheme = {
  background: 'rgb(19, 18, 18)',
  text: 'rgb(234, 236, 246)',
  textLight: 'rgb(204, 206, 216)',
  headerBg: 'rgb(48, 47, 47)',
  headerText: 'rgb(180, 180, 180)',
  hoverBg: 'rgb(28, 30, 36)',
  icon: '#ffffff',
  iconSecondary: '#ffffff',
  transitionSpeed: '0.5s',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  dropdownBg: 'rgb(48, 47, 47)',
  dropdownBorder: '#444',
  placeholderColor: 'rgb(234, 236, 246)',

  // Navigation-Specific Variables
  navbarBackground: 'rgb(24, 23, 23)', // Dark sidebar background
  navbarActive: '#bb86fc', // Soft purple for active links
  navbarInactive: '#cccccc', // Light gray for inactive links
  navbarDivider: '#444444', // Dark gray divider
  navbarHoverBackground: 'rgba(187, 134, 252, 0.1)', // Softer hover effect
  navbarActiveBackground: 'rgba(187, 134, 252, 0.2)', // Light purple background for active state

  // Logo
  navbarLogo: AndyAiIconWhite, // White logo for dark theme

  // internal-Specific Variables
  internalSectionBackground: 'rgb(48, 47, 47)', // Section background
  internalSectionShadow: '0 4px 12px rgba(255, 255, 255, 0.15)', // Section box-shadow
  internalTitleColor: '#ffffff', // Title color
  internalSubtitleColor: '#dddddd', // Subtitle color
  internalIconColor: '#a78bfa', // Icon color
  internalFontSizeTitle: '36px', // Title font size
  internalFontSizeSubtitle: '18px', // Subtitle font size
  internalFontWeightTitle: '700', // Title font weight
  internalFontWeightSubtitle: '400', // Subtitle font weight
};

export const aiTheme = {
  background: 'rgb(239,239,248)',
  text: '#ffffff',
  textLight: 'rgb(204, 206, 216)',
  headerBg: 'linear-gradient(90deg, #6a11cb, #2575fc)',
  headerText: '#ffffff',
  hoverBg: 'rgba(255, 255, 255, 0.15)',
  icon: '#ffffff',
  iconSecondary: '#ffffff',
  transitionSpeed: '0.5s',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  dropdownBg: 'linear-gradient(90deg, #6a11cb, #2575fc)',
  dropdownBorder: '#ddd',
  placeholderColor: '#ffffff',

  // Navigation-Specific Variables
  navbarBackground: 'linear-gradient(180deg, #6e1f93, #8c3dbb)', // Gradient background
  navbarActive: '#ffffff', // White for active links
  navbarInactive: '#e1d7e7', // Light gray for inactive links
  navbarDivider: 'rgba(255, 255, 255, 0.3)', // White divider
  navbarHoverBackground: 'rgba(255, 255, 255, 0.1)', // Softer hover effect
  navbarActiveBackground: 'rgba(255, 255, 255, 0.2)', // Light white background for active state

  // Logo
  navbarLogo: AndyAiIconWhite, // White logo for AI theme

  // internal-Specific Variables
  internalSectionBackground: '#ffffff', // Section background
  internalSectionShadow: '0 4px 12px rgba(106, 17, 203, 0.15)', // Section box-shadow (matches AI header gradient)
  internalTitleColor: '#4c4c4c', // Title color (can adjust for AI theme)
  internalSubtitleColor: '#666666', // Subtitle color
  internalIconColor: '#8b5cf6', // Icon color
  internalFontSizeTitle: '36px', // Title font size
  internalFontSizeSubtitle: '18px', // Subtitle font size
  internalFontWeightTitle: '700', // Title font weight
  internalFontWeightSubtitle: '400', // Subtitle font weight
};
