import React, { useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import Tippy.js styles

const CloudContainer = styled.div`
  width: 100%;
  height: 400px;
  background-color: var(--color-input-bg);
  border-radius: 8px;
  padding: 5px;
  box-shadow: var(--box-shadow-light);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* For tooltip positioning if needed */
`;

const SampleText = styled.p`
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 10px;
`;

// Tooltip styled component (optional customization)
const TooltipContent = styled.div`
  font-size: 14px;
  color: #333;
`;

const WordCloudComponent = ({ data, sampleText }) => {
    const containerRef = useRef(null);
    const svgRef = useRef(null);
    const [words, setWords] = useState([]);
    const [dimensions, setDimensions] = useState({ width: 600, height: 400 });
  
    const colorScale = useMemo(() => d3.scaleOrdinal(d3.schemeCategory10), []);
  
    useEffect(() => {
      const handleResize = () => {
        if (containerRef.current) {
          const { offsetWidth, offsetHeight } = containerRef.current;
          setDimensions({ width: offsetWidth, height: offsetHeight });
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial call
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    useEffect(() => {
      if (!data || data.length === 0) {
        setWords([]);
        return;
      }
  
      d3.select(svgRef.current).selectAll('*').remove();
  
      const layout = cloud()
        .size([dimensions.width, dimensions.height])
        .words(
          data.map(d => ({
            text: d.text,
            size: d.value,
          }))
        )
        .padding(5)
        .rotate(() => {
          const angles = [0, 45, 90, 135];
          return angles[Math.floor(Math.random() * angles.length)];
        })
        .font('Impact')
        .fontSize(d => Math.log(d.size + 1) * 20)
        .on('end', generatedWords => {
          setWords(generatedWords);
        });
  
      layout.start();
  
      return () => {
        layout.stop();
      };
    }, [data, dimensions]);
  
  // Event handler for clicking on a word
  const handleWordClick = (word) => {
    alert(`Word: ${word.text}\nFrequency: ${word.size}`);
  };

  return (
    <div>
      <CloudContainer>
        {words.length > 0 ? (
          <svg
            ref={svgRef}
            width="100%"
            height="100%"
            viewBox="0 0 600 400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform={`translate(300,200)`}>
              {words.map((word, index) => (
                <Tippy
                  key={index}
                  content={<TooltipContent>Frequency: {word.size}</TooltipContent>}
                  placement="top"
                  animation="scale"
                >
                  <text
                    textAnchor="middle"
                    transform={`translate(${word.x},${word.y}) rotate(${word.rotate})`}
                    fontFamily={word.font}
                    fontSize={word.size}
                    fill={colorScale(index % 10)}
                    style={{ cursor: 'pointer', transition: 'transform 0.2s, fill 0.2s' }}
                    onClick={() => handleWordClick(word)}
                    onMouseEnter={(e) => {
                      e.target.style.fill = '#ff5722'; // Change color on hover
                      e.target.style.transform = 'scale(1.2)'; // Slight enlargement
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.fill = colorScale(index % 10); // Revert color
                      e.target.style.transform = 'scale(1)'; // Revert size
                    }}
                  >
                    {word.text}
                  </text>
                </Tippy>
              ))}
            </g>
          </svg>
        ) : (
          <SampleText>No Data Available</SampleText>
        )}
      </CloudContainer>
      {sampleText && <SampleText>{sampleText}</SampleText>}
    </div>
  );
};

WordCloudComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  sampleText: PropTypes.string,
};

WordCloudComponent.defaultProps = {
  sampleText: 'Sample Data: Word Bank Cloud',
};

export default WordCloudComponent;