// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";  // Import Firebase Auth
import { getFirestore } from "firebase/firestore";  // Import Firestore
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDmxczMpSfVswG6Rvkv2WF4v2CSfkAD8XU",
  authDomain: "andyai-6a2f5.firebaseapp.com",
  projectId: "andyai-6a2f5",
  storageBucket: "andyai-6a2f5.appspot.com",
  messagingSenderId: "45853578886",
  appId: "1:45853578886:web:db6231c803f6fa32d12307",
  measurementId: "G-JE5F2EX9YD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  // Initialize and export auth
const db = getFirestore(app);  // Initialize and export Firestore
const functions = getFunctions(app, 'us-central1'); // Specify region

// Optionally connect to emulator for testing
// connectFunctionsEmulator(functions, 'localhost', 5001);

// Export Firebase services


export { auth, db, functions };
