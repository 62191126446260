// Orders.js
import React from 'react';
import styled from 'styled-components';
import DashboardHeader from './DashboardHeader';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

// Utilizing CSS Variables for Theming
const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--internal-background);
  padding: 20px;
`;

const Section = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: var(--internal-section-background);
  padding: 40px;
  border-radius: 20px;
  box-shadow: var(--internal-section-shadow);
  text-align: center;
`;

const Title = styled.h1`
  font-size: var(--internal-font-size-title);
  font-weight: var(--internal-font-weight-title);
  color: var(--internal-title-color);
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: var(--internal-font-size-subtitle);
  font-weight: var(--internal-font-weight-subtitle);
  color: var(--internal-subtitle-color);
  margin-bottom: 30px;
`;

const IconWrapper = styled.div`
  margin-bottom: 30px;
  color: var(--internal-icon-color);
`;

const RocketIcon = styled(RocketLaunchIcon)`
  font-size: 80px !important;
  color: var(--internal-icon-color);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;
const Orders = () => {
  return (
    <>
      <DashboardHeader />
      <ComingSoonContainer>
        <Section>
          <IconWrapper>
            <RocketIcon />
          </IconWrapper>
          <Title>Coming Soon</Title>
          <Subtitle>We are working hard to launch this feature soon. Stay tuned for more updates!</Subtitle>
        </Section>
      </ComingSoonContainer>
    </>
  );
};

export default Orders;